<template>
  <v-data-table
    :headers="headers"
    :items="sensors"
    :search="search"
    sort-by="state"
    multi-sort
    sort-desc
    class="elevation-1"
    :footer-props="{'items-per-page-options': [10 ,15, 20, -1]}"
  >
    <template #item.state="{ item }">
      <div v-if="item.state==1">
        <!-- <v-icon small color="green">mdi-record-circle</v-icon> -->
        <v-tooltip
          transition="slide-x-transition"
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-3"
              color="grey"
              dark
              v-on="on"
            >
              {{ snr(item.snr) }}
            </v-icon>
          </template>
          <div style="max-width: 300px">
            SNR: {{ item.snr  }}<br>
            RSSI: {{ item.rssi  }}<br>
            SF: {{ item.sf  }}
          </div>
        </v-tooltip>
        
        <v-tooltip
          transition="slide-x-transition"
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-badge
              :content="item.sf>10 ? '!' : ''"
              :value="item.sf>10 ? true : false"
              overlap
              offset-x="6"
              dot
              color="warning"
            >
            <!-- color="red" -->
              <!-- <v-icon color="menucontent">{{ item.icon }}</v-icon> -->
              <v-icon
              small
              class="mr-0"
              color="grey"
              dark
              v-on="on"
            >
              {{ bat(item) }}
            </v-icon>
            </v-badge>
            <!-- <v-icon
              small
              class="mr-3"
              color="grey"
              dark
              v-on="on"
            >
              {{ bat(item) }}
            </v-icon> -->
          </template>
          <div style="max-width: 300px">
            Battery: {{ item.bat/1000 }}V
            <div v-if="item.sf>10">Warning: Higher SF affects battery lifespan!</div>
          </div>
        </v-tooltip>

        <!-- <v-tooltip
          transition="slide-x-transition"
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-3"
              color="grey"
              dark
              v-on="on"
            >
              {{ sf(item.sf) }}
            </v-icon>
          </template>
          <div style="max-width: 300px">
            SF: {{ item.sf  }}
          </div>
        </v-tooltip> -->
        
        <!-- <v-chip
          color="success"
          outlined
          small
        >Active
        </v-chip> -->
      </div>
      <div v-else-if="item.state==2">
        <v-chip
          color="info"
          outlined
          small
        >Pending Configuration
        </v-chip>
      </div>
      <div v-else>
        <v-chip
          color="grey"
          outlined
          small
        >Disabled
        </v-chip>
      </div>
      
    </template>
    <!-- <template v-slot:[`item.state`]="{ item }">
      <v-icon
        v-if="selfScopes.includes('users.write') || selfScopes.includes('groups.manager')"
        small
        class="mr-3"
        @click="editUsers(item)"
        color="primary"
        dark
      >
        mdi-account-multiple
      </v-icon>
      <v-icon
        v-if="selfScopes.includes('sensors.write') || selfScopes.includes('groups.manager')"
        small
        class="mx-3"
        @click="editItem(item)"
        color="primary"
        dark
      >
        mdi-pencil
      </v-icon>
    </template> -->

    <template v-slot:[`item.symbol`]="{ item }">
      <!-- <v-tooltip
        transition="slide-x-transition"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" disabled>{{ item.symbol }}</v-icon>
        </template>
        <div style="max-width: 300px">
          
        </div>
      </v-tooltip> -->
      <v-icon disabled>{{ item.symbol }}</v-icon>
    </template>

    <template v-slot:[`item.location`]="{ item }">
      {{ computeLocation(item.location) }}
    </template>
    
    <!-- <template #item.state="{ item }">
      <div v-if="item.state==1">
        <v-icon small color="green">mdi-record-circle</v-icon>
      </div>
      <div v-else>
        <v-icon small color="grey">mdi-record-circle</v-icon>
      </div>
    </template> -->

    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-card :align="alignment"> -->
        <!-- <v-container>
          <v-row>
          <v-col col="12">

          </v-col>
          <v-col col="12">
            
          </v-col>
          <v-col col="12">
            
          </v-col>
          </v-row>
        </v-container> -->
        <!-- <v-text>Sensors</v-text> -->
        <v-toolbar-title>Sensors</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-dialog persistent scrollable v-model="dialogAddSensor" max-width="600px" transition="slide-y-transition">
          <template v-if="selfScopes.includes('sensors.create')||selfScopes.includes('groups.manager')" v-slot:activator="{ on, attrs }">
            <v-btn small color="primary" outlined dark class="mb-2" v-bind="attrs" v-on="on" @click="getAppsTTN"> 
              <!-- <v-icon small class="mr-1">mdi-plus</v-icon> -->
              New Sensor
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">Register a Device</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col>
                    <!-- <v-autocomplete
                      dense
                      v-model="sensorModelSelected"
                      :items="ttnAppDataList"
                      label="Device Model"
                      outlined
                    ></v-autocomplete> -->
                    <v-autocomplete
                      dense
                      v-model="sensorModelSelected"
                      :items="ttnAppData"
                      item-text="description"
                      item-value="id"
                      label="Device Model"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="1" sm="1">
                    <v-btn icon @click="vDevModel = !vDevModel" :color="vDevModel ? 'primary' : 'grey'">
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </v-col>
                    <!-- <v-icon small>mdi-information-outline</v-icon>
                    Refer to this
                    <a :href="deviceInfo" target="_blank">Guide</a>
                    for details on how to add the sensor.
                  </v-col> -->
                </v-row>
                <v-row dense>
                  <v-col cols="11">
                  <v-banner
                    outlined
                    v-model="vDevModel"
                    transition="slide-y-transition"
                  >
                    Select from the list of currently supported devices. In case your LoRaWAN device is not listed here, please send us a request at <a href="mailto: info@beuave.com">info@beuave.com</a> with the device model and we will add the support for it. For a detailed guide on how to register a new device, please refer to the instructions <a :href="deviceInfo" target="_blank">here</a>.
                  </v-banner>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <v-autocomplete
                      :disabled="!sensorModelSelected"
                      chips
                      multiple
                      deletable-chips
                      small-chips
                      dense
                      v-model="sensorTypesSelected"
                      :items="sensorTypesList"
                      item-text="Description"
                      item-value="id"
                      label="Select Sensors"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="1" sm="1">
                    <v-btn icon @click="vSelectSensors = !vSelectSensors" :color="vSelectSensors ? 'primary' : 'grey'">
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="11">
                  <v-banner
                    outlined
                    v-model="vSelectSensors"
                    transition="slide-y-transition"
                  >
                     The sensors selected here will be ready to configure in the sensors menu after the device has been successfully added. The ones not selected will be disabled. This can be edited later from the sensors menu. 
                    <!-- <a :href="deviceInfo" target="_blank">Guide</a> -->
                  </v-banner>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <v-autocomplete
                      dense
                      v-model="freqPlanSelected"
                      :items="freqPlan"
                      label="Frequency plan"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="1" sm="1">
                    <v-btn icon @click="vFreqPlan = !vFreqPlan" :color="vFreqPlan ? 'primary' : 'grey'">
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="11">
                  <v-banner
                    outlined
                    v-model="vFreqPlan"
                    transition="slide-y-transition"
                  >
                  If you are unsure of which frequency plan to use in your country, see the list of <a href="https://www.thethingsnetwork.org/docs/lorawan/frequencies-by-country/" target="_blank">frequency plans by country list</a>.
                  </v-banner>
                  </v-col>
                </v-row>
                <!-- <v-row dense>
                  <v-col cols="12" sm="8" md="8">
                    <v-autocomplete
                      dense
                      v-model="lorawanVersionSelected"
                      :items="lorawanVersion"
                      label="LoRaWAN version"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                </v-row> -->
                <!-- <v-row dense>
                  <v-col cols="12" sm="8" md="8">
                    <v-autocomplete
                      dense
                      v-model="phyVersionSelected"
                      :items="phyVersion"
                      label="Regional parameters version"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                </v-row> -->
                <v-row dense>
                  <v-col>
                    <v-text-field
                      dense
                      outlined
                      v-model="devEUI"
                      label="DevEUI"
                    ></v-text-field>
                    <!-- <div class="ma-auto" style="max-width: 300px"> -->
                    <!-- <v-otp-input
                      v-model="devEUI"
                      length="16"
                      plain
                    ></v-otp-input> -->
                    <!-- </div> -->
                  </v-col>
                <!-- </v-row> -->
                <!-- <v-row> -->
                  <v-col cols="1" sm="1">
                    <v-btn icon @click="vDevEUI = !vDevEUI" :color="vDevEUI ? 'primary' : 'grey'">
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="11">
                  <v-banner
                    outlined
                    v-model="vDevEUI"
                    transition="slide-y-transition"
                  >
                    Please find the DevEUI in the package of your device. It is a 64-bit globally-unique Extended Unique Identifier assigned by the device manufacturer.
                  </v-banner>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field 
                      dense
                      outlined
                      v-model="appEUI"
                      label="AppEUI"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" sm="1">
                    <v-btn icon @click="vAppEUI = !vAppEUI" :color="vAppEUI ? 'primary' : 'grey'" >
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="11">
                  <v-banner
                    outlined
                    v-model="vAppEUI"
                    transition="slide-y-transition"
                  >
                    Please find the AppEUI in the package of the device.
                  </v-banner>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <v-text-field 
                      dense
                      outlined
                      v-model="appKey"
                      label="AppKey"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" sm="1">
                    <v-btn icon @click="vAppKey = !vAppKey" :color="vAppKey ? 'primary' : 'grey'">
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="11">
                  <v-banner
                    outlined
                    v-model="vAppKey"
                    transition="slide-y-transition"
                  >
                    AppKey is pre-configured in your device. Please find it in the package.
                  </v-banner>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <v-text-field
                      disabled
                      dense
                      outlined
                      v-model="device_id"
                      label="Device Id"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" sm="1">
                    <v-btn icon @click="vDevId = !vDevId" :color="vDevId ? 'primary' : 'grey'">
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="11">
                  <v-banner
                    outlined
                    v-model="vDevId"
                    transition="slide-y-transition"
                  >
                    Device ID is derived from the DevEUI and is the main reference for this device in beUave.
                  </v-banner>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="11">
                    <v-text-field
                      dense
                      outlined
                      v-model="sensorDescription"
                      label="Device Name"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="11">
                    <v-text-field
                      dense
                      outlined
                      v-model="sensorLabel"
                      label="Label"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" sm="1">
                    <v-btn icon @click="vGroup = !vGroup" :color="vGroup ? 'primary' : 'grey'">
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                
                <v-row dense class="mb-5">
                  <v-col cols="11">
                  <v-banner
                    outlined
                    v-model="vGroup"
                    transition="slide-y-transition"
                  >
                    Please select the group you want the device to be assigned to. You can assign the device to your group if you are the owner, or any group that you have access to, in case you have privileges for registering new sensors.
                  </v-banner>
                  </v-col>
                </v-row>
                <!-- </v-row> -->
                
                <v-row dense>
                  <v-col>
                    <v-autocomplete
                      dense
                      v-model="userGroupSelected"
                      :items="groups"
                      item-text="description"
                      item-value="id"
                      label="Group"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="1" sm="1">
                    <v-btn icon @click="vGroup = !vGroup" :color="vGroup ? 'primary' : 'grey'">
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense class="mb-5">
                  <v-col cols="11">
                  <v-banner
                    outlined
                    v-model="vGroup"
                    transition="slide-y-transition"
                  >
                    Please select the group you want the device to be assigned to. You can assign the device to your group if you are the owner, or any group that you have access to, in case you have privileges for registering new sensors.
                  </v-banner>
                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" outlined text @click="addSensorTTN">
                Add device
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined text @click="closeAddSensors">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog" max-width="500px" transition="slide-y-transition">
          <!-- <template v-if="selfScopes.includes('sensors.write')" v-slot:activator="{ on, attrs }">
            <v-btn color="primary" outlined dark class="mb-2" v-bind="attrs" v-on="on">
              <v-icon small class="mr-1">mdi-plus</v-icon>
              Add Sensor
            </v-btn>
          </template> -->

          <v-card>
            <v-card-title>
              <!-- <span class="headline">{{ formTitle }}</span> -->
              <span class="headline">Sensor Configuration</span>
            </v-card-title>

            <!-- <v-tabs
              v-model="tab"
              background-color="transparent"
              grow
            >
              <v-tab>Sensor</v-tab>
              <v-tab>Device</v-tab>
            </v-tabs>
            
            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="item in items"
                :key="item"
              >
                <v-card
                  color="basil"
                  flat
                >
                  <v-card-text>{{ text }}</v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items> -->
            
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense disabled
                      v-model="editedItem.device_id"
                      label="Device Id"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete dense disabled
                      v-model="editedItem.sensor_type"
                      :items="sensorTypes"
                      item-text="Description"
                      item-value="id"
                      label="Type"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete dense
                      v-model="editedItem.location"
                      :items="locations"
                      item-text="location"
                      item-value="id"
                      label="Location"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete dense
                      v-model="editedItem.sensor_group"
                      :items="groups"
                      item-text="description"
                      item-value="id"
                      label="Group"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field dense
                      v-model="editedItem.description"
                      label="Description"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field dense
                      v-model="editedItem.label"
                      label="Label"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field dense
                      v-model="editedItem.min_val"
                      label="Min"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field dense
                      v-model="editedItem.max_val"
                      label="Max"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field dense
                      v-model="editedItem.offset"
                      label="Offset"
                    >
                      <!-- <template v-slot:label>
                        Offset <v-icon small style="vertical-align: middle">
                          mdi-help-circle-outline
                        </v-icon>
                      </template> -->
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field dense
                      v-model="editedItem.read_interval"
                      label="Read Interval"
                    >
                    <template v-slot:append>
                      <v-tooltip
                        transition="slide-x-transition"
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small>
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <div style="max-width: 300px">
                          The time (in minutes) between readings sent by the sensor node.
                          Lowering the interval will shorten the battery life.
                        </div>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field dense
                      v-model="editedItem.threshold_count"
                      label="Threshold Count"
                    >
                      <template v-slot:append>
                        <v-tooltip
                          transition="slide-x-transition"
                          bottom
                        >
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small>
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          <div style="max-width: 300px">
                            Number of times threshold is crossed before the alert is triggered.
                            E.g. if it is set to 3, the alert condition will be met if threshold is crossed in last 3 consecutive readings.
                            If a data miss occurs along with a threshold crossing within the 3 readings window, it will be counted as a threshold crossing for worst case scenario.
                          </div>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field dense
                      v-model="editedItem.no_data_interval"
                      label="Disconnect Time"
                    >                      
                      <template v-slot:append>
                        <v-tooltip
                          transition="slide-x-transition"
                          bottom
                        >
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small>
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          <div style="max-width: 300px">
                            Time (in minutes) after which no-data alert will be triggered in case of no reading received from the sensor.
                          </div>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <!-- <v-text-field dense
                      v-model="editedItem.data_log_method"
                      label="Log Method"
                    ></v-text-field> -->
                    <v-autocomplete dense
                      v-model="editedItem.data_log_method"
                      :items="logMethod"
                      label="Log Method"
                    >
                     <template v-slot:append-outer>
                        <v-tooltip
                          transition="slide-x-transition"
                          bottom
                        >
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small>
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          <div style="max-width: 300px">
                            <strong>Smart</strong> (Recommended): Keeps all the readings for a period of 30 days. Beyond this period, the readings are averaged to one data-point per day in case of no threshold events. The readings around threshold events are not deleted.<br>
                            <strong>All</strong>: Keeps all the readings in the database.<br>
                            <strong>None</strong>: Keeps all the readings in the database.
                          </div>
                        </v-tooltip>
                     </template>
                  </v-autocomplete>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      v-model="editedItem.sensor_type"
                      :items="sensorTypes"
                      item-text="Description"
                      item-value="id"
                      label="Application ID"
                    ></v-autocomplete>
                  </v-col> -->
                  </v-row>
                  <v-row align="center">
                  <v-switch
                    v-model="active_switch"
                    :label="active_switch === true ? 'Enabled' : 'Disabled'"
                  ></v-switch>
                  
                  <!-- <v-col cols="4">
                    <v-checkbox
                      value
                      disabled
                    ></v-checkbox>
                  </v-col> -->
                  <!-- @change="updateState" -->
                  <!-- :label="`Active: ${editedItem.state.toString()}`" -->

                  <v-spacer></v-spacer>

                  <v-dialog
                    v-model="dialogDelDevice"
                    width="auto"
                    max-width="600px"
                    transition="slide-y-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="text-none font-weight-regular"
                        color="error"
                        text
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="confirmDelDevice"
                      >
                      <v-icon left>
                        mdi-delete
                      </v-icon>
                        Delete device
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Delete end device</span>
                      </v-card-title>
                      <v-card-text class="mt-3">
                        <p class="text-left font-weight-regular">
                          Are you sure you want to <b>permanently delete the device "{{editedItem.device_id}}"</b>?
                        </p>
                        <p>
                          This will delete the following {{device_sensor_list.length}} sensors and <b>all of their data</b>.
                        </p>

                        <!-- <v-data-table
                          dense
                          :headers="headers_data"
                          :items="userSensors"
                          :search="searchUserSensor"
                          class="elevation-1"
                          :footer-props="{'items-per-page-options': [10 ,15, 20, -1]}"
                        >
                          <template v-slot:[`item.symbol`]="{ item }">
                            <v-icon disabled>{{ item.symbol }}</v-icon>
                          </template>
                        </v-data-table> -->
                        
                        <v-list-item dense
                          v-for="(item, i) in device_sensor_list"
                          :key="i"
                        >
                          <v-list-item-icon>
                            <v-icon v-text="item.symbol"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-text="item.description"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <!-- <v-icon small color="warning">mdi-alert</v-icon> -->
                        <p class="mt-2 text-left font-weight-medium">
                          This action cannot be undone!
                        </p>
                        <!-- <v-container> -->
                          <v-row justify="center">
                            <v-col
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              <v-text-field
                                v-model="confirmDel"
                                persistent-hint
                                hint="Enter last 4 digits of device id"
                                outlined
                                dense
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        <!-- </v-container> -->
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          outlined
                          @click="closeDelDevice"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="error"
                          outlined
                          @click="delDevice"
                        >
                          Delete device
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- <v-btn color="error" text @click="dialogDelDevice = true">
                    Delete Device
                  </v-btn> -->
                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined text @click="close">
                Cancel
              </v-btn>
              <v-btn color="primary" outlined text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- STARTS: Warning Dialog for changing the sensor read interval -->
        <v-dialog
          v-model="dialogIntervalConfirm"
          width="auto"
          max-width="600px"
          transition="slide-y-transition"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Read Interval Update</span>
            </v-card-title>
            <v-card-text class="mt-3">
              <p class="text-left font-weight-regular">
                Are you sure you want to <b>change the read inteval of device "{{editedItem.device_id}}"</b>?
              </p>
              <p>
                This will change the read interval of the following {{device_sensor_list.length}} sensors.
              </p>

              <v-list-item dense
                v-for="(item, i) in device_sensor_list"
                :key="i"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.symbol"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.description"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- <v-icon small color="warning">mdi-alert</v-icon> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                outlined
                @click="dialogIntervalConfirm = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                outlined
                @click="updateSensorConfirm"
              >
                Update device
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- ENDS: Warning Dialog for changing the sensor read interval -->
        

        <!-- <template> -->
          <!-- <v-row justify="center"> -->
        
          <!-- </v-row> -->
        <!-- </template> -->

        <v-dialog v-model="dialogUsers" max-width="700px" transition="slide-y-transition">
          <!-- <v-container> -->
          <v-card>
            <!-- <v-card-title class="headline"
              >User Sensors</v-card-title
            > -->
            <template>
              <v-data-table
                dense
                :headers="headers_data"
                :items="sensorUsers"
                :search="searchSensorUsers"
                class="elevation-1"
                :footer-props="{'items-per-page-options': [10 ,15, 20, -1]}"
              >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Users Assigned</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  
                  <v-dialog scrollable v-model="dialogAddUser" max-width="600px" transition="slide-y-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        :disabled="selfScopes.includes('groups.manager')==0"
                        color="primary"
                        outlined
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="getUsers">
                        <!-- <v-icon small class="mr-1">mdi-plus</v-icon> -->
                        Add/Remove
                      </v-btn>
                    </template>
                    <v-card>
                      <v-toolbar flat>
                      <v-toolbar-title>Add/Remove Users</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <!-- <v-spacer></v-spacer> -->

                      <!-- <v-card-title>
                        <span class="headline">Add Sensors</span>
                      </v-card-title> -->
                      <!-- <v-text-field
                        class="mx-5"
                        v-model="searchUser"
                        append-icon="mdi-magnify"
                        label="Search"
                        hide-details
                        clearable
                      ></v-text-field> -->
                      <!-- clear-icon="mdi-close-circle-outline" -->
                      </v-toolbar>
                      <!-- <v-card-title>Cafe Badilico</v-card-title> -->
                      <v-card-text class="mt-3">
                        <v-container>
                        <v-row dense>
                          <!-- <v-col cols="12" sm="8" md="8"> -->
                            <v-autocomplete
                              :disabled="!usersSelected"
                              chips
                              multiple
                              deletable-chips
                              small-chips
                              v-model="usersSelected"
                              :items="users"
                              :item-text="users => users.first_name +' '+ users.last_name +' ('+ users.email +')'"
                              item-value="id"
                              label="Select Users"
                              outlined
                            ></v-autocomplete>
                          <!-- </v-col> -->
                        </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <!-- :disabled="!usersSelected.length" -->
                        <v-btn
                          color="primary"
                          text outlined
                          @click="addRemoveUsers"
                          >Update
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          outlined
                          @click="closeAddUsers"
                        >Cancel
                        </v-btn>
                        <!-- <v-btn
                          color="blue darken-1"
                          text
                          outlined
                          @click="saveAddSensor"
                        ><v-icon small class="mr-1">mdi-content-save</v-icon>
                          Save
                        </v-btn> -->
                      </v-card-actions>
                    </v-card>                  
                  </v-dialog>

                  <v-spacer></v-spacer>
                  <v-text-field
                    class="mx-3"
                    v-model="searchSensorUsers"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field> 
                </v-toolbar>
              </template>             
              <!-- <template v-slot:[`item.action`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mx-3"
                      @click="delSensor(item)"
                      color="red"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.view`]="{ item }">
                <v-switch
                  dense
                  v-model="item.view"
                  @change="changeUserSensorsView(item)"
                ></v-switch>
              </template>
               <template v-slot:[`item.alarm`]="{ item }">
                <v-switch
                  dense
                  v-model="item.alarm"
                  @change="changeUserSensorsView(item)"
                ></v-switch>
              </template> -->
              </v-data-table>
              <!-- <v-row>
                <v-col cols="10">
                  <v-autocomplete
                    class="ml-5 mt-4"
                    v-model="userSelected"
                    :items="sensors"
                    item-text="description"
                    item-value="id"
                    label="Select a sensor"
                    deletable-chips
                    small-chips
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="mt-5" cols="2">
                  <v-btn :disabled="!userSelected" color="blue darken-1" text outlined @click="addUserSensor">Add</v-btn>
                </v-col>
              </v-row> -->
            </template>
          </v-card>
          <!-- </v-container> -->
        </v-dialog>
        <v-spacer></v-spacer>
        <v-text-field
          class="mx-3"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <!-- Filter for active sensors -->
        <!-- <v-select
          :items="statusList"
          v-model="statusFilterValue"
          label="Filter"
          outlined
          dense
        ></v-select> -->
      <!-- </v-card> -->
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        v-if="selfScopes.includes('users.write') || selfScopes.includes('groups.manager')"
        small
        class="mr-3"
        @click="editUsers(item)"
        color="primary"
        dark
      >
        mdi-account-multiple
      </v-icon>
      <v-icon
        v-if="selfScopes.includes('sensors.write') || selfScopes.includes('groups.manager')"
        small
        class="mx-3"
        @click="editItem(item)"
        color="primary"
        dark
      >
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import api from "@/services/apiService";
import { showSnackbar } from "../globalActions";

// import apiTTN from "@/services/apiServiceTTN";

// import LineChart from './LineChart';
// import dayjs from 'dayjs';
  
export default {
  name: "Sensors",
  components: {
    // LineChart
  },
  data: () => ({
    search: '',
    dialog: false,
    dialogAddSensor: false,
    // dialogDelete: false,
    // dialogViewData: false,
    dialogUsers: false,
    dialogDelDevice: false,
    // headers: [
    //   { value: "symbol" },
    //   { text: 'Sensor Name', value: 'description' },
    //   // { text: "Type", value: "type" },
    //   { text: 'Location', value: 'location' },
    //   { text: 'Device Id', value: 'device_id', align: 'center' },
    //   // { text: 'Type', value: 'sensor_type' },
    //   // { text: 'App Id', value: 'app_id' },
    //   { text: 'Status', value: 'state', filter: this.statusFilter, divider: true },
    //   // { text: 'Min', value: 'min_val' },
    //   // { text: 'Max', value: 'max_val' },
    //   // { text: 'Threshold Count', value: 'threshold_count' },
    //   // { text: 'Read Interval', value: 'read_interval' },
    //   // { text: 'Group', value: 'sensor_group' },
    //   // { text: 'Email', value: 'email_alarm' },
    //   // { text: 'Log Method', value: 'data_log_method' },
    //   // { text: 'Origin', value: 'origin' },      
    //   { text: 'Actions', value: 'actions', align: 'center', sortable: false },
    // ],
    sensors: [],
    // responseSuccess: false,
    editedIndex: -1,
    editedItem: {
      id: 0,
      location: '',
      description: '',
      label: '',
      device_id: '',
      sensor_type: '',
      app_id: '5',
      state: 0,
      min_val: 0,
      max_val: 0,
      offset: 0,
      read_interval: '20',
      no_data_interval: '',
      threshold_count: 3,
      sensor_group: null,
      email_alarm: '',
      data_log_method: 'none',
      // origin: 1,
    },
    defaultItem: {
      id: 0,
      location: null,
      description: '',
      label: '',
      device_id: '',
      sensor_type: '',
      app_id: '',
      state: 0,
      min_val: 0,
      max_val: 0,
      offset: 0,
      read_interval: '20',
      no_data_interval: '',
      threshold_count: 3,
      sensor_group: null,
      // email_alarm: '{"hbijaz@igc.pt": "hbijaz"}',
      email_alarm: '{}',
      data_log_method: 'smart',
      origin: 1,
    },
    locations: [],
    sensorTypes: [],
    sensorData: [],
    sensorIndex: 0,
    active_switch: false,
    headers_data: [
      // show list of sensors access to
      { text: 'User', value: 'fullname'},
      { text: 'Email', value: 'email'},
      // { text: 'View', value: 'view'},
      // { text: 'Alarm', value: 'alarm', divider: true },
      // { text: 'Delete', value: 'action',  align: 'center', sortable: false },
      ],
    logMethod: ['smart', 'all', 'none'],
    selfScopes: [],
    // dateStart: new Date().toISOString().substr(0, 10),
    // dateEnd: new Date().toISOString().substr(0, 10),
    dateStart: '',
    dateEnd: '',
    loaded: false,
    cdata: [],
    labels: [],
    toggle_exclusive: 0,
    sensorUsers: [],
    searchSensorUsers: '',
    // userSelected: '',
    sensorDescription: '',
    sensorLabel: '',
    groups: [],
    sensorTypesSelected: [],
    sensorTypesList: [],

    // sensorModels: ['LHT65', 'ERSCO2'],
    sensorModelSelected: '',
    freqPlan: ['EU_863_870_TTN', 'US_902_928_FSB_2'],
    freqPlanSelected: '',
    // lorawanVersion: ['PHY_V1_0_3_REV_A'],
    lorawanVersionSelected: 'PHY_V1_0_3_REV_A',
    // phyVersion: ['MAC_V1_0_3'],
    phyVersionSelected: 'MAC_V1_0_3',
    devEUI: '',
    appEUI: '',
    appKey: '',
    device_id: '',
    ttnAppData: [],
    ttnAppDataList: [],
    ttnAppDataSelected: [],
    my_origin: 0,
    originTag: '',
    ownerId: 0,
    userGroupSelected: null,
    usersGroupsList: [],
    deviceInfo: '/LHT65.pdf',
    vDevModel: false,
    vDevEUI: false,
    vSelectSensors: false,
    vFreqPlan: false,
    vAppEUI: false,
    vAppKey: false,
    vDevId: false,
    vGroup: false,
    confirmDel: '',
    device_sensor_list: [],
    dialogAddUser: false,
    users: [],
    usersSelected: [],
    temp_read_interval: '',
    tab: null,
    dialogIntervalConfirm: false,
    statusList: [
      {text: "All Sensors", value: null},
      {text: "Active Sensors", value: 1},
      {text: "Disabled Sensors", value: 0},
      {text: "Pending Configuration", value: 2},
    ],
    statusFilterValue: null,
    // filter: this.statusFilter,
  }),

  computed: {
    headers() {
      return [
      { value: "symbol" },
      { text: 'Sensor Name', value: 'description' },
      // { text: "Type", value: "type" },
      { text: 'Location', value: 'location' },
      { text: 'Device Id', value: 'device_id', align: 'center' },
      // { text: 'Type', value: 'sensor_type' },
      // { text: 'App Id', value: 'app_id' },
      { text: 'Status', value: 'state', filter: this.statusFilter, divider: true },
      // { text: 'Min', value: 'min_val' },
      // { text: 'Max', value: 'max_val' },
      // { text: 'Threshold Count', value: 'threshold_count' },
      // { text: 'Read Interval', value: 'read_interval' },
      // { text: 'Group', value: 'sensor_group' },
      // { text: 'Email', value: 'email_alarm' },
      // { text: 'Log Method', value: 'data_log_method' },
      // { text: 'Origin', value: 'origin' },      
      { text: 'Actions', value: 'actions', align: 'center', sortable: false },
    ]
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogAddSensor(val) {
      val || this.closeAddSensors();
    },
    dialogUsers(val) {
      val || this.closeUsers();
    },
    dialogDelDevice(val) {
      val || this.closeDelDevice;
    },
    // dialogAddUser(val) {
    //   val || this.closeAddUsers();
    // },
    devEUI() {
      this.device_id = this.originTag + '-' + this.devEUI.toLowerCase();
      this.devEUI = this.devEUI.toUpperCase();
    },
    sensorModelSelected() {
      // const ttt = this.ttnAppData.find(element => element.id === this.sensorModelSelected);
      // this.sensorTypesList = this.sensorTypes.filter(element => element.app_id === ttt.id);
      this.sensorTypesList = this.sensorTypes.filter(element => element.app_id === this.sensorModelSelected);
      // this.sensorTypesList = tempvar.map(
      //   ({Description, id})=> { 
      //     return { Description, id }
      //   });
    },
  },

  // computed: {
  // //   computeLocation(item) {
  // //     console.log("~ item", item);
  // //     return this.locations.find(element => element.item === this.locations.id).description;
  // //   },
  // },

  mounted() {
    this.getUserDetails();
    this.getSensors();
    this.getLocations();
    if (this.selfScopes.includes('sensors.write') || this.selfScopes.includes('groups.manager')) {
      this.getSensorTypes(); 
    }
    this.getGroups();
    this.getSensorsNotifications(); // to sync the notifications in case changed by another user
  },

  methods: {
  // initialize () {
  //     },
    statusFilter(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.statusFilterValue) {
        return true;
      }

      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.statusFilterValue;
    },
    snr(snr) {
      if (snr > 4) {
        return 'mdi-wifi-strength-4';
      }
      else if (snr <= 4 && snr > 0) {
        return 'mdi-wifi-strength-3'
      }
      else if (snr <= 0 && snr > -7) {
        return 'mdi-wifi-strength-2'
      }
      else if (snr <= -7 && snr >= -10) {
        return 'mdi-wifi-strength-1'
      }
      else return 'mdi-wifi-strength-outline'
    },
    bat(item) {
      var batSpan = item.bat_max - item.bat_min;
      if (item.bat_max && item.bat_min) {
        if ( item.bat > item.bat_max ) {
          if ( item.sf > 10 ) return 'mdi-battery-alert-variant';
          else return 'mdi-battery';
        }
        else if ( item.bat > item.bat_min + (batSpan) * 0.9 ) {
          return 'mdi-battery-90'
        }
        else if ( item.bat > item.bat_min + (batSpan) * 0.8 ) {
          return 'mdi-battery-80'
        }
        else if ( item.bat > item.bat_min + (batSpan) * 0.7 ) {
          return 'mdi-battery-70'
        }
        else if ( item.bat > item.bat_min + (batSpan) * 0.6 ) {
          return 'mdi-battery-60'
        }
        else if ( item.bat > item.bat_min + (batSpan) * 0.5 ) {
          return 'mdi-battery-50'
        }
        else if ( item.bat > item.bat_min + (batSpan) * 0.4 ) {
          return 'mdi-battery-40'
        }
        else if ( item.bat > item.bat_min + (batSpan) * 0.3 ) {
          return 'mdi-battery-30'
        }
        else if ( item.bat > item.bat_min + (batSpan) * 0.2 ) {
          return 'mdi-battery-20'
        }
        else if ( item.bat >= item.bat_min + (batSpan) ) {
          return 'mdi-battery-10'
        }
        else if (item.bat < item.bat_min + (batSpan)) {
          return 'mdi-battery-outline'
        }
      }
      else return 'mdi-battery-unknown'
    },
    // sf(sf) {
    //   if (sf == 6 || sf == 7) {
    //     return 'mdi-emoticon-outline';
    //   }
    //   else if (sf == 8 || sf == 9) {
    //     return 'mdi-emoticon-happy-outline'
    //   }
    //   else if (sf == 10) {
    //     return 'mdi-emoticon-neutral-outline'
    //   }
    //   else return 'mdi-emoticon-sad-outline'
    // },
    getUserDetails() {
      // get user details from the token to set the env according to permissions
      if (localStorage.token) {
        // get token from localstorage
        let token = localStorage.getItem("token");
        let decoded = JSON.parse(atob(token.split('.')[1]));
        this.my_origin = decoded.origin;
        this.selfScopes = decoded.scopes;
        this.ownerId = decoded.id;
      }
      else {
        console.log('token not found');
        // generate and error pop-up saying invalid session. Please login!
      }
    },
    getSensors: async function() {
      const temp = await api.getSensors();
      if( Array.isArray(temp) ) {
        this.sensors = temp;
      }
    },

    getAppsTTN: async function() {
      this.ttnAppData = await api.getAppDataTTN();
      this.ttnAppData.sort((a, b) => a.description.localeCompare(b.description));
      // this.ttnAppDataList = this.ttnAppData.map(a => a.description);
      this.originTag = await api.getOriginTag(this.my_origin);

      // this.getOwnerGroups();
      this.getGroups();
    },

    addSensorTTN: async function() {
      // if (this.devEUI && this.appEUI && this.appKey && this.device_id && this.freqPlanSelected && this.sensorModelSelected) {
      //   const network_server_address = "eu1.cloud.thethings.network";
      //   const application_server_address = "eu1.cloud.thethings.network";
      //   const join_server_address = "eu1.cloud.thethings.network";
        this.ttnAppDataSelected = this.ttnAppData.find(element => element.id === this.sensorModelSelected);
      //   const config = {
      //     headers: {
      //       'Authorization': 'Bearer ' + this.ttnAppDataSelected.access_key,
      //       'Content-Type': 'application/json'
      //     },
      //   };
      //   // console.log("~ config", config);
        
      //   // EndDeviceRegistry.Create (Identity Server)
      //   const create_device_data = {
      //     "end_device": {
      //       "ids": {
      //         "device_id": this.device_id,
      //         "dev_eui": this.devEUI,
      //         "join_eui": this.appEUI,
      //         "application_ids":{
      //           "application_id": this.ttnAppDataSelected.app_id
      //         }
      //       },
      //       "network_server_address": network_server_address,
      //       "application_server_address": application_server_address,
      //       "join_server_address": join_server_address
      //     },
      //     "field_mask": {
      //       "paths": [
      //         "network_server_address",
      //         "application_server_address",
      //         "join_server_address"
      //       ]
      //     }
      //   };
      //   await apiTTN.createDevice(create_device_data, config);

      //   // NsEndDeviceRegistry.Set (Network Server)
      //   const register_network_server = {
      //     "end_device": {
      //       "ids": {
      //         "device_id": this.device_id,
      //         "dev_eui": this.devEUI,
      //         "join_eui": this.appEUI,
      //         "application_ids":{
      //           "application_id": this.ttnAppDataSelected.app_id
      //         }
      //       },
      //       "frequency_plan_id": this.freqPlanSelected,
      //       "lorawan_phy_version": this.lorawanVersionSelected,
      //       "multicast": false,
      //       "supports_join": true,
      //       "lorawan_version": this.phyVersionSelected,
      //       "supports_class_c": false,
      //       "supports_class_b": false,
      //       "mac_settings": {
      //         "rx2_data_rate_index": 0,
      //         "rx2_frequency": 869525000
      //       }
      //     },
      //     "field_mask": {
      //       "paths": [
      //         "frequency_plan_id",
      //         "lorawan_phy_version",
      //         "multicast",
      //         "supports_join",
      //         "lorawan_version",
      //         "ids.dev_eui",
      //         "ids.join_eui",
      //         "ids.device_id",
      //         "ids.application_ids.application_id",
      //         "supports_class_c",
      //         "supports_class_b",
      //         "mac_settings.rx2_data_rate_index",
      //         "mac_settings.rx2_frequency"
      //       ]
      //     }
      //   };
      //   await apiTTN.regNetworkServer(register_network_server, this.device_id, config);

      //   // AsEndDeviceRegistry.Set (Application Server)
      //   const register_app_server = {
      //     "end_device": {
      //       "ids": {
      //         "device_id": this.device_id,
      //         "dev_eui": this.devEUI,
      //         "join_eui": this.appEUI,
      //         "application_ids":{
      //           "application_id": this.ttnAppDataSelected.app_id
      //         }
      //       },
      //     },
      //   "field_mask": {
      //     "paths": [
      //       "ids.dev_eui",
      //       "ids.join_eui",
      //       "ids.device_id",
      //       "ids.application_ids.application_id"
      //       ]
      //     }
      //   };
      //   await apiTTN.regAppServer(register_app_server, this.device_id, config);

      //   // JsEndDeviceRegistry.Set (Join Server, only for OTAA devices)
      //   const register_join_server = {
      //     "end_device": {
      //       "ids": {
      //         "device_id": this.device_id,
      //         "dev_eui": this.devEUI,
      //         "join_eui": this.appEUI,
      //         "application_ids":{
      //           "application_id": this.ttnAppDataSelected.app_id
      //         }
      //       },
      //       "network_server_address": network_server_address,
      //       "application_server_address": application_server_address,
      //       "root_keys": {
      //         "app_key": {
      //           "key": this.appKey
      //         }
      //       }
      //     },
      //     "field_mask": {
      //       "paths": [
      //         "network_server_address",
      //         "application_server_address",
      //         "ids.dev_eui",
      //         "ids.join_eui",
      //         "ids.device_id",
      //         "ids.application_ids.application_id",
      //         "root_keys.app_key.key"
      //       ]
      //     }
      //   };
      //   await apiTTN.regJoinServer(register_join_server, this.device_id, config);

      //   // get the sensor types from db associated with the selected sensor model
      //   const sensor_types_list = this.sensorTypes.filter(element => element.app_id === this.ttnAppDataSelected.id);

      //   for (var i = 0; i < sensor_types_list.length; i++) {
      //     // this.editedItem.sensor_type = sensor_types_list[i].id;
          
      //     const sensorRequest = {
      //       location:         this.defaultItem.location,
      //       description:      this.sensorDescription,
      //       label:            this.defaultItem.label,
      //       device_id:        this.device_id,
      //       sensor_type:      sensor_types_list[i].id,
      //       app_id:           this.ttnAppDataSelected.id,
      //       state:            this.defaultItem.state,  // var i = result ? 1 : 0;
      //       min_val:          this.defaultItem.min_val,
      //       max_val:          this.defaultItem.max_val,
      //       offset:           this.defaultItem.offset,
      //       read_interval:    this.defaultItem.read_interval,
      //       threshold_count:  this.defaultItem.threshold_count,
      //       sensor_group:     this.defaultItem.sensor_group,
      //       email_alarm:      JSON.stringify(this.defaultItem.email_alarm),
      //       data_log_method:  this.defaultItem.data_log_method,
      //       origin:           this.my_origin
      //     };
      //     await api.addSensor(sensorRequest);
      //     // this.addSensor(sensorRequest);
      //   }
      //   this.sensorDescription = '',
      // let snack_message = this.device_id + ' registered successfully. Please edit the associated sensors in the sensors menu to configure and enable the sensors';
      // showSnackbar(snack_message, 'success');
      //   this.getSensors();
      //   this.closeAddSensors();
      // }

      // server side registration
      if (this.devEUI && this.appEUI && this.appKey && this.device_id && this.freqPlanSelected && this.sensorModelSelected ) {
        // const ttnAppSelectedID = this.ttnAppData.find(element => element.id === this.sensorModelSelected).app_id;
        // POST https://www.oscilas.com/api/iot HTTP/1.1
        // Authorization: {{auth}}
        // content-type: application/json
        // {
        //   "device_id":"igc-A84041531183AA86",
        //   "dev_eui":"A84041531183AA86",
        //   "join_eui":"A000000000000101",
        //   "app_id":"igc-dragino-ltc2-lt",
        //   "freq_plan":"EU_863_870_TTN",
        //   "app_key":"99A95C481D7B86D3A2D67E3F559461CF",
        //   "dest":"ttn"
        // }
        const registerSensorRequest = {
          "device_id": this.device_id,
          "dev_eui": this.devEUI,
          "join_eui": this.appEUI,
          "app_id": this.ttnAppDataSelected.app_id,
          "freq_plan": this.freqPlanSelected,
          "app_key": this.appKey,
          "dest":"ttn"
        }
        const resp = await api.createDevice(registerSensorRequest);        

        if(resp == 200) {
          // get the sensor types from db associated with the selected sensor model
          const sensor_types_list = this.sensorTypes.filter(element => element.app_id === this.ttnAppDataSelected.id);

          const sensorsAddPayload = [];

          for (var i = 0; i < sensor_types_list.length; i++) {
            var stateActual = this.defaultItem.state;
            if (this.sensorTypesSelected.includes(sensor_types_list[i].id)) {
              stateActual = 2;
            }
            
            sensorsAddPayload.push({
              location:         this.defaultItem.location,
              description:      this.sensorDescription,
              label:            this.sensorLabel,
              device_id:        this.device_id,
              sensor_type:      sensor_types_list[i].id,
              app_id:           this.ttnAppDataSelected.id,
              state:            stateActual,  // var i = result ? 1 : 0;
              min_val:          this.defaultItem.min_val,
              max_val:          this.defaultItem.max_val,
              offset:           this.defaultItem.offset,
              read_interval:    this.defaultItem.read_interval,
              threshold_count:  this.defaultItem.threshold_count,
              no_data_interval: Math.max((this.defaultItem.read_interval * this.defaultItem.threshold_count), 60 ), // minimum is 60 min
              sensor_group:     this.userGroupSelected,
              email_alarm:      JSON.stringify(this.defaultItem.email_alarm),
              data_log_method:  this.defaultItem.data_log_method,
              origin:           this.my_origin
            });
          }
          await api.addMultipleSensors(sensorsAddPayload);
        }
        // let snack_message = this.device_id + ' registered successfully. Please edit the associated sensors in the sensors menu to configure and enable the sensors';
        // showSnackbar(snack_message, 'success');
        this.getSensors();
        this.getSensorsNotifications();
        this.closeAddSensors();
        this.sensorDescription = '';
        this.sensorLabel = '';
      }
      else {
        showSnackbar("All fields are required!", 'error');
      }
    },
    
    updateSensor: async function() {
      // check if the read interval is modified and only include in the payload if it is modified
      const sensorRequest = {
        location:         this.editedItem.location,
        description:      this.editedItem.description,
        label:            this.editedItem.label,
        device_id:        this.editedItem.device_id,
        sensor_type:      this.editedItem.sensor_type,
        app_id:           this.editedItem.app_id,
        state:            this.editedItem.state,  // var i = result ? 1 : 0;        
        min_val:          this.editedItem.min_val,
        max_val:          this.editedItem.max_val,
        offset:           this.editedItem.offset,
        threshold_count:  this.editedItem.threshold_count,
        no_data_interval: this.editedItem.no_data_interval,
        sensor_group:     this.editedItem.sensor_group,
        email_alarm:      JSON.stringify(this.defaultItem.email_alarm),
        data_log_method:  this.editedItem.data_log_method,
        origin:           this.editedItem.origin
      };
      if( this.temp_read_interval != this.editedItem.read_interval ) {
        sensorRequest.new_read_interval = this.editedItem.read_interval;
        this.device_sensor_list = this.sensors.filter(element => element.device_id === this.editedItem.device_id);
        this.dialogIntervalConfirm = true;
      }
      else {
        await api.updateSensor(this.editedItem.id, sensorRequest);
        // initialize the editedItem with default values
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.active_switch = false;
        // this.dialogIntervalConfirm = false;
        this.getSensors();
        this.getSensorsNotifications();
        this.close();
      }
    },
    updateSensorConfirm: async function() {
      // check if the read interval is modified and only include in the payload if it is modified
      const sensorRequest = {
        location:           this.editedItem.location,
        description:        this.editedItem.description,
        label:              this.editedItem.label,
        device_id:          this.editedItem.device_id,
        sensor_type:        this.editedItem.sensor_type,
        app_id:             this.editedItem.app_id,
        state:              this.editedItem.state,  // var i = result ? 1 : 0;        
        min_val:            this.editedItem.min_val,
        max_val:            this.editedItem.max_val,
        offset:             this.editedItem.offset,
        threshold_count:    this.editedItem.threshold_count,
        new_read_interval:  this.editedItem.read_interval,
        no_data_interval:   this.editedItem.no_data_interval,
        sensor_group:       this.editedItem.sensor_group,
        email_alarm:        JSON.stringify(this.defaultItem.email_alarm),
        data_log_method:    this.editedItem.data_log_method,
        origin:             this.editedItem.origin
      };
      
      await api.updateSensor(this.editedItem.id, sensorRequest);
      // initialize the editedItem with default values
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.active_switch = false;
      this.dialogIntervalConfirm = false;
      this.getSensors();
      this.getSensorsNotifications();
      this.close();
    },
    getSensorsNotifications: async function() {
      const n = await api.getSensorsNotifications();
      if (typeof n !== 'undefined' && n !== null) {
        const nt = { field: 'sensors', value: n };
        this.$emit('changeNotifications', nt);
      }
    },
    getLocations: async function() {
      const temp = await api.getLocations();
      if( Array.isArray(temp) ) {
        this.locations = temp;
      }
    },
    getSensorTypes: async function() {
      this.sensorTypes = await api.getSensorTypes();
    },
    getSensorIdData: async function() {
      this.sensorData = await api.getSensorIdData(this.sensorIndex);
    },
    resetState () {
        this.loaded = false;
        // this.showError = false;
    },
    requestData () {
      this.resetState();
      // this.getSensorIdData();
      this.getSensorDataRange();
    },
    getSensorDataRange: async function() {
      // this.sensorData = await api.getSensorDataRange(this.sensorIndex, this.dateStart, this.dateEnd);
      this.sensorData = await api.getSensorDataRange(this.sensorIndex, this.dateStart, this.dateEnd);
      // console.log(Object.keys(this.sensorData));
      this.cdata = this.sensorData.map(sdata => sdata.value);
      this.labels = this.sensorData.map(sdata => sdata.event_time);
      this.loaded = true;
    },
    editItem(item) {
      // call the api for latest sensor details
      this.editedIndex = this.sensors.indexOf(item);
      this.editedItem = Object.assign({}, item);  // [TBD] Note: Object.assign() does not throw on null or undefined sources.
      this.temp_read_interval = this.editedItem.read_interval;
      if (this.editedItem.state == 1) {
        this.active_switch = true;
      }
      else {
        this.active_switch = false;
      }
      this.dialog = true;
    },
    // getSensorDetails: async function() {
    //   const temp = await api.getSensorId();
    //   if( Array.isArray(temp) ) {
    //     this.sensors = temp;
    //   }
    // },
    // viewItem: async function(item) {
    //   // this.sensorIndex = this.sensors.indexOf(item);
    //   // this.sensorIndex = Object.values(item)[0];
    //   this.sensorIndex = item.id;
    //   this.sensorDescription = item.description;
    //   // this.sensorIndex = this.sensors.indexOf(item) + 1;
    //   // this.getSensorIdData();
    //   // await this.requestData();
    //   this.plotGraph(0);
    //   // this.toggle_exclusive = undefined; // this will execute the plotGraph through watch
    //   // this.getSensorDataRange();
    //   // this.dialogViewData = true;
    //   // route to graph with props for the following sensor and default period of one day
    //   // this.$router.push("/Graph");
    // },
    
    // addUserSensor() {
    //   const requestBody = {
    //     sensor: this.userSelected,
    //     view: 1, // this.userViewSwitch & 1;
    //     alarm: 1, // this.userViewSwitch | 0;
    //   };
    //   console.log(requestBody);
    //   api.addUserSensor(this.userIndex, requestBody);
    //   // refresh the list
    //   this.userSelected = '';
    //   this.getUserSensors();
    // },
    
    editUsers: async function(item) {
      // this.sensorIndex = Object.values(item)[0];
      this.sensorIndex = item.id;
      await this.getSensorUsers(item.id);
      this.dialogUsers = true;
    },
    getSensorUsers: async function(sensorId) {
      const temp = await api.getSensorUsers(sensorId);
      if( Array.isArray(temp) ) {
        this.sensorUsers = temp;
        // console.log("~ this.sensorUsers", this.sensorUsers);
      }
      // this.userViewSwitch = Boolean(this.userSensors.view)
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.temp_read_interval = '';
        this.editedIndex = -1;
        this.active_switch = false;
      });
    },
    closeAddSensors() {
      this.dialogAddSensor = false;
      this.usersGroupsList = [];
      this.userGroupSelected = null;
      this.vDevModel = false;
      this.vDevEUI = false;
      this.vSelectSensors = false;
      this.vFreqPlan = false;
      this.vAppEUI = false;
      this.vAppKey = false;
      this.vDevId = false;
      this.vGroup = false;

      this.$nextTick(() => {
        this.sensorModelSelected = '';
        this.freqPlanSelected = '';
        this.devEUI = '';
        this.appEUI = '';
        this.appKey = '';
        this.sensorDescription = '';
        this.sensorLabel = '';
      });
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      //   this.active_switch = false;
      // });
    },
    // closeViewData() {
    //   // this.dialogViewData = false;
    //   this.toggle_exclusive = 0,
    //   this.sensorIndex = 0;
    //   this.cdata = [];
    //   this.labels = [];
    // },
    closeUsers() {
      this.dialogUsers = false;
      this.sensorUsers = [];
      // this.userIndex = 0;
      // this.userSelected = '';
      this.searchSensorUsers = '';
    },
    save() {
      if (this.active_switch == false) {
        this.editedItem.state = 0;
      }
      else {
        this.editedItem.state = 1;
      }
      if (this.editedIndex > -1) {
        this.updateSensor();
        // Object.assign(this.sensors[this.editedIndex], this.editedItem); // [TBD]: only execute this when the update request succeeds. Not needed if making a get request to update
      } 
      // else {
      //   // this.sensors.push(this.editedItem);
      //   this.addSensor();
      // }
      // this.close();
    },    
    // saveAddSensors() {

    //   this.closeAddSensors();
    // },
    getGroups: async function() {
      this.groups = await api.getGroups();
    },

    computeLocation(item) {
      const temp = this.locations.find(element => element.id === item);
      if(temp) {
        return temp.location;
      }
      else return 'None';
    },
    getOwnerGroups: async function() {
      const temp = await api.getOwnerGroups(this.ownerId);
      if( Array.isArray(temp) ) {
        // this.usersGroupsList = this.groups.find(element => element.id === temp);
        this.usersGroupsList = temp;
      }
    },

    confirmDelDevice: async function() {
      this.device_sensor_list = this.sensors.filter(element => element.device_id === this.editedItem.device_id);
    },
    closeDelDevice() {
      this.confirmDel = '';
      this.dialogDelDevice = false;
    },

    delDevice: async function() {
      // check the confirmation string
      if(!this.confirmDel) {
        // send the request to delete
          showSnackbar("Please enter the confirmation string!", 'warning');
      }
      else if(this.confirmDel == this.editedItem.device_id.slice(this.editedItem.device_id.length - 4)) {
        // send the request to delete
        const respDelDevice = await api.delDevice(this.editedItem.id);
        // upon success 1: intialize the text field and close the dialog, 2: initialize and close sensor configure dilog, 3: get sensors to update the table
        if (respDelDevice.status == 200) {
          this.confirmDel = '';
          this.dialogDelDevice = false;
          this.close();
          this.getSensors();
          showSnackbar(respDelDevice.statusText, 'green');
        }
      }
      else {
        showSnackbar("String entered is incorrect. Deletion failed!", 'error');
      }
    },

    closeAddUsers() {
      this.dialogAddUser = false;
      // this.usersSelected = [];
    },

    addRemoveUsers: async function() {
      // addRemoveUserSensors: async function() {
        // add sensors if new user added
        // var sensorUsersIds = this.sensorUsers.map(function(x) {
        //   return x.id;
        // });
        let sensorUsersIds = this.sensorUsers.map( x => x.id ); // [TEST]: The case when sensorUsers is empty
        let newUserSensorstoAdd = this.usersSelected.filter( x => !sensorUsersIds.includes(x) );
        if (newUserSensorstoAdd.length) {
          const requestBody = {
            sensor: this.sensorIndex,
            view: 1, // this.userViewSwitch & 1;
            alarm: 1, // this.userViewSwitch | 0;
            no_data_alarm: 0,
          };
          for (let i=0; i< newUserSensorstoAdd.length; i++) {
            await api.addUserSensor(newUserSensorstoAdd[i], requestBody);
          }
        }
        // Remove sensors if user removed from the list
        let newUserSensorstoDel = sensorUsersIds.filter(x => !this.usersSelected.includes(x));
        if (newUserSensorstoDel.length) {
          const userRequest = {
            data: {
              user: '',
            }
          };
          for (let i=0; i< newUserSensorstoDel.length; i++) {
            userRequest.data.user = newUserSensorstoDel[i];
            await api.delUserSensor(this.sensorIndex, userRequest);
          }
        }
        this.usersSelected = [];
        this.getSensorUsers(this.sensorIndex);  // [TBD]: this should be executed on when add or delete was a success
        this.closeAddUsers();
    },
    updateUsersSelected: async function() {
      this.usersSelected = [];
      // Use map function or similar instead of for loop [TBD]
      for(let x in this.sensorUsers) {
        this.usersSelected.push(this.sensorUsers[x].id);
      }
    },
    getUsers: async function() {
      const temp = await api.getUsers();
      if( Array.isArray(temp) ) {
        this.users = temp.filter(element => element.status === 1);
      }
      this.updateUsersSelected();
    },

    // {
    // "device_id":"igc-647fda000000ffff",
    // "app_id":"igc-tektelic-t000617",
    // "dest":"ttn"
    // }

  },
};
</script>
<style lang="scss" scoped>
  @import '~vuetify/src/components/VBadge/_variables.scss';

  .v-badge {
    $badge-color:'grey';
  }
</style>