<template>
<section id="home">
  <v-parallax dark src="@/assets/img/bg0.jpg" height="850">
    <v-row align="center" justify="center">
      <v-col cols="11">
        <v-row align="center" justify="center">
          <v-col cols="12" md="6" xl="8">

            <v-card
              elevation="0"
              class="mx-auto"
              color="rgb(255, 0, 0, 0)"
              dark
            >
            <!-- color="transparent" -->
              <v-card-text>
                <v-row align="center">
                  <v-col cols="2">
                    <v-img
                      :src="require('@/assets/img/oscilas.png')"
                      alt="beUave logo"
                      width="90"
                    ></v-img>
                  </v-col>
                  <v-col
                    class="display-3 font-weight-bold"
                    cols="8"                  
                  >
                    <!-- :class="{'subheading': $vuetify.breakpoint. smAndDown, 'display-3': $vuetify.breakpoint. mdAndUp}"  -->
                    Scisensing
                  </v-col>
                  
                </v-row>
                <v-row
                    align="center"
                  >
                  <v-col
                    cols="2"
                  >    
                  </v-col>
                  <v-col
                    class="text-h5"
                  >
                    Your research matters
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <!-- <v-container>
            <v-img
              :src="require('@/assets/img/oscilas.png')"
              contain
              height="70"
              />
            <h1 class="display-2 font-weight-bold mb-4">beUave</h1>
            <h1 class="font-weight-light">
              LoRa-based sensors for research infrastructures
            </h1>
            </v-container> -->
            <!-- <v-btn
              rounded
              outlined
              large
              dark
              @click="$vuetify.goTo('#features')"
              class="mt-5"
            >
              Learn More
              <v-icon class="ml-2">mdi-arrow-down</v-icon>
            </v-btn> -->

          </v-col>
          <!-- <v-spacer></v-spacer> -->
          <v-col>
            <login />
          </v-col>
          <v-col cols="12" md="6" xl="4" class="hidden-sm-and-down"> </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-parallax>
  <!-- <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <v-col cols="12" class="text-center">
              <h1 class="font-weight-light display-2">Title</h1>
              <h1 class="font-weight-light">
                Description of the items that follow
              </h1>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular">{{ feature.title }}</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container> -->
</section>
</template>

<script>
import login from '@/components/Login.vue';

export default {
  components: {
    login,
  },
  data() {
    return {
      // features: [
      //   {
      //     img: require("@/assets/img/oscilas.svg"),
      //     title: "First",
      //     text: "Brief overview.",
      //   },
      //   {
      //     img: require("@/assets/img/oscilas.svg"),
      //     title: "Second",
      //     text: "Brief overview.",
      //   },
      //   {
      //     img: require("@/assets/img/oscilas.svg"),
      //     title: "Third",
      //     text: "Brief overview.",
      //   },
      // ],
    };
  },

  methods: {
  },
};
</script>

<style>
/* .card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-top: 10px;
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
} */

/* .zoom-efect {
  transform: scale(1.1);
} */

/* .up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
} */
</style>
