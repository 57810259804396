<template>
  <!-- <v-container> -->
  <v-tabs class="mt-5">
    <v-tab>
      Information
    </v-tab>
    <v-tab>
      Password
    </v-tab>

    <v-tab-item>
      <v-form
        @submit.prevent="updateAccount"
        class="mb-5"
      >
        <v-card flat>
          <v-card-title class="my-5">
            Edit Name and Email
          </v-card-title>
          <!-- <v-divider></v-divider> -->
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="3" md="4">
                <v-text-field
                  label="First Name"
                  v-model="accountForm.firstName"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="4">
                <v-text-field
                  label="Last Name"
                  v-model="accountForm.lastName"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="8">
                <v-text-field
                  disabled
                  label="Email"
                  v-model="accountForm.email"
                  type="email"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <!-- <v-btn color="primary" outlined :loading="accountUpdating" @click.native="updateAccount"> -->
            <v-btn color="primary" outlined :loading="accountUpdating" type="submit">
              <!-- <v-icon left dark>mdi-save</v-icon> -->
              Save Changes
            </v-btn>
          </v-card-actions>

          <!-- <div class="text-center">
            <v-btn class="mb-5" outlined :loading="accountUpdating" color="primary" type="submit">
              Update
            </v-btn>
          </div> -->
          
        </v-card>
      </v-form>
    </v-tab-item>
    <v-tab-item>
        <!-- ref="passForm"
        v-model="valid"
        lazy-validation -->
      <v-form 
        @submit.prevent="changePassword"
      >
        <v-card
          flat
          icon="mdi-lock"
          :title="passwordForm.password"
        >
          <v-card-title class="my-5">
            Change Password
          </v-card-title>
          <!-- <v-divider></v-divider> -->
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="3" md="4">
                <v-text-field
                  label="Current Password"
                  v-model="passwordForm.current_password"
                  required
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" md="4">
                <v-text-field
                  label="New Password"
                  v-model="passwordForm.password"
                  :rules="[rules.required, rules.min, rules.strength]"
                  validate-on-blur
                  required
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                ></v-text-field>
                <v-progress-linear
                  :color="score.color"
                  :value="score.value"
                  rounded
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" md="4">
                <v-text-field
                  label="Confirm Password"
                  v-model="passwordForm.password_confirmation"
                  required
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show3 ? 'text' : 'password'"
                  @click:append="show3 = !show3"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <!-- <v-btn color="primary" outlined :loading="passwordChanging" @click.native="submit"> -->
            <v-btn color="primary" outlined :loading="passwordChanging" type="submit">
              <!-- <v-icon left dark>mdi-save</v-icon> -->
              Save Changes
            </v-btn>
          </v-card-actions>
          <!-- <div class="text-center">
            <v-btn class="mb-5" outlined :loading="passwordChanging" color="warning" type="submit">
              update
            </v-btn>
          </div> -->
        </v-card>
      </v-form>
    </v-tab-item>
  </v-tabs>

    <!-- <v-layout align-center justify-center>
      <div>
        <v-row>
          <form @submit.prevent="updateAccount" class="mb-5">
            <v-card>
              <v-card-title>
                Edit Name and Email
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="3" md="4">
                    <v-text-field
                      label="First Name"
                      v-model="accountForm.firstName"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="4">
                    <v-text-field
                      label="Last Name"
                      v-model="accountForm.lastName"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="4">
                    <v-text-field
                      label="Email"
                      v-model="accountForm.email"
                      type="email"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <div class="text-center">
                <v-btn class="mb-2" outlined small rounded :loading="accountUpdating" color="primary" type="submit">
                  Update
                </v-btn>
              </div>
            </v-card>
          </form>
        </v-row>
        <v-row>
          <form @submit.prevent="changePassword">
            <v-card
              icon="mdi-lock"
              :title="passwordForm.password"
            >
              <v-card-title>
                Change Password
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="3" md="4">
                    <v-text-field
                      label="Current Password"
                      type="password"
                      v-model="passwordForm.current_password"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="4">
                    <v-text-field
                      label="New Password"
                      type="password"
                      v-model="passwordForm.password"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="4">
                    <v-text-field
                      label="Confirm Password"
                      type="password"
                      v-model="passwordForm.password_confirmation"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <div class="text-center">
                <v-btn class="mb-2" outlined small rounded :loading="passwordChanging" color="warning" type="submit">
                  update
                </v-btn>
              </div>
            </v-card>
          </form>
        </v-row>
      </div>
    </v-layout> -->
</template>

<script>
import api from "@/services/apiService";
import zxcvbn from "zxcvbn";

// import axios from "axios";
// import { showSnackbar } from "../globalActions";

export default {
  data() {
    return {
      accountUpdating: false,
      passwordChanging: false,
      accountForm: {
        firstName: null,
        lastName: null,
        email: null,
      },
      passwordForm: {
        current_password: '',
        password: '',
        password_confirmation: '',
      },
      errorMessages: {},
      userId: '',
      show1: false,
      show2: false,
      show3: false,
      // userData: [],
      color: "",
      rules: {
        required: value => !!value || 'Enter a password',
        min: v => v.length >= 8 || 'Use 8 characters or more for your password',
        strength: v => zxcvbn(v).score >= 3 || 'Please choose a stronger password. Try a mix of letters, numbers, and symbols.',
      },
    };
  },
  mounted() {
    this.getUserDetails()
  },
  computed: {
    score() {
      const result = zxcvbn(this.passwordForm.password);
      
      switch (result.score) {
        case 4:
          return {
            color: "light-blue",
            value: 100
          };
        case 3:
          return {
            color: "light-green",
            value: 75
          };
        case 2:
          return {
            color: "yellow",
            value: 50
          };
        case 1:
          return {
            color: "orange",
            value: 25
          };
        default:
          return {
            color: "red",
            value: 0
          };
      }
    }
  },
  methods: {
    getUserDetails() {
      // get user details from the token to set the env according to permissions
      if (localStorage.token) {
        // get token from localstorage
        let token = localStorage.getItem("token");
        let decoded = JSON.parse(atob(token.split('.')[1]));
        this.userId = decoded.id;
        // this.selfScopes = decoded.scopes;
        // let fullName = decoded.user.split(' ');
        // this.firstName = fullName[0];
        // this.lastName = fullName[fullName.length - 1];
        // this.params = decoded.params;
        // console.log(decoded);
        // console.log(this.selfScopes);
        // get user data with id
        this.getUserIdData();
      }
      else {
        console.log('token not found');
        // generate and error pop-up saying invalid session. Please login!
      }
    },
    getUserIdData: async function() {
      // this.userData = await api.getUserIdData(this.userId);
      let resp = await api.getUserIdData(this.userId);
      // console.log(resp);
      // console.log(resp[0].first_name);
      // console.log(resp[0].last_name);
      // console.log(resp[0].email);
      
      this.accountForm.firstName = resp[0].first_name;
      this.accountForm.lastName = resp[0].last_name;
      this.accountForm.email = resp[0].email;
      // console.log(this.accountForm);
    },
    
    async updateAccount() {
      this.accountUpdating = true;
      const request = {
        firstname: this.accountForm.firstName,
        lastname: this.accountForm.lastName,
        email: this.accountForm.email
      }
      await api.updateUser(this.userId, request);
      // console.log(request);
      // try {
      //   const response = await axios.put("/users/" + this.userId, request);
      //   // console.log(response);
      //   this.accountUpdating = false;
      //   return response.data;
      // } catch (error) {
      //   showSnackbar(error.response.statusText, 'warning');
      //   this.accountUpdating = false;
      // }
      this.accountUpdating = false;
    },
    
    async changePassword() {
      // validations on password
      // old ans new should be different
      // new and confirmed should be equal, otherwise generate an error message
      this.passwordChanging = true;
      await api.updateUserPassword(this.userId, this.passwordForm);
      this.passwordChanging = false;
      // try {
      //   const response = await axios.put("/users/password/" + this.userId, this.passwordForm);
      //   // console.log(response);
      //   this.passwordChanging = false;
      //   return response.data;
      // } catch (error) {
      //   showSnackbar(error.response.statusText, 'warning');
      //   this.passwordChanging = false;
      // }
      this.passwordForm = {
        current_password: null,
        password: null,
        password_confirmation: null,
      };
    },
    // updateUserPassword: async function() {
    //   await api.updateUser(this.userId, this.passwordForm);
    //   this.passwordChanging = false;
    //   this.passwordForm = {
    //     current_password: null,
    //     password: null,
    //     password_confirmation: null,
    //   };
    //   // At succes, pop a message saying the changes will be applied at next login [TBD]
    // },
  },
};
</script>
