<template>
  <!-- <v-container fluid fill-height id="login-page" class="grey lighten-3"> -->
    <!-- <h2>Register</h2> -->
    <v-layout align-center justify-center>
      <v-flex :style="{ 'max-width': '350px' }">
        <v-card elevation="17" class="mx-2 pa-2">
          <v-snackbar v-model="show" :color="color" bottom right timeout="5000" app transition="slide-y-transition">
            <!-- <v-icon small>
              mdi-alert
            </v-icon> -->
            {{response}}
            <template v-slot:action="{ attrs }">
              <v-icon
                v-bind="attrs"
                @click="show = false"
              >
                mdi-close
              </v-icon>
            </template>
            <!-- <v-btn small dark @click.native="show = false">
              close
            </v-btn> -->
          </v-snackbar>
          <!-- <v-img
            :src="require('@/assets/img/oscilas.svg')"
            contain
            height="70"
          /> -->
          <!-- class="my-7" -->
          <v-card-title>Create an account</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="register.first_name"
              label="First Name"
              required
            >
            </v-text-field>

            <v-text-field
              v-model="register.last_name"
              label="Last Name"
              required
            >
            </v-text-field>

            <v-text-field 
              v-model="register.email"
              label="Email"
              required
            >
            </v-text-field>

            <v-text-field
              v-model="register.password"
              :rules="[rules.required, rules.min, rules.strength]"
              validate-on-blur
              label="Password"
              type="password"
              required
            >
            </v-text-field>
            
            <!-- :background-opacity="opacity" -->
            <v-progress-linear
              :color="score.color"
              :value="score.value"
              rounded
            ></v-progress-linear>
            <!-- </div> -->

            <v-text-field
              v-model="passwordrepeat"
              label="Confirm Password"
              type="password"
              required
            >
            </v-text-field>

          </v-card-text>
          <div class="text-center">
            <v-hover v-slot="{ hover }">
              <v-btn
                :disabled="register_disabled"
                min-width="200"
                class="mb-5"
                success
                @click="registerIt"
                elevation="5"
                dark
                :color="hover ? '#00AB8E' : 'rgb(0, 108, 101, 0.75)'"
              >Register</v-btn>
            </v-hover>
            <!-- <v-btn class="mb-5" outlined color="teal" success @click="registerIt"
              >Submit</v-btn> -->
            <v-divider class="ma-5"></v-divider>

            <v-btn text class="mb-3" success small @click="goToLogin"
              >Already have an Account?</v-btn
            >
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  <!-- </v-container> -->
</template>

<script>
import axios from "axios";
import zxcvbn from "zxcvbn";

export default {
  data() {
    return {
      register: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
      },
      passwordrepeat: "",
      show: false,
      response: "",
      color: "",
      register_disabled: false,
      rules: {
        required: value => !!value || 'Enter a password',
        min: v => v.length >= 8 || 'Use 8 characters or more for your password',
        strength: v => zxcvbn(v).score >= 3 || 'Please choose a stronger password. Try a mix of letters, numbers, and symbols.',
      },
    };
  },
  computed: {    
    score() {
      const result = zxcvbn(this.register.password);
      
      switch (result.score) {
        case 4:
          return {
            color: "light-blue",
            value: 100
          };
        case 3:
          return {
            color: "light-green",
            value: 75
          };
        case 2:
          return {
            color: "yellow",
            value: 50
          };
        case 1:
          return {
            color: "orange",
            value: 25
          };
        default:
          return {
            color: "red",
            value: 0
          };
      }
    }
  },
  methods: {
    registerIt() {
      // check if all the fields are provided
      if (this.register.first_name && this.register.last_name && this.register.email && this.register.password && this.passwordrepeat) {
        if(this.register.password == this.passwordrepeat) { // check if the passwords match
          // axios.defaults.headers.common["Authorization"] = undefined;
          // console.log(axios.defaults.headers.common["Authorization"]);
          axios
            .post("https://www.oscilas.com/api/register", this.register)
            .then((response) => {
              this.response = response.data;
              this.color = "green";
              this.show = true;
              this.register_disabled = true;
              // this.$router.push("/");
            })
            .catch((error) => {
              this.response = error.response.statusText;
              this.color = "red";
              this.show = true;
            });
        }
        else {  // password mismatch
          this.response = "Password mismatch";
          this.color = "red";
          this.show = true;
        }
      }
      else {
        // form incomplete
        this.response = "All fields are required for registration!";
        this.color = "red";
        this.show = true;
      }
    },
    goToLogin() {
      this.$router.push("/");
    },
  },
};
</script>
