<template>

  <section id="about" class="mt-10">
  <!-- <div id="app"> -->
    <!-- <v-app id="inspire"> -->

<!-- Device management, data collection and visualization for your IoT solution -->
    <!-- <section > -->
      <div class="container mb-10">
        <div class="row align-center justify-center">
          <div class="text-center col col-10">
            <!-- <h1 class="display-1 font-weight-medium mt-10">
              About
            </h1> -->
            <h1 class="display-1 font-weight-regular mt-15">
              Scisensing is an IoT platform for data collection, visualization, device and user management.
            </h1>
            <h2 class="font-weight-regular mb-5 mt-5" style="color:gray">
              Scisensing is commited to raise the standards of research infrastructures by monitoring critical parameters, for improving experimental reproducibility.
            </h2>
             <!-- <p style="color:gray"> -->
            <h2>
              <v-row justify="center">
                <v-expansion-panels flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header style="color:gray"><v-spacer></v-spacer>Learn More</v-expansion-panel-header>
                    <v-expansion-panel-content style="color:gray" class="font-weight-regular mt-10 text-left">
                      Given the high variability and complexity of biomedical research, it is subject to high risk on reproducibility. 
                      Part of the mitigation of this risk can be achieved by the control of basic parameters on the provision of samples and reagents.
                      In research labs and infrastructures, the commercial monitoring environmental solutions have not evolved in the last two decades. 
                      The traditional mechanism of probe/sensor/receptor/data/alarm is not easy to scale and leads to high investment and running costs. 
                      In contrast, our proposition is to use LoRa networks as it is widely available for IoT, offers redundancy, and are not attached to brand-specific hardware.
                      Our mission is to make it affordable enough to have it in every single incubator, fridge, freezer, without the need of IT or any other infrastructure.
                      Our system enables smart datapoint storage to decrease the amount of data, enabling a lean but effective system, hitting the range of a few euros/sensor/year.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </h2>
              <!-- It enables device connectivity via LoRaWAN protocol. -->
            <!-- <h4 class="subtitle-1 mt-5"> -->
              <!-- Scisensing is an IoT platform for data collection, visualization, device and user management. 
              It enables device connectivity via LoRaWAN protocol. -->
              <!-- Given the high variability and complexity of research, it is subject to high risk on reproducibility.
              Despite the efforts on data management and statistical relevance, factors such as raw materials and 
              experimental environmental conditions are typically neglected. However, a big part of the mitigation 
              of this risk can be achieved by the control of basic parameters on the provision of samples and reagents. 
              This is why we developed methods for monitoring distributed research infrastructures, from scientific 
              facilities, to individual labs, or even for scientific experiments. -->
            <!-- </h4> -->
          </div>
        </div>
      </div>
    <!-- </section> -->

    <div class="row align-center justify-center">
      <div class="text-center col col-12">
        <h1 class="display-1 font-weight-medium mt-10">
          Effectively deployed in
        </h1>
        
        <!-- <p class="mt-5" style="color:gray">
          Scisensing has been effectively deployed in
        </p> -->
      
        <section class="mt-15">
          <div class="container">
            <div class="row align-center justify-center">
              <div class="col-md-5 col-12">
                <div class="container ma-3">
                  <p class="display-1 font-weight-regular mb-4">
                    Technical Facilities
                  </p>
                  <p style="color:gray">
                    Environmental temperature and CO<sub>2</sub> levels from incubation chambers, 
                    rooms with sensitive equipments such as high-end optical and electron microscopes, 
                    flow cytometers, sequencing, etc.
                  </p>
                </div>
              </div>
              <div class="text-center col-md-6 col-12">
                <div class="v-card v-sheet theme--light elevation-10">
                  <div class="v-image v-responsive theme--light">
                    <div class="v-responsive__sizer" style="padding-bottom: 56.2225%;">
                    </div>
                    <!-- <div class="v-image__image v-image__image--cover" style="background-image: url(&quot;https://res.cloudinary.com/musbell/image/upload/v1607979436/Screenshot_from_2020-11-03_10-26-29_ffndzr.png&quot;); background-position: center center;"> -->
                    <div class="v-image__image v-image__image--cover">
                      <v-img 
                        src="@/assets/img/microscopy.jpg" 
                        class="v-image__image v-image__image--cover"
                      ></v-img>
                    </div>
                    <div class="v-responsive__content" style="width: 1366px;">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style="background:#f8f9fa">
          <div style="background:#f8f9fa" class="container">
            <div class="row align-center justify-center">
              <div class="text-center col-md-6 col-12">
                <div class="mt-10 v-card v-sheet theme--light elevation-10">
                  <div class="v-image v-responsive theme--light">
                    <div class="v-responsive__sizer" style="padding-bottom: 56.2225%;">
                    </div>
                    <div class="v-image__image v-image__image--cover">
                      <v-img 
                        src="@/assets/img/vivaria.jpg" 
                        class="v-image__image v-image__image--cover"
                      ></v-img>
                    </div>
                    <div class="v-responsive__content" style="width: 1366px;">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-5 col-12">
                <div class="container">
                  <p class="display-1 font-weight-regular mb-4">
                    Vivaria Facility
                  </p>
                  <p style="color:gray">
                    The control of environmental conditions in lab animals, and also plant 
                    facilities is essential for running high quality experiments, ensuring 
                    data reproducibility, and animal welfare.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mt-10">
        <div class="container">
          <div class="row align-center justify-center">
            <div class="col-md-5 col-12">
              <div class="container ma-3">
                <p class="display-1 font-weight-regular mb-4">
                  Cold storage
                </p>
                <p style="color:gray">
                  Monitoring of cold storages (freezers, ultra-freezers and cryo-preservation), 
                  provides insights regarding equipment behaviour. As an example, one can easily setup 
                  a deep freezer to -72 °C, thus reducing research CO<sub>2</sub> footprint.
                </p>
              </div>
            </div>
            <div class="text-center col-md-6 col-12">
              <div class="v-card v-sheet theme--light elevation-10">
                <div class="v-image v-responsive theme--light">
                  <div class="v-responsive__sizer" style="padding-bottom: 56.2225%;">
                  </div>
                  <div class="v-image__image v-image__image--cover">
                    <v-img 
                      src="@/assets/img/cold.jpg"
                      class="v-image__image v-image__image--cover"
                    ></v-img>
                  </div>
                  <div class="v-responsive__content" style="width: 1366px;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
        <section style="background:#f8f9fa">
          <div style="background:#f8f9fa" class="container">
            <div class="row align-center justify-center">
              <div class="text-center col-md-6 col-12">
                <div class="mt-10 v-card v-sheet theme--light elevation-10">
                  <div class="v-image v-responsive theme--light">
                    <div class="v-responsive__sizer" style="padding-bottom: 56.2225%;">
                    </div>
                    <div class="v-image__image v-image__image--cover">
                      <v-img 
                        src="@/assets/img/bsclean.jpg" 
                        class="v-image__image v-image__image--cover"
                      ></v-img>
                    </div>
                    <div class="v-responsive__content" style="width: 1366px;">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-5 col-12">
                <div class="container">
                  <p class="display-1 font-weight-regular mb-4">
                    Biosafety and clean rooms
                  </p>
                  <p style="color:gray">
                    Multiparametric measurements of rooms with restricted access such as 
                    volatile compounds, dust, temperature, humidity, CO<sub>2</sub> , motion, luminosity, etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    show: false,
    items: [
      {
        // color: '#1F7087',
        src: '@/assets/img/oscilas.png',
        title: 'First thing',
        description: 'Brief description.',
      },
      {
        // color: '#952175',
        src: '@/assets/img/oscilas.png',
        title: 'Second thing',
        description: 'Brief description.',
      },
    ],
  }),
};
</script>
