<template>
  <v-card>
    <v-card-title>
      <!-- <span class="headline">Sensor Data</span> -->
    </v-card-title>

    <!-- <v-divider></v-divider> -->
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="6">
        <v-autocomplete
          solo          
          outlined
          dense
          clearable
          v-model="sensorsOfTypeSelected"
          :items="userSensors"
          :loading="isLoading"
          item-text="description"
          item-value="sensor"
          label="Sensor"
          prepend-inner-icon="mdi-memory"
        ></v-autocomplete>
        </v-col>
      </v-row>
      <!-- <v-row> -->
        <!-- <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateStart"
                label="From"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateStart"
              no-title
              scrollable
            ></v-date-picker>
          </v-menu>
        </v-col> -->
        <!-- <v-col
          cols="12"
          sm="6"
          md="3">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateEnd"
                label="To"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateEnd"
              no-title
              scrollable
            ></v-date-picker>
          </v-menu>
        </v-col> -->

      <v-row class="my-0">
        <!-- <v-col
          cols="12"
          sm="6"
          md="4"
        >
        <v-btn
          small
          @click="dateMinus"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        </v-col> -->
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                solo          
                outlined
                dense
                v-model="dateRangeText"
                label="Dates"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
              <!-- model: {{ dates }} -->
            </template>
            <v-date-picker
              color="primary"
              width="auto"
              v-model="dates"
              range
              no-title
              scrollable
            ></v-date-picker>
          </v-menu>
        </v-col>
        <!-- <v-col
          cols="12"
          sm="6"
          md="4"
        >
        <v-btn
          small
          @click="datePlus"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        </v-col> -->

      <!-- </v-row> -->
      <!-- <v-row> -->
        <v-col
          cols="12"
          sm="6"
          md="3">
          <v-card-actions>
            <v-btn
              color="primary"
              @click="requestData"
              :disabled="!sensorsOfTypeSelected"
              >Load</v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
      
    </v-container>
    <v-card-text class="mt-5">
      <v-row
        align="center"
        justify="center"
      >
        <!-- <v-col cols="12">
          <p class="text-center">
          </p>
        </v-col> -->
        <v-btn-toggle  v-if="loaded"
          v-model="toggle_exclusive"
          mandatory
          color="primary"
        >
          <!-- <v-btn small>
            Today
          </v-btn> -->
          <v-btn small>
            1d
          </v-btn>
          <v-btn small>
            1w
          </v-btn>
          <v-btn small>
            1m
          </v-btn>
          <v-btn small>
            3m
          </v-btn>
          <v-btn small>
            6m
          </v-btn>
        </v-btn-toggle>
      </v-row>
    </v-card-text>
    <!-- <v-list-item two-line> -->
      <!-- <v-list-item-content> -->
      <line-chart
        v-if="loaded"
        :chart-data="cdata"
        :chart-labels="labels"
        :chart-unit="unit"
        :chart-type="sensorType"
      ></line-chart>

      <v-snackbar v-model="show" :color="color" bottom right timeout="5000">
        {{snack_message}}
        <template v-slot:action="{ attrs }">
          <v-icon
            v-bind="attrs"
            @click="show = false"
          >
            mdi-close
          </v-icon>
        </template>
        <!-- <v-btn small dark @click.native="show = false">
          close
        </v-btn> -->
      </v-snackbar>
      <!-- </v-list-item-content> -->
    <!-- </v-list-item> -->
  </v-card>
</template>

<script>
// import axios from 'axios'
import LineChart from './LineChart'
import api from "@/services/apiService"
import dayjs from 'dayjs';

  export default {
    components: {
      LineChart
    },
    // props: {},
    data () {
      return {
        // package: null,
        // packageName: '',
        // period: 'last-month',
        sensorIndex: '3',
        sensorData: [],
        loaded: false,
        cdata: [],
        labels: [],
        showError: false,
        // errorMessage: 'Please enter sensor id'
        dateStart: new Date().toISOString().substr(0, 10),
        dateEnd: new Date().toISOString().substr(0, 10),
        menu1: false,
        // menu2: false,
        sensorsOfTypeSelected: '',
        isLoading: false,
        userSensors: [],
        toggle_exclusive: undefined,
        show: false,
        color: '',
        snack_message: '',
        sensorType: '',
        unit: '',
      }
    },
    
    watch: {
      toggle_exclusive(val) {
        this.plotGraph(val);
      },
    },

    computed: {
      dateRangeText () {
        return this.dates.join(' - ')
      },
      dates: {
        get: function () {
          return !this.dateEnd ? [this.dateStart] : (this.dateStart < this.dateEnd ? [this.dateStart, this.dateEnd] : [this.dateEnd, this.dateStart]);
        },
        set: function (val) {
          this.dateStart = val[0] || null
          this.dateEnd = val[1] || null
        },
      },
    },

    mounted () {
      this.getUserDetails();
      this.getUserSensors();
    },
    methods: {
      getUserDetails() {
         // get user details from the token to set the env according to permissions
        if (localStorage.token) {
          // get token from localstorage
          let token = localStorage.getItem("token");
          let decoded = JSON.parse(atob(token.split('.')[1]));
          this.userIndex = decoded.id;
          // console.log(this.userIndex);
        }
        else {
          console.log('token not found');
          // generate and error pop-up saying invalid session. Please login!
        }
      },
      getUserSensors: async function() {
        this.userSensors = await api.getUserSensors(this.userIndex);
        for (var i = 0; i < this.userSensors.length; i++) {
          this.userSensors[i].description = this.userSensors[i].description + ' [' + this.userSensors[i].type + ']';
        }

        // this.desc = this.userSensors.description + this.userSensors.type;
        // console.log("~ this.desc", this.desc);
        
        // this.userViewSwitch = Boolean(this.userSensors.view)
      },
      plotGraph(val) {
        // console.log(val);
        this.cdata = [];
        this.labels = [];
        this.dateEnd = dayjs().format('YYYY-MM-DD');
        this.dateStart = '';
        switch (val) {
        // case 0:
        //   this.dateStart = dayjs(this.dateEnd).format('YYYY-MM-DD');
        //   this.requestData();
        //   break;
        case 0:
          this.dateStart = dayjs(this.dateEnd).subtract(1, 'day').format('YYYY-MM-DD');
          break;
        
        case 1:
          this.dateStart = dayjs(this.dateEnd).subtract(7, 'day').format('YYYY-MM-DD');
          break;

        case 2:
          this.dateStart = dayjs(this.dateEnd).subtract(1, 'month').format('YYYY-MM-DD');
          break;
          
        case 3:
          this.dateStart = dayjs(this.dateEnd).subtract(3, 'month').format('YYYY-MM-DD');
          break;
        
        case 4:
          this.dateStart = dayjs(this.dateEnd).subtract(6, 'month').format('YYYY-MM-DD');
          break;

        default:
          break;
        }
        this.requestData();
      },
      requestData () {
        this.resetState();
        // this.getSensorIdData();
        this.getSensorDataRange();
      },
      resetState () {
        // this.loaded = false;
        this.showError = false;
      },
      // getSensorIdData: async function() {
      //   // this.sensorData = await api.getSensorIdData(this.sensorIndex);
      //   this.sensorData = await api.getSensorIdData(this.sensorsOfTypeSelected);
      //   // console.log(this.sensorData);
      //   // console.log(Object.keys(this.sensorData));
      //   this.cdata = this.sensorData.map(sdata => sdata.value);
      //   this.labels = this.sensorData.map(sdata => sdata.event_time);
      //   this.loaded = true;
      // },
      getSensorDataRange: async function() {
        var currentDate = new Date().toISOString().substr(0, 10);
        if ( !this.dateEnd ) {
          this.dateEnd = this.dateStart;
        }
        if ( (this.dateEnd > currentDate) || (this.dateStart > currentDate) ) {
          // alert('The dates can not be in future');
          this.snack_message = 'The dates can not be in future';
          this.color = 'red';
          this.show = true;
        }
        else {
          if (this.dateEnd < this.dateStart) {
            let dateTemp = this.dateEnd;
            this.dateEnd = this.dateStart;
            this.dateStart = dateTemp;
          }
          // this.sensorData = await api.getSensorDataRange(this.sensorIndex, this.dateStart, this.dateEnd);
          this.sensorData = await api.getSensorDataRange(this.sensorsOfTypeSelected, this.dateStart, this.dateEnd);
          // console.log(Object.keys(this.sensorData));
          this.cdata = this.sensorData.map(sdata => sdata.value);
          this.labels = this.sensorData.map(sdata => sdata.event_time);
          this.sensorType = this.userSensors.find(element => element.sensor === this.sensorsOfTypeSelected).type;
          this.unit = this.formatUnit(this.sensorType);
          this.loaded = true;
        }
      },

      formatUnit(typeToFormat) {
      var unit = '';
      switch (typeToFormat) {
        case 'battery' :
          unit = 'V';
          break;
        case 'temperature' :
          unit = '°C';
          break;
        case 'humidity' :
          unit = '%';
          break;
        case 'co2' :
          unit = 'ppm';
          break;
        case 'light' :
          unit = 'lux';
          break;
        case 'motion' :
          unit = 'm';
          break;
        case 'pressure' :
          unit = 'mbar';
          break;
        default:
          unit = 'unknown';
      }
      return unit;
    },
      // dateMinus () {

      // },
      // datePlus () {

      // },
      
    }
  }
</script>