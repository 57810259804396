<template>
  <!-- <v-container fluid fill-height id="login-page" class="grey lighten-3"> -->
    <!-- <h2>Register</h2> -->
    <v-layout align-center justify-center>
      <v-flex :style="{ 'max-width': '300px' }">
        <v-card elevation="17">
          <v-snackbar v-model="show" :color="color" top right timeout="2500" app transition="slide-y-transition">
            {{response}}
            <!-- <v-btn small dark @click.native="show = false">
              close
            </v-btn> -->
          </v-snackbar>
          <!-- <v-img
            :src="require('@/assets/img/oscilas.svg')"
            contain
            height="70"
          /> -->
          <!-- class="my-7" -->
          <v-card-title>Recover your password</v-card-title>
          <v-card-text>
            <v-text-field 
              class="mx-2"
              v-model="recover.email"
              label="Email"
              required
            >
            </v-text-field>

          </v-card-text>
          <div class="text-center">
            <v-hover v-slot="{ hover }">
              <v-btn
                min-width="200"
                class="mb-5"
                success
                @click="registerIt"
                elevation="5"
                dark
                :color="hover ? '#00AB8E' : 'rgb(0, 108, 101, 0.75)'"
              >Submit</v-btn>
            </v-hover>
            <!-- <v-btn class="mb-5" outlined color="teal" success @click="registerIt"
              >Submit</v-btn> -->
            <v-divider class="ma-5"></v-divider>

            <v-btn text class="mb-3" success small @click="goToLogin"
              >Remember your Password?</v-btn
            >
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  <!-- </v-container> -->
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      recover: {
        email: "",
        // origin: 1,
      },
      show: false,
      response: "",
      color: "",
    };
  },
  methods: {
    registerIt() {
      if (this.recover.email) {
        // axios.defaults.headers.common["Authorization"] = undefined;
        // console.log(axios.defaults.headers.common["Authorization"]);
        axios
          .post("https://www.oscilas.com/api/newpass", this.recover)
          .then((response) => {
            this.response = response.data;
            this.color = "green";
            this.show = true;
            this.$router.push("/");
          })
          .catch((error) => {
            this.response = error.response.statusText;
            this.color = "red";
            this.show = true;
          });
      }
      else {
        this.response = "Please enter a valid email!";
        this.color = "red";
        this.show = true;
  }
    },
    goToLogin() {
      this.$router.push("/");
    },
  },
};
</script>
