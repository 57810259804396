import Vue from 'vue';
import App from './App';
import router from './router';
import vuetify from './plugins/vuetify';

// import 'vuetify/dist/vuetify.min.css'
// import store from './store';
// import axios from './services/axios'

// import axios from './services/apiService'

// import axios from 'axios'
// import VueAxios from 'vue-axios'

Vue.config.productionTip = false;

// Vue.use(VueAxios, axios)
// axios.defaults.baseURL = "https://www.oscilas.com/api"
// axios.defaults.headers.common['Authorization'] = localStorage.token;

// axios.defaults.headers.common['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiTnVubyBzdHJpbmciLCJzY29wZXMiOiJbXCJzZW5zb3JzLnJlYWRcIiwgXCJzZW5zb3JzLndyaXRlXCIsIFwidXNlcnMucmVhZFwiLCBcInVzZXJzLndyaXRlXCIsIFwibG9jYXRpb25zLnJlYWRcIiwgXCJsb2NhdGlvbnMud3JpdGVcIiwgXCJzZW5zb3J0eXBlcy5yZWFkXCIsIFwic2Vuc29ydHlwZXMud3JpdGVcIiwgXCJkYXRhXCIsIFwiYWxhcm1zXCJdIiwiaWF0IjoxNjE1ODU0MzcyfQ.HvIozdEX1-AYBr2_tIGyTFumsmZniklDIXuqDC3Lb10';
// axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

new Vue({
  // store,
  router,
  // axios,
  vuetify,
  render: h => h(App)
}).$mount('#app');
