<!-- ./components/Snackbar.vue -->
<template>
  <v-snackbar 
    v-model="show"
    :color="color"
    center
    top
    app
    max-width="20px"
    :timeout="snackTimeout"
    transition="slide-y-transition"
  >
    {{snackbarMessage}}
    <template v-slot:action="{ attrs }">
      <v-icon
        v-bind="attrs"
        @click="show = false"
      >
        mdi-close
      </v-icon>
      <!-- <v-btn
        text
        v-bind="attrs"
        @click="show = false"
      >
        Close
      </v-btn> -->
    </template>
    <!-- <v-btn small dark @click.native="show = false">
      close
    </v-btn> -->
  </v-snackbar>
</template>

<script>
import EventBus, { ACTIONS } from "../eventBus/index";

  export default {
    data() {
      return {
        snackbarMessage: "",
        color:"",
        show: false,
        snackTimeout: 5000,
      };
    },

    mounted() {
      EventBus.$on(ACTIONS.SNACKBAR, (message, color) => {
        this.snackbarMessage = message;
        this.color = color;
        this.show = true;
      });
    },

    // created: function() {
    //   this.$store.watch(
    //     state => state.snackbar.snack,
    //     () => {
    //       const message = this.$store.state.snackbar.snack.message;
    //       if (message) {
    //         this.show = true;
    //         this.message = message;

    //         this.color = this.$store.state.snackbar.snack.color;
    //         this.$store.commit("snackbar/setSnack", {});
    //       }
    //     }
    //   );
    // }
  };
</script>
