<template>
<!-- <v-app> -->
  <!-- <v-container fluid fill-height id="login-page" class="grey lighten-3"> -->
    <!-- <div id="landing-page"></div> -->
    
    <v-layout align-center justify-center>
      <v-flex :style="{ 'max-width': '300px' }">
        <v-card elevation="17" color="rgb(0, 0, 0, .1)" dark> <!-- dark color="transparent" -->
          <!-- <v-img
            :src="require('@/assets/img/oscilas.svg')"
            contain
            height="70"
          /> -->

          <!-- <div>
            <v-alert v-if="error" type="error" :value="alert">
              {{ error }}
            </v-alert>
          </div> -->
          
          <v-snackbar v-model="show" :color="color" top center app timeout="5000" transition="slide-y-transition">
            {{snack_message}}
            <template v-slot:action="{ attrs }">
              <v-icon
                v-bind="attrs"
                @click="show = false"
              >
                mdi-close
              </v-icon>
            </template>
            <!-- <v-btn small dark @click.native="show = false">
              close
            </v-btn> -->
          </v-snackbar>

          <v-card-text>
            <!-- <form @keyup.enter="logIn"> -->
            <!-- @submit.prevent="logInDefault" -->
            <v-form
              ref="loginForm"
            >
              <v-text-field
                class="mx-2 mt-3"
                v-model="login.email"
                label="Email"
                required
                prepend-inner-icon="mdi-account"
                color="#00AB8E"
                placeholder=" "
                persistent-placeholder
                solo-inverted
                outlined
                dense
              >
              </v-text-field>
              <!-- <v-text-field
                class="mx-2 mt-3"
                v-model="login.email"
                label="Email"
                required
                prepend-inner-icon="mdi-account"
                color="#00AB8E"
                placeholder=" "
                persistent-placeholder
              >
              </v-text-field> -->
              <v-text-field
                class="mx-2"
                v-model="login.password"
                label="Password"
                required
                prepend-inner-icon="mdi-lock"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                color="#00AB8E"
                placeholder=" "
                persistent-placeholder
                solo-inverted
                dense
                outlined
              >
              <!-- color="teal" -->
              </v-text-field>
              <!-- <v-text-field
                dark
                class="mx-2"
                v-model="login.password"
                label="Password"
                required
                prepend-icon="mdi-lock"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                color="#00AB8E"
                placeholder=" "
                persistent-placeholder
                light
                dense
              >
              </v-text-field> -->
            </v-form>
          </v-card-text>
          <div class="text-center">
            <v-hover v-slot="{ hover }">
              <v-btn
                min-width="200"
                class="ma-2"
                success
                @click="logInDefault"
                elevation="5"
                :color="hover ? '#00AB8E' : 'rgb(0, 108, 101, 0.75)'"
              >Sign in</v-btn>
            </v-hover>
            <!-- color="#006C65" -->
            <v-spacer></v-spacer>
            or
            <v-spacer></v-spacer>

            <v-hover v-slot="{ hover }">
              <v-btn
                min-width="200"
                class="ma-2"
                success
                @click="logInAgendo"
                elevation="5"
                :color="hover ? '#00AB8E' : 'rgb(0, 108, 101, 0.75)'"
              >Sign in with Agendo</v-btn>
            </v-hover>
            <!-- :style="{ 'background-color': hover ? '#00AB8E' : '#006C65' }" -->

            <!-- <v-btn
              class="ma-2"
              color="#00AB8E"
              success
              @click="logInAgendo"
              outlined
              >Sign in with Agendo</v-btn
            > -->
            <v-divider class="ma-5"></v-divider>
          <div class="mx-2">
            <v-row>
              <v-col>
                <v-btn color="grey" text success small @click="goToRecover">
                  Forgot Password?
                </v-btn>
              </v-col>
              <v-col>
                <v-btn class="mr-2" color="grey" outlined text success small @click="goToRegister">
                  Sign up
                </v-btn>
              </v-col>
            </v-row>
          </div>
          </div>
          <!-- <div class="alert alert-danger" v-if="error">{{ error }}</div> -->
        </v-card>
      </v-flex>
    </v-layout>
  <!-- </v-container> -->
<!-- </v-app> -->
</template>

<script>
import axios from "axios";
import api from "@/services/apiService";

export default {
  data() {
    return {
      show1: false,
      password: 'Password',
      login: {
        email: "",
        password: "",
        tag: "igc",
        // origin: "0",
      },
      apiEnd: "",
      userData: [],
      // error: false,
      // valid: true,
      // emailRules: [
      //   v => !!v || 'E-mail is required',
      //   v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      // ],
      // test: false,
      show: false,
      color: '',
      snack_message: '',
    };
  },
  // created () {
  //   this.checkCurrentLogin()
  // },
  // updated () {
  //   this.checkCurrentLogin()
  // },
  methods: {
    // checkCurrentLogin  () {
    //   if (localStorage.token) {
    //     this.$router.replace(this.$route.query.redirect || '/home')
    //   }
    // },
    // validate () {
    //   this.$refs.loginForm.validate()
    // },
    
    logInDefault() {
      this.apiEnd = "login";
      this.logIn();
    },
    logInAgendo() {
      this.apiEnd = "agendologin";
      this.logIn();
    },
    // logIn() {
    //   axios
    //     .post(this.login.apiEnd, this.login)
    //     // .then(request => this.loginSuccessful(request))
    //     .then((response) => {

    //       const token = response.data;
    //       // console.log(token);

    //       // localStorage.setItem("token", token);
    //       localStorage.setItem("token", token);
    //       // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    //       axios.defaults.headers.common["Authorization"] = token;

    //       // Loading delay animate

    //       // load user configuration such as theme, home page, units, nav drawer type, menu type(hiden?)
    //       // check params
    //       // Set user configurations
    //       // method to select the theme
    //       this.$router.push("/home");
    //     })
    //     // .catch(() => this.loginFailed())
    //     .catch((error) => {
    //       // Display Error
    //       console.log("Error login");
    //       alert("Error logging in.")
    //       console.log(error);
    //       // this.loginFailed();
    //     });
    // },
    logIn() {
      if (!this.login.email || !this.login.password) {
        this.snack_message = 'Please provide a valid email and password'
        this.color = 'red';
        this.show = true;
      }
      else {
        this.userLogin(this.apiEnd, this.login);
        // axios.post('https://www.oscilas.com/api/'+this.apiEnd, this.login)
        // .then((response) => this.loginSuccessful(response))
        // .catch((error) => this.loginFailed(error))
      }
    },

    async userLogin(apiEnd, userCredentials) {
    try {
      const response = await axios.post("https://www.oscilas.com/api/" + apiEnd, userCredentials);
      if (!response.data) {
        this.snack_message = 'Response empty! Expected a token!';
        this.color = 'red';
        this.show = true;
        delete localStorage.token;
        return;
      }
      if(response.data.token) {
        localStorage.setItem("token", response.data.token);
        this.$router.replace(this.$route.query.redirect || '/home')
      }
      else {
        this.snack_message = response.data;
        this.color = 'orange';
        this.show = true;
      }
    } catch (error) {
      this.snack_message = error.response.statusText;
      this.color = 'red';
      this.show = true;
      console.error(error);
    }
  },

    // userLogin: async function() {
    //   await api.userLogin(this.apiEnd, this.login);
    // },

    // loginSuccessful (req) {
    //   if (!req.data) {
    //     this.loginFailed()
    //     return
    //   }
    //   if(req.data.token) {
    //     localStorage.setItem("token", req.data.token);
    //     this.$router.replace(this.$route.query.redirect || '/home')
    //   }
    //   else {
    //     this.snack_message = req.data;
    //     this.color = 'green';
    //     this.show = true;
    //   }

    // },
    // // In case of failure or when server responds with 200 but does not generate a token
    // loginFailed (error) {
    //   // this.error = 'Error Logging in!'
    //   this.snack_message = error.response.statusText;
    //   this.color = 'red';
    //   this.show = true;
    //   delete localStorage.token
    // },
    goToRecover() {
      this.$router.push("/recover");
    },
    goToRegister() {
      this.$router.push("/register");
    },
    getUserIdData: async function() {
      this.userData = await api.getUserIdData(this.userIndex);
      // [TBD] : Success or failure warning
    },
  },
};
</script>
