<template>
  <!-- <v-dialog v-model="dialogViewData" transition="slide-y-transition"> hide-overlay -->
  <!-- <template> -->
    <v-card>
      <v-card-title class="headline"
        >
        <v-icon disabled>{{ lastReading.symbol }}</v-icon>
        {{ lastReading.description }}
      </v-card-title>
      <!-- <v-card
        flat
        class="py-12"
      > -->
      <v-card-text>
        <v-row
          align="center"
          justify="center"
          class="mb-1"
        >
          <!-- <v-col cols="12">
            <p class="text-center">
            </p>
          </v-col> -->
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="grey"
            small
            @click="dateMinus"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            outlined
            color="grey"
            small
            @click="datePlus"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn-toggle
            mandatory
            color="primary"
            v-model="toggle_exclusive"
          >
            <!-- <v-btn small>
              Today
            </v-btn> -->
            <v-btn small @click="plotGraph(0)">
              1d
            </v-btn>
            <v-btn small @click="plotGraph(1)">
              1w
            </v-btn>
            <v-btn small @click="plotGraph(2)">
              1m
            </v-btn>
            <v-btn small @click="plotGraph(3)">
              3m
            </v-btn>
            <v-btn small @click="plotGraph(4)">
              6m
            </v-btn>
          </v-btn-toggle>
          
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                small
                @click="download"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>Export to Excel</span>
          </v-tooltip>
          
          <v-spacer></v-spacer>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey"
                small
                @click="getSensorsAnalytics"
                :loading="loading"
                :disabled="loading"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-poll</v-icon>
              </v-btn>
            </template>
            <span>Basic data analysis. Requires week mode to work.</span>
          </v-tooltip>
          
        </v-row>
        
        <v-row>
          <v-spacer></v-spacer>
          <p v-if="loadedAna" class="text-right">
            Height: {{analHeight}}, Delta: {{analDelta}}, Period: {{analPeriod}}, Score: {{analScore}}
          </p>

        </v-row>
      </v-card-text>
      <!-- <v-list-item two-line> -->
        <!-- <v-list-item-content> -->
          <line-chart class="pa-3"
            v-if="loaded"
            :chart-data="cdata"
            :chart-labels="labels"
            :chart-unit="unit"
            :chart-type="sensorType"
            :chart-thresholds="thresholds"
          ></line-chart>
          <!-- <canvas id="my_chart" class="pa-3"
            v-if="loaded"
            :chart-data="cdata"
            :chart-labels="labels"
            :chart-unit="unit"
            :chart-type="sensorType"
            :chart-thresholds="thresholds"
          ></canvas> -->
        <!-- </v-list-item-content> --> 
      <!-- </v-list-item> -->
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined text @click.stop="closeViewData">
          Close
        </v-btn>
      </v-card-actions> -->
    </v-card>
    <!-- <v-btn @click="$parent.closeViewData = false">Disagree</v-btn> -->
  <!-- </v-dialog> -->
</template>

<script>
import api from "@/services/apiService";
import dayjs from 'dayjs';
import LineChart from './LineChart';
import XLSX from 'xlsx';

export default {
  name: "Readings",
  components: {
    LineChart
  },
  props: {
    lastReading : {
      type: Object
    },
  },

  data: () => ({
    // lastReads: [],
    // dialogViewData: false,
    sensorData: [],
    loaded: false,
    cdata: [],
    labels: [],
    dateStart: '',
    dateEnd: '',
    toggle_exclusive: 0,
    sensorDescription: '',
    infoTag: '',
    sensorType: '',
    unit: '',
    symbol: '',
    cdataAna: [],
    labelsAna: [],
    loadedAna: false,
    analHeight: '',
    analPeriod: '',
    analScore: '',
    analDelta: '',
    alert: false,
    msg: '',
    loading: false,
    thresholds: {
      min: 0,
      max: 0
    },
  }),

  // watch: {
  //   dialogViewData(val) {
  //     val || this.closeViewData();
  //   },
  // },

  mounted() {
    this.viewItem();
  },

  methods: {
    plotGraph(val) {
      this.cdata = [];
      this.labels = [];
      this.dateEnd = dayjs().format('YYYY-MM-DD');
      this.dateStart = '';
      switch (val) {
      case 0:
        this.dateStart = dayjs(this.dateEnd).subtract(1, 'day').format('YYYY-MM-DD');
        break;
      
      case 1:
        this.dateStart = dayjs(this.dateEnd).subtract(7, 'day').format('YYYY-MM-DD');
        break;

      case 2:
        this.dateStart = dayjs(this.dateEnd).subtract(1, 'month').format('YYYY-MM-DD');
        break;
        
      case 3:
        this.dateStart = dayjs(this.dateEnd).subtract(3, 'month').format('YYYY-MM-DD');
        break;
      
      case 4:
        this.dateStart = dayjs(this.dateEnd).subtract(6, 'month').format('YYYY-MM-DD');
        break;

      default:
        this.dateStart = dayjs(this.dateEnd).subtract(1, 'day').format('YYYY-MM-DD');
        break;
      }
      this.requestData();
    },
    getColor (item) {
      if (item.value > item.max_val) return 'red'
      else if (item.value < item.min_val) return 'blue'
      else return 'green'
    },
    checkTime(timeToCheck) {
      if (dayjs().subtract(1, 'hour') > dayjs(timeToCheck)) {
        return true;
      }
      else {
        return false;
      }
    },
    formatDate(dateToFormat) {
      return dayjs(dateToFormat).format('YYYY-MM-DD HH:mm:ss');
    },
    
    formatUnit(typeToFormat) {
      var unit = '';
      switch (typeToFormat) {
        case 'battery' :
          unit = 'V';
          break;
        case 'temperature' :
          unit = '°C';
          break;
        case 'temperature_probe' :
          unit = '°C';
          break;
        case 'humidity' :
          unit = '%';
          break;
        case 'co2' :
          unit = 'ppm';
          break;
        case 'light' :
          unit = 'lux';
          break;
        case 'motion' :
          unit = 'm';
          break;
        case 'pressure' :
          unit = 'mbar';
          break;
        case 'distance' :
          unit = 'mm';
          break;
        case 'oxygen' :
          unit = '%';
          break;
        case 'meter' :
          unit = '';
          break;
        default:
          unit = 'unknown';
      }
      return unit;
    },

    // getSensorIdData: async function() {
    //   this.sensorData = await api.getSensorIdData(this.sensorIndex);
    // },

    viewItem: async function() {
      // this.sensorIndex = this.sensors.indexOf(item);
      // this.sensorIndex = Object.values(item)[0];
      // this.sensorIndex = this.sensors.indexOf(item) + 1;
      // this.getSensorIdData();
      this.sensorIndex = this.lastReading.sensor;
      this.sensorDescription = this.lastReading.description;
      this.sensorType = this.lastReading.type;
      this.plotGraph(0);
      // await this.requestData();
      // this.toggle_exclusive = 0; // this will execute the plotGraph through watch
      // this.getSensorDataRange();
      // this.dialogViewData = true;
    },
    closeViewData() {
      this.toggle_exclusive = 0,
      this.sensorIndex = 0;
      this.cdata = [];
      this.labels = [];
      this.loadedAna = false;
    },
    resetState () {
        this.loaded = false;
        // this.showError = false;
    },
    requestData () {
      // this.resetState();
      // this.getSensorIdData();
      this.getSensorDataRange();
    },
    getSensorDataRange: async function() {
      const temp = await api.getSensorDataRange(this.sensorIndex, this.dateStart, this.dateEnd);
      // console.log(Object.keys(this.sensorData));
      if( Array.isArray(temp) ) {
        this.sensorData = temp;
        this.cdata = this.sensorData.map(sdata => sdata.value);
        this.labels = this.sensorData.map(sdata => sdata.event_time);
        this.unit = this.formatUnit(this.sensorType);
        // this.symbol = this.lastReads.find(element => element.sensor === this.sensorIndex).symbol;
        // this.thresholds.min = this.lastReads.find(element => element.sensor === this.sensorIndex).min_val;
        // this.thresholds.max = this.lastReads.find(element => element.sensor === this.sensorIndex).max_val;
        // this.symbol = this.lastReading.symbol;
        this.thresholds.min = this.lastReading.min_val;
        this.thresholds.max = this.lastReading.max_val;
        this.loaded = true;
      }
    },
    // Get analytics data
    // {
    //   "height": "0.1679827",--
    //   "delta": "149",--
    //   "peak": "352",
    //   "freq": "0.0003464091",
    //   "period": "48.11268",--
    //   "miss_percentage": 3,
    //   "score": 1,--
    //   "mag": [
    //     "0.0003464091",
    //     "0.038980023",
    //     "0.029447408",
    //     "0.028664152",
    //   ]
    // }
    getSensorsAnalytics: async function() {
      this.loadedAna = false;
      this.loading = true;
      this.analHeight = '';
      this.analPeriod = '';
      this.analScore = '';
      this.analDelta = '';
      const sensorAnalytics = await api.getSensorsAnalytics(this.sensorIndex, this.dateStart, this.dateEnd);
      if (sensorAnalytics) {
        this.analHeight = sensorAnalytics.height;
        this.analPeriod = sensorAnalytics.period;
        this.analScore = sensorAnalytics.score;
        this.analDelta = sensorAnalytics.delta;
        this.loadedAna = true;
        // this.alert = true;
      }
      this.loading = false;

      // this.cdataAna = sensorAnalytics.map(sdata => sdata.value);
      // this.labelsAna = sensorAnalytics.map(sdata => sdata.event_time);
      // this.unitAna = this.formatUnit(this.sensorType);
      // this.symbolAna = this.lastReads.find(element => element.sensor === this.sensorIndex).symbol;
    },
    download () {
      // extract time and sensor value properties form the sensorData array of objects
      const gData = this.sensorData.map(
        ({event_time, value})=> { 
          return { event_time, value }
        });
        // console.log("~ gData", gData);

      // const Datas= {
      //     // can have multiple worksheets in the output file
      //     // first sheet
      //     sensor: this.sensorData,

      //     // second sheet
      //     // sensor1: this.sensorData1,
      //   };

      // export json to Worksheet of Excel
      // only array possible
      // var sensorWS = XLSX.utils.json_to_sheet(Datas.sensor)
      var sensorWS = XLSX.utils.json_to_sheet(gData);
      // var sensor1WS = XLSX.utils.json_to_sheet(Datas.sensor1) 

      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      // make sure the sheet name caharacters limit is respected
      XLSX.utils.book_append_sheet(wb, sensorWS, this.sensorType); // sheetAName is name of Worksheet
      // XLSX.utils.book_append_sheet(wb, sensor1WS, 'sensor1')

      // export Excel file
      // XLSX.writeFile(wb, 'sensorData.xlsx') // name of the file is 'book.xlsx'
      XLSX.writeFile(wb, this.sensorDescription + '.xlsx'); // this.sensorDescription.replace(/\s+/g, '')
    },
    dateMinus () {
      switch (this.toggle_exclusive) {
      case 0:
        this.dateStart = dayjs(this.dateStart).subtract(1, 'day').format('YYYY-MM-DD');
        this.dateEnd = dayjs(this.dateEnd).subtract(1, 'day').format('YYYY-MM-DD');
        break;
      
      case 1:
        this.dateStart = dayjs(this.dateStart).subtract(7, 'day').format('YYYY-MM-DD');
        this.dateEnd = dayjs(this.dateEnd).subtract(7, 'day').format('YYYY-MM-DD');
        break;

      case 2:
        this.dateStart = dayjs(this.dateStart).subtract(1, 'month').format('YYYY-MM-DD');
        this.dateEnd = dayjs(this.dateEnd).subtract(1, 'month').format('YYYY-MM-DD');
        break;
        
      case 3:
        this.dateStart = dayjs(this.dateStart).subtract(3, 'month').format('YYYY-MM-DD');
        this.dateEnd = dayjs(this.dateEnd).subtract(3, 'month').format('YYYY-MM-DD');
        break;
      
      case 4:
        this.dateStart = dayjs(this.dateStart).subtract(6, 'month').format('YYYY-MM-DD');
        this.dateEnd = dayjs(this.dateEnd).subtract(6, 'month').format('YYYY-MM-DD');
        break;

      default:
        this.dateStart = dayjs(this.dateStart).subtract(1, 'day').format('YYYY-MM-DD');
        this.dateEnd = dayjs(this.dateEnd).subtract(1, 'day').format('YYYY-MM-DD');
        break;
      }
      this.requestData();
    },
    datePlus () {
      var currentDate = new Date().toISOString().substr(0, 10);
      if (this.dateEnd <= currentDate) {
        switch (this.toggle_exclusive) {
        case 0:
          this.dateStart = dayjs(this.dateStart).add(1, 'day').format('YYYY-MM-DD');
          this.dateEnd = dayjs(this.dateEnd).add(1, 'day').format('YYYY-MM-DD');
          break;
        
        case 1:
          this.dateStart = dayjs(this.dateStart).add(7, 'day').format('YYYY-MM-DD');
          this.dateEnd = dayjs(this.dateEnd).add(7, 'day').format('YYYY-MM-DD');
          break;

        case 2:
          this.dateStart = dayjs(this.dateStart).add(1, 'month').format('YYYY-MM-DD');
          this.dateEnd = dayjs(this.dateEnd).add(1, 'month').format('YYYY-MM-DD');
          break;
          
        case 3:
          this.dateStart = dayjs(this.dateStart).add(3, 'month').format('YYYY-MM-DD');
          this.dateEnd = dayjs(this.dateEnd).add(3, 'month').format('YYYY-MM-DD');
          break;
        
        case 4:
          this.dateStart = dayjs(this.dateStart).add(6, 'month').format('YYYY-MM-DD');
          this.dateEnd = dayjs(this.dateEnd).add(6, 'month').format('YYYY-MM-DD');
          break;

        default:
          this.dateStart = dayjs(this.dateStart).add(1, 'day').format('YYYY-MM-DD');
          this.dateEnd = dayjs(this.dateEnd).add(1, 'day').format('YYYY-MM-DD');
          break;
        }
        this.requestData();
      }
      else {
        console.log("Error: The date can not be in future!");
      }
    },
  },
};
</script>
