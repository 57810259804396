import axios from "axios";
import { showSnackbar } from "../globalActions";

// import axios from './axios'
// import router from "@/router"

// yourConfig = {
//   headers: {
//      Authorization: "Bearer " + yourJWTToken
//   }
// }

const customRequest = axios.create({
  // baseURL: 'https://new.oscilas.com/api/v1',
  baseURL: 'https://www.oscilas.com/api',
  headers: {'Request-Origin': 'website'}
});

customRequest.interceptors.request.use(config => {
  const accessToken = localStorage.getItem("token");
  
  //checking if accessToken exists
  if(accessToken) {
    config.headers["Authorization"]=accessToken;
  }
  
  return config;
});

customRequest.interceptors.response.use(
  function(response) {
    if( response.status == 204) {
      showSnackbar(response.statusText, 'warning');
    }
    // showSnackbar(response.data, 'green');
    return response;
  }, 
  function(error) {
      // handle error
      if (error.response) {
        console.log(error.response);
        // if(error.response.status == 204) {
        //   showSnackbar('No data to show!', 'warning');
        //   alert("401");
        // log out the user in this case
        //   // this.viewItem();
        //   // this.$router.push({ name: "Logout" });
        // }
        // else {
          // alert(error.response.data);
          showSnackbar(error.response.statusText, 'red');
        // }
      }
  });

const apiClient = {

  // SENSORS //
  async getSensors() {
    try {
      const response = await customRequest.get("/sensors");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getSensorsCompact() {
    try {
      const response = await customRequest.get("/sensors/compact/list");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getSensorInfo(sensorId) {
    try {
      const response = await customRequest.post("/sensors/instructions", sensorId);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getSensorId(sensorId) {
    try {
      const response = await customRequest.get("/sensors/" + sensorId);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getSensorIdData(sensorId) {
    try {
      const response = await customRequest.get("/sensors/" + sensorId + "/data");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getSensorDataRange(sensorId, startTime, endTime) {
    try {
      const response = await customRequest.get("/sensors/" + sensorId + "/data/" + startTime + "/" + endTime);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getSensorsOfType(sensorType) {
    try {
      const response = await customRequest.get("/sensors/type/" + sensorType);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async addMultipleSensors(requestData) {
    try {
      const response = await customRequest.post("/sensors/addmultiple", requestData);
      if (response.status == 200) {
        showSnackbar("Sensor has been Added!", 'green');
      }
      return response.data;
    } catch (error) {
      console.error(error);
      showSnackbar(error, 'red');
    }
  },

  async updateSensor(sensorId, requestData) {
    try {
      const response = await customRequest.put("/sensors/" + sensorId, requestData);
      showSnackbar(response.statusText, 'green');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getLastReadings() {
    try {
      const response = await customRequest.get("/sensors/data/lastreadings");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getUserSensors(userId) {
    try {
      const response = await customRequest.get("/sensors/users/" + userId);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async addUserSensor(userId, requestData) {
    try {
      const response = await customRequest.post("/sensors/users/" + userId, requestData);
      showSnackbar("Updated!", 'green');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateUserSensors(userSensorId, requestData) {
    try {
      const response = await customRequest.put("/sensors/usersensor/" + userSensorId, requestData);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async delUserSensor(userSensorId, requestData) {
    try {
      const response = await customRequest.delete("/sensors/usersensor/" + userSensorId, requestData);
      showSnackbar("Updated!", 'green');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async delDevice(requestData) {
    try {
      const response = await customRequest.delete("/iot/" + requestData);
      return response;
    } catch (error) {
      console.error(error);
    }
  },

  // ttn related
  async getAppDataTTN() {
    try {
      const response = await customRequest.get("/sensors/ttnapp");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  // SENSOR TYPES //
  async getSensorTypes() {
    try {
      const response = await customRequest.get("/sensortypes");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  // USERS //
  async getUsers() {
    try {
      const response = await customRequest.get("/users");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getUserIdData(userId) {
    try {
      const response = await customRequest.get("/users/" + userId);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getUserDash(userId) {
    try {
      const response = await customRequest.get("/users/dash/" + userId);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateUserDash(userId, requestData) {
    try {
      const response = await customRequest.put("/users/dash/" + userId, requestData);
      if (response.data.message) {
        alert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async addUser(userRequest) {
    try {
      const response = await customRequest.post("/register", userRequest);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateUser(userId, requestData) {
    try {
      const response = await customRequest.put("/users/" + userId, requestData);
      if (response.status == 200) {
        showSnackbar(response.statusText, 'green');
      }
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateUserPassword(userId, requestData) {
    try {
      const response = await customRequest.put("/users/password/" + userId, requestData);
      if (response.status == 200) {
        showSnackbar(response.statusText, 'green');
      }
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateUserParams(userId, requestData) {
    try {
      const response = await customRequest.put("/users/parameters/" + userId, requestData);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateUserScopes(userId, requestData) {
    try {
      const response = await customRequest.put("/users/scopes/" + userId, requestData);
      if (response.data.message) {
        alert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async approveUser(userId) {
    try {
      const response = await customRequest.put("/approveuser/" + userId);
      if (response.data.message) {
        showSnackbar(response.data.message, 'green');
        // alert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async disableUser(userId) {
    try {
      const response = await customRequest.put("/disableuser/" + userId);
      if (response.data.message) {
        showSnackbar(response.data.message, 'green');
        // alert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSensorUsers(sensorId) {
    try {
      const response = await customRequest.get("/users/sensors/" + sensorId);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateManagers(groupId, requestData) {
    try {
      const response = await customRequest.put("/groups/owners/" + groupId, requestData);
      if (response.status == 200) {
        showSnackbar(response.statusText, 'green');
      }
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  // LOCATION //
  async getLocations() {
    try {
      const response = await customRequest.get("/locations");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async addLocation(requestData) {
    try {
      const response = await customRequest.post("/locations", requestData);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateLocation(locationId, requestData) {
    try {
      const response = await customRequest.put("/locations/" + locationId, requestData);
      if (response.data.message) {
        showSnackbar(response.data.message, 'green');
      }
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  // GROUPS //
  async getGroups() {
    try {
      const response = await customRequest.get("/groups");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getGroupUsers(userId) {
    try {
      const response = await customRequest.get("/groups/owners/" + userId);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async addGroup(requestData) {
    try {
      const response = await customRequest.post("/groups", requestData);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateGroups(groupId, requestData) {
    try {
      const response = await customRequest.put("/groups/" + groupId, requestData);
      if (response.data.message) {
        showSnackbar(response.data.message, 'green');
      }
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getUserGroups(userId) {
    try {
      const response = await customRequest.get("/groups/user/" + userId);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateUserGroups(userId, requestData) {
    try {
      const response = await customRequest.put("/groups/user/" + userId, requestData);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getOwnerGroups(userId) {
    try {
      const response = await customRequest.get("/users/groupsown/" + userId);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  // ALARMS  //
  async getAllAlarms() {
    try {
      const response = await customRequest.get("/alarms");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getDismissedAlarms(startTime, endTime) {
    try {
      const response = await customRequest.get("/alarms/dismissed/" + startTime + "/" + endTime);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  // async getAllAlarms(startTime, endTime) {
  //   try {
  //     const response = await customRequest.get("/alarms/" + startTime + "/" + endTime );
  //     return response.data;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },
  // async getAllAlarms() {
  //   try {
  //     const response = await customRequest.get("/alarms");
  //     return response.data;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },
  async getSensorAlarms(sensorId, type) {
    try {
      const response = await customRequest.get("/alarms/details/" + sensorId + "/" + type );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async dismissAlarm(sensorId, type) {
    try {
      const response = await customRequest.put("/alarms/dismiss/" + sensorId + "/" + type );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getOriginTag(originId) {
    try {
      const response = await customRequest.get("/origins/tag/" + originId);
      return response.data[0].tag;
    } catch (error) {
      console.error(error);
    }
  },

  async getOriginInfo(originId) {
    try {
      const response = await customRequest.get("/origins/" + originId);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getOriginData(originId) {
    try {
      const response = await customRequest.get("/origins/" + originId);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async contactus(requestData) {
    try {
      const response = await customRequest.post("/contactus", requestData);
      // console.log(response);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  // Register Sensor
  async createDevice(requestData) {
    try {
      const response = await customRequest.post("/iot", requestData);
      return response.status;
    } catch (error) {
      console.error(error);
    }
  },
  
  // NOTIFICATIONS
  async getPendingUserNotifications() {
    try {
      const response = await customRequest.get("/notifications/pendingusers");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getAlarmNotifications() {
    try {
      const response = await customRequest.get("/notifications/alarms");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSensorsNotifications() {
    try {
      const response = await customRequest.get("/notifications/sensors");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  // Analytics
  async getSensorsAnalytics(sensor, startTime, endTime) {
    try {
      const response = await customRequest.get("/anal/" + sensor + "/" + startTime + "/" + endTime);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  // ACCESS
    // async recoverPass(requestData) {
    //   try {
    //     const response = await customRequest.post("/newpass", requestData);
    //     showSnackbar(response.data, 'green');
    //     // console.log(response);
    //     return response.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    // },

  // async userLogin(apiEnd, userCredentials) {
  //   try {
  //     const response = await axios.post("/" + apiEnd, userCredentials);
  //     if (!response.data) {
  //       this.snack_message = 'Response empty! Expected a token!';
  //       this.color = 'red';
  //       this.show = true;
  //       delete localStorage.token;
  //       return;
  //     }
  //     if(response.data.token) {
  //       localStorage.setItem("token", response.data.token);
  //       this.$router.replace(this.$route.query.redirect || '/home')
  //     }
  //     else {
  //       this.snack_message = response.data;
  //       this.color = 'orange';
  //       this.show = true;
  //     }
  //     // return response.data;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },

};

// axios.interceptors.response.use(null, error => {
//   let path = '/error';
//   switch (error.response.status) {
//     case 401: path = '/login'; break;
//     case 404: path = '/404'; break;
//   }
//   // console.log(error.response.status);
//   router.push(path);
//   // alert(error.response.data.message);
//   return Promise.reject(error);
// });

export default apiClient;
