<template>
  <v-data-table
    dense
    :headers="headers_data"
    :items="userSensors"
    :search="searchUserSensor"
    class="elevation-1"
    :footer-props="{'items-per-page-options': [10 ,15, 20, -1]}"
  >
    <template v-slot:[`item.symbol`]="{ item }">
      <v-icon disabled>{{ item.symbol }}</v-icon>
    </template>

    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>My Sensors</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        
         <!-- Dialog for adding user sensors with a treeview for multiselect -->
        <v-dialog scrollable v-model="dialogAddSensor" max-width="700px" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="primary"
              outlined
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="getSensors">
              <!-- <v-icon small class="mr-1">mdi-plus</v-icon> -->
              Add/Remove
            </v-btn>
          </template>
          <v-card>
            <v-toolbar flat>
            <v-toolbar-title>Add/Remove Sensors</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <!-- <v-card-title>
              <span class="headline">Add Sensors</span>
            </v-card-title> -->
            <v-text-field
              class="mx-5"
              v-model="searchTree"
              append-icon="mdi-magnify"
              label="Search"
              hide-details
              clearable
            ></v-text-field>
            <!-- clear-icon="mdi-close-circle-outline" -->
            </v-toolbar>
            <v-card-text>
                <v-row>
                  <v-col>
                    <v-treeview
                      class="ml-5 mt-4"
                      v-model="sensorSelected"
                      selectable
                      selected-color="primary"
                      :items="sensorsList"
                      item-text="description"
                      item-value="sensor"
                      item-key="sensor"
                      :search="searchTree"
                      open-on-click
                      transition
                      dense
                      item-disabled="locked"
                    >
                    <!-- item-disabled="state" -->
                    <!-- :open.sync="open" -->
                      <template v-slot:prepend="{ item }">
                        <!-- <v-icon v-if="item.symbol"> -->
                        <v-icon>
                          {{ item.symbol }}
                        </v-icon>
                      </template>
                    </v-treeview>
                  </v-col>
                  <!-- <v-col class="pa-6" cols="6">
                    <template>
                      <div>
                        {{ sensorSelected }}
                      </div>
                    </template>
                  </v-col>                       -->
                </v-row>
              
            </v-card-text>
            <v-card-actions>
              <!-- :disabled="!sensorSelected.length" -->
              <v-btn
                :disabled="sensorSelected.length==currentUserSensors.length"
                color="primary" 
                outlined
                @click="addRemoveUserSensors"
                >Update
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                outlined
                @click="closeAddSensors"
              >Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-text-field
          class="mx-3"
          v-model="searchUserSensor"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-toolbar>
    </template>

    <!-- <template v-slot:[`item.action`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            class="mx-3"
            @click="delSensor(item)"
            color="red"
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-close-thick
          </v-icon>
        </template>
        <span>Disassociate</span>
      </v-tooltip>
    </template> -->

    <template v-slot:[`item.view`]="{ item }">
      <!-- <v-switch
        :input-value="userViewSwitch"
        @change="changeUserSensorConfig(item)"
      ></v-switch> -->
      <div class="row justify-center">
        <v-switch
          dense
          v-model="item.view"
          @change="changeUserSensorConfig(item)"
        ></v-switch>
      </div>
    </template>
    
    <template v-slot:[`item.alarm`]="{ item }">
      <!-- <v-switch
        :input-value="userViewSwitch"
        @change="changeUserSensorConfig(item)"
      ></v-switch> -->
      <div class="row justify-center">
        <v-switch
          dense
          v-model="item.alarm"
          @change="changeUserSensorConfig(item)"
        ></v-switch>
      </div>
    </template>
    
    <template v-slot:[`item.no_data_alarm`]="{ item }">
      <div class="row justify-center">
        <v-switch
          dense
          v-model="item.no_data_alarm"
          @change="changeUserSensorConfig(item)"
        ></v-switch>
      </div>
    </template>

    <template v-slot:[`item.location`]="{ item }">
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <div v-on="on">{{ item.location }}</div>
        </template>
        <span>{{ item.descloc }}</span>
      </v-tooltip>
    </template>

  </v-data-table>
  
  <!-- <template>
    <v-row>
      <v-col cols="10">
        <v-autocomplete
          class="ml-5 mt-4"
          v-model="sensorSelected"
          :items="sensorsList"
          item-text="description"
          item-value="id"
          label="Select a sensor to add"
          deletable-chips
          small-chips
          clearable
          multiple
        ></v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="mt-5" cols="2">
        <v-btn :disabled="!sensorSelected.length" color="blue darken-1" text outlined @click="addUserSensor">Add</v-btn>
      </v-col>
    </v-row>
  </template> -->
</template>

<script>
import api from "@/services/apiService";

  export default {
    name: "userSensors",
    data: () => ({
      searchUserSensor: '',
      searchTree: null,
      dialogAddSensor: false,
      userIndex: 0,
      headers_data: [
      // show list of sensors access to
      { value: "symbol" },
      { text: 'Sensor', value: 'description'},
      { text: 'Location', value: 'location', align: 'center'},
      { text: 'Alarms', value: 'view', sortable: false },
      { text: 'Threshold Notifications', value: 'alarm', align: 'center', sortable: false },
      { text: 'No-data Notifications', value: 'no_data_alarm', align: 'center', divider: true, sortable: false },
      // { text: 'Action', value: 'action',  align: 'center', sortable: false },
      ],
      status_switch: false,
      // chipProp: [
      //   { text : 'pending', colour : 'orange' },
      //   { text : 'active', colour : 'orange' },
      //   { text : 'disabled', colour : 'orange' },
      // ],
      userSensors: [],
      groupHeaders: [],
      groupSensors: [],
      autoGroup: [
        {
          groupHeaders: [],
        },
      ],
      userSensorIndex: 0,
      sensors: [],
      sensorSelected: [],
      sensorsList: [],
      userViewSwitch: 0,
      userAlarmSwitch: 0,
      userSensorsIndex: 0,
      locked: false,
      currentUserSensors: [],
    }),
    
    // computed: {
    //   // allSensorsSelect () {
    //   //   return this.sensorSelected.length === this.sensorsList.length
    //   // },
    //   // likesSomeFruit () {
    //   //   return this.sensorSelected.length > 0 && !this.allSensorsSelect
    //   // },
    //   // icon () {
    //   //   if (this.allSensorsSelect) return 'mdi-close-box'
    //   //   if (this.likesSomeFruit) return 'mdi-minus-box'
    //   //   return 'mdi-checkbox-blank-outline'
    //   // },
    //   // computeUserSensorsview(item) {
    //   //   return this.userViewSwith = Boolean)
    //   // }
    // },
    watch: {
      dialogAddSensor(val) {
        val || this.closeAddSensors();
      },
      // // whenever sensorSelected changes, this function will run
      // sensorSelected: function () {
      //   this.isLoading = true;
      //   this.getSensors();
      //   this.isLoading = false;
      // },
    },

    mounted(){
      this.getUserDetails();
      // call get users only when this user has permissions to read users
      this.getUserSensors();
      // this.getSensors();
    },

    methods: {
      getUserDetails() {
         // get user details from the token to set the env according to permissions
        if (localStorage.token) {
          // get token from localstorage
          let token = localStorage.getItem("token");
          let decoded = JSON.parse(atob(token.split('.')[1]));
          this.selfScopes = decoded.scopes;
          this.userIndex = decoded.id;
        }
        else {
          console.log('token not found');
          // generate and error pop-up saying invalid session. Please login!
        }
      },
 
      updateUserSensors: async function(userSensorId, requestBody) {
        await api.updateUserSensors(userSensorId, requestBody);
      },

      getUserSensors: async function() {
        const temp = await api.getUserSensors(this.userIndex);
        if( Array.isArray(temp) ) {
          this.userSensors = temp;
        }
        
        // this.userViewSwitch = Boolean(this.userSensors.view)
      },

      getSensors: async function() {
        this.sensors = [];
        this.sensorsList = [];
        this.currentUserSensors = [];
        // Get all the sensors available
        this.sensors = await api.getSensorsCompact();

        for( var j = 0; j < this.sensors.length; j++) {
          // if ( this.sensors[j].state === 0 || this.userSensors.some((obj) => obj.sensor === this.sensors[j].sensor)) {
          // if ( this.sensors[j].state === 0 ) {
          //   this.sensors[j].locked = true;
          // }
          // else 
          if ( this.userSensors) {
            if (this.userSensors.some((obj) => obj.sensor === this.sensors[j].sensor)) {
              // this.sensors[j].locked = true;
              this.sensorSelected.push(this.sensors[j].sensor); // update the model for seeing a checkbox in the already added sensors
            }
          }
          // else {
          //   this.sensors[j].locked = false;
          // }
        }
        this.currentUserSensors = this.sensorSelected;

        // Remove the existing user sensors from the list to display only the ones that are not added already
        // if(this.userSensors) {
        //   // if( this.userSensors.length > 0 ) {
        //     for( var i = 0; i < this.userSensors.length; i++){                    
        //       for( var j = 0; j < this.sensors.length; j++){
        //         if ( this.sensors[j].state == 0 || this.sensors[j].sensor == this.userSensors[i].sensor) {
        //           this.sensors[j].locked = true;
        //         }
        //         else {
        //           this.sensors[j].locked = false;
        //         }
        //         // if ( this.sensors[j].sensor == this.userSensors[i].sensor) { 
        //         //     this.sensors.splice(j, 1);
        //         //     j--;
        //         // }
        //       }
        //     }
        //   // }
        // }

        // // Sort the sensors array of objects based on the sensor_group
        // this.sensors.sort((a, b) => {
        //   if (a.sensor_group < b.sensor_group) {
        //     return -1;
        //   }
        //   if (a.sensor_group > b.sensor_group) {
        //     return 1;
        //   }
        //   return 0;
        // });
        // // Alternate short syntax for Sort:
        // // this.sensors.sort((a, b) => (a.sensor_group < b.sensor_group) ? 1 : (a.sensor_group > b.sensor_group) ? -1 : 0);

        // Group sensors based on the sensor_group, needed for group title and inserting headers in between
        let sensorsByGroups = this.groupBy(this.sensors, 'group_');

        let m = 0;
        Object.keys(sensorsByGroups).forEach(index => { 
          let str0 = Object.keys(sensorsByGroups)[m];
          let currentGroup = {  
            sensor: str0,
            description: str0,
            // !: false,
            children:
              sensorsByGroups[index]
          };
          this.sensorsList.push(currentGroup);
          m++;
        });

        // console.log('new object: ', str1);

        // console.log('object grouped: ', sensorsByGroups);
        // console.log('object entries: ', Object.entries(sensorsByGroups));
        // console.log('Object values: ', Object.values(sensorsByGroups));
        // console.log('object keys: ', Object.keys(sensorsByGroups));
        // this.sensorsList = Object.values(sensorsByGroups);
        
        // console.log('grouped: ', this.sensorsList);
        // // Insert the group header and dividers at the start of each group of sensors
        // let str1 = {};
        // let m = 0;
        // Object.keys(sensorsByGroups).forEach(index => { 
        //   // console.log(Object.keys(sensorsByGroups)[m]);
        //   console.log(sensorsByGroups[index]);
        //   str1 = {'header': 'Group: ' + Object.keys(sensorsByGroups)[m]};
        //   // console.log(str1);
        //   sensorsByGroups[index].unshift({"divider": true});
        //   sensorsByGroups[index].unshift(str1);
        //   // console.log(Object.keys(sensorsByGroups)[m]);
        //   // console.log(Object.values(sensorsByGroups));
        //   m++;
        // });
        // console.log(Object.values(sensorsByGroups));
        // // Array.prototype.push.apply(sensorsByGroups[1],sensorsByGroups[2]) // adds the arrays of objects from sensorsByGroups[2] to sensorsByGroups[1] 
        // this.sensorsList = Object.values(sensorsByGroups).flat();
        // // this.sensorsList[147].header = 'Group: 2';
        // console.log("sensorsList: ", this.sensorsList);
        // console.log("sensorsList: ", this.sensorsList[2][0].sensor_group);
        
        // console.log("sensorsList: ", Object.keys(this.sensorsList);
        // console.log(this.sensorsList.length);

        // // console.log(Object.keys(sensorsByGroups)[0]);
        // // console.log(Object.keys(sensorsByGroups)[1]);
        // // console.log(Object.keys(sensorsByGroups)[2]);

        // // console.log(Object.keys(sensorsByGroups)[0]);
        // // console.log(sensorsByGroups[1]);
        // // for (var k=0; k<Object.keys(sensorsByGroups).length; k++) {
        // //   str1['header'] = 'Group: ' + Object.keys(sensorsByGroups)[k];
        // //   sensorsByGroups[k+1].unshift({"divider": true});
        // //   sensorsByGroups[k+1].unshift(str1);
        // //   console.log('group: ', Object.keys(sensorsByGroups)[k]);
        // //   console.log(sensorsByGroups[k+1]);
        // // }
        // // console.log('group: ', Object.keys(sensorsByGroups)[1]);
        // // console.log(Object.keys(sensorsByGroups).length);
      },

      groupBy(OurArray, property) {
        return OurArray.reduce(function (accumulator, object) {
          // get the value of our object(sensor_group in our case) to use for grouping the array as the array key
          const key = object[property];
          // if the current value is similar to the key(sensor_group) don't accumulate the transformed array and leave it empty  
          if (!accumulator[key]) {
            accumulator[key] = [];
          }
          // add the value to the array
          accumulator[key].push(object);
          // return the transformed array
          return accumulator;
        // set the initial value of reduce() to an empty object
        }, {});
      },

      addRemoveUserSensors: async function() {
        // add sensors if any
        let newUserSensorstoAdd = this.sensorSelected.filter(x => !this.currentUserSensors.includes(x));
        if (newUserSensorstoAdd.length) {
          const requestBody = {
            sensor: '',
            view: 1, // this.userViewSwitch & 1;
            alarm: 1, // this.userViewSwitch | 0;
            no_data_alarm: 0,
          };
          for (let i=0; i< newUserSensorstoAdd.length; i++) {
            requestBody.sensor = newUserSensorstoAdd[i];         
            await api.addUserSensor(this.userIndex, requestBody);
          }
        }
        // Remove sensors if any
        let newUserSensorstoDel = this.currentUserSensors.filter(x => !this.sensorSelected.includes(x));
        if (newUserSensorstoDel.length) {
          const userRequest = {
            data: {
              user: this.userIndex,
            }
          };
          for (let i=0; i< newUserSensorstoDel.length; i++) {
            await api.delUserSensor(this.userSensors.find(element => element.sensor === newUserSensorstoDel[i]).sensor, userRequest);
          }
        }
        // refresh the list
        this.sensorSelected = [];
        this.getSensors();
        this.getUserSensors();
        // this.dialogAddSensor = true;
        this.closeAddSensors();
      },

      // delUserSensor: async function(userId) {
      //   const userRequest = {
      //     data: {
      //       user: userId,
      //     }
      //   };
      //   // console.log(this.userSensorIndex, userRequest);
      //   await api.delUserSensor(this.userSensorIndex, userRequest);
      //   this.userSensorIndex = 0;
      //   this.getUserSensors();
      // },

      // addUserSensor: async function() {
      //   const requestBody = {
      //     sensor: '',
      //     view: 1, // this.userViewSwitch & 1;
      //     alarm: 1, // this.userViewSwitch | 0;
      //   };
      //   let newUserSensors = this.sensorSelected.filter(x => !this.currentUserSensors.includes(x));
      //   if (newUserSensors.length) {
      //       for (var i=0; i< newUserSensors.length; i++){
      //       requestBody.sensor = newUserSensors[i];         
      //       await api.addUserSensor(this.userIndex, requestBody);
      //     }
      //     // // refresh the list
      //     this.sensorSelected = [];
      //     this.getUserSensors();
      //     this.getSensors();
      //     // this.dialogAddSensor = true;
      //     this.closeAddSensors();
      //   }
      // },

      closeAddSensors() {
        this.dialogAddSensor = false;
        this.sensorsList = [];
        this.searchTree = '';
      },

      // delSensor(item) {
      //   if (confirm('Are you sure you want to disassociate this sensor?')) {
      //     this.userSensorIndex = item.id;
      //     this.delUserSensor(item.user);
      //   }
      // },

      changeUserSensorConfig: async function(item) {
        const requestBody = {
          user: item.user,
          sensor: item.sensor,
          view: item.view ? 1 : 0, // this.userViewSwitch & 1;
          alarm: item.alarm ? 1 : 0, // this.userViewSwitch | 0;
          no_data_alarm: item.no_data_alarm ? 1 : 0,
        };
        await this.updateUserSensors(item.id, requestBody);
        // await api.updateUserSensors(item.id, requestBody);
        // refresh the list
        this.getUserSensors();
      },
      
      // saveUserApprove() {
      //   // start loading !
      //   // send request and wait for response
      //   this.approveUser();
      //   // v-alert upon finish
      //   // approveRequestStatus = true;
      // },
    },
  };
</script>