<template>
  <div class="text-md-center">
    <h2>You are Logged Out</h2>
  </div>
</template>

<script>
// export default {
//     mounted (
//         // Send a logout request and clear the local storage at success
//         // request
//         // localStorage.removeItem('token');
//     )
// }

import axios from "axios";

export default {
    mounted () {
        localStorage.removeItem("token");
        axios.defaults.headers.common['Authorization'] = undefined;
        // console.log(axios.defaults.headers.common['Authorization']);
        this.$router.push('/');
        this.$vuetify.theme.dark = false; // Reset theme at logout
    },
    // methods:{
    //     logItIn(){
    //         this.$router.push('/Login');
    //     }
    // }
}
</script>