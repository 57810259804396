<template>
  <v-data-table
    :headers="headers"
    :items="groups"
    :search="search"
    sort-by="id"
    multi-sort
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Groups</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        
        <v-dialog persistent v-model="dialog" max-width="500px" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              :disabled="selfScopes.includes('groups.write')==0"
              color="primary"
              outlined
              dark class="mb-2"
              v-bind="attrs" 
              v-on="on"
            >
              New Group
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <!-- <v-col cols="12" sm="6" md="4"> -->
                    <v-text-field
                      v-model="editedItem.description"
                      label="Description"
                      required
                    ></v-text-field>
                  <!-- </v-col> -->
                </v-row>
                <v-row>
                  <v-textarea
                    v-model="editedItem.instructions"
                    outlined
                    counter
                    label="Instructions"
                    :rules="rules"
                  ></v-textarea>
                </v-row>

                <!-- <v-row>
                  <tiptap-vuetify
                    v-model="content"
                    :extensions="extensions"
                  />
                </v-row> -->
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" outlined text @click="save">
                <v-icon small class="mr-1">mdi-content-save</v-icon>
                Save
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined text @click="close">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        
        <v-dialog v-model="dialogUsers" max-width="700px" transition="slide-y-transition">
          <v-card>
            <template>
              <v-data-table
                dense
                :headers="headers_group_owner_data"
                :items="groupOwners"
                :search="searchGroupUsers"
                class="elevation-1"
                :footer-props="{'items-per-page-options': [10 ,15, 20, -1]}"
              >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Group Managers</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-dialog scrollable v-model="dialogAddManager" max-width="600px" transition="slide-y-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        :disabled="selfScopes.includes('users.write')==0"
                        color="primary"
                        outlined
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="getUsers">
                        <!-- <v-icon small class="mr-1">mdi-plus</v-icon> -->
                        Add/Remove
                      </v-btn>
                    </template>
                    <v-card>
                      <v-toolbar flat>
                      <v-toolbar-title>Add/Remove Managers</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <!-- <v-spacer></v-spacer> -->

                      <!-- <v-card-title>
                        <span class="headline">Add Sensors</span>
                      </v-card-title> -->
                      <!-- <v-text-field
                        class="mx-5"
                        v-model="searchUser"
                        append-icon="mdi-magnify"
                        label="Search"
                        hide-details
                        clearable
                      ></v-text-field> -->
                      <!-- clear-icon="mdi-close-circle-outline" -->
                      </v-toolbar>
                      <!-- <v-card-title>Cafe Badilico</v-card-title> -->
                      <v-card-text class="mt-3">
                        <v-container>
                        <v-row dense>
                          <!-- <v-col cols="12" sm="8" md="8"> -->
                            <v-autocomplete
                              :disabled="!managersSelected"
                              chips
                              multiple
                              deletable-chips
                              small-chips
                              v-model="managersSelected"
                              :items="users"
                              :item-text="users => users.first_name +' '+ users.last_name +' ('+ users.email +')'"
                              item-value="id"
                              label="Select Users"
                              outlined
                            ></v-autocomplete>
                          <!-- </v-col> -->
                        </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <!-- :disabled="!managersSelected.length" -->
                        <v-btn
                          color="primary" 
                          text outlined
                          @click="addRemoveManagers"
                          >Update
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          outlined
                          @click="closeAddManagers"
                        >Cancel
                        </v-btn>
                        <!-- <v-btn
                          color="blue darken-1"
                          text
                          outlined
                          @click="saveAddSensor"
                        ><v-icon small class="mr-1">mdi-content-save</v-icon>
                          Save
                        </v-btn> -->
                      </v-card-actions>
                    </v-card>                  
                  </v-dialog>

                  <v-spacer></v-spacer>
                  <v-text-field
                    class="mx-3"
                    v-model="searchGroupUsers"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field> 
                </v-toolbar>
                <v-card-subtitle>Group: {{ currentGroup.description }}</v-card-subtitle>
              </template>
              </v-data-table>
            </template>
          </v-card>
          <!-- </v-container> -->
        </v-dialog>

        <v-spacer></v-spacer>
        <v-text-field
          class="mx-3"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        v-if="selfScopes.includes('users.write')"
        small
        class="mr-3"
        @click="editUsers(item)"
        color="primary"
        dark
      >
        mdi-account-supervisor
      </v-icon>
      <!-- :disabled="selfScopes.includes('groups.write')==0" -->
      <v-icon
        v-if="selfScopes.includes('groups.write')||selfScopes.includes('groups.manager')"
        small
        class="mx-3"
        @click="editItem(item)"
        color="primary"
        dark
      >
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import api from "@/services/apiService";
// import {
//   // component
//   TiptapVuetify,
//   // extensions
//   Heading,
//   Bold,
//   Italic,
//   Strike,
//   Underline,
//   Code,
//   CodeBlock,
//   Paragraph,
//   BulletList,
//   OrderedList,
//   ListItem,
//   Link,
//   Blockquote,
//   HardBreak,
//   HorizontalRule,
//   History
// } from "tiptap-vuetify";
export default {
  // components: { TiptapVuetify },
  name: "Groups",
  data: () => ({
//     extensions: [
//       new Heading({
//         levels: [1, 2, 3]
//       }),
//       new Bold(),
//       new Italic(),
//       new Strike(),
//       new Underline(),
//       new Code(),
//       new CodeBlock(),
//       new Paragraph(),
//       new BulletList(),
//       new OrderedList(),
//       new ListItem(),
//       new Link(),
//       new Blockquote(),
//       new HardBreak(), // Shift + Enter
//       new HorizontalRule(),
//       new History()
//     ],
//     content: `
// <h1>Most basic use</h1>
// <p>
//   You can use the necessary extensions. 
//   The corresponding buttons are 
//   <strong>
//     added automatically.
//   </strong>
// </p>
// <pre><code>&lt;tiptap-vuetify v-model="content" :extensions="extensions"/&gt;</code></pre>
// <p></p>
// <h2>Icons</h2>
// <p>Avaliable icons groups:</p>
// <ol>
//   <li>
//     <p>Material Design <em>Official</em></p>
//   </li>
//   <li>
//     <p>Font Awesome (FA)</p>
//   </li>
//   <li>
//     <p>Material Design Icons (MDI)</p>
//   </li>
// </ol>
// <p></p>
// <blockquote>
//   <p>This package is awesome!</p>
// </blockquote>
// <p></p>
//     `,
    search: '',
    dialog: false,
    dialogUsers: false,
    headers: [
      { text: "Description", value: "description" },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false },
    ],
    groups: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      description: '',
      instructions: '',
    },
    defaultItem: {
      id: 0,
      description: '',
      instructions: '',
    },
    selfScopes: [],
    groupUsers: [],
    searchGroupUsers: '',
    headers_group_owner_data: [
      // show list of sensors access to
      { text: 'User', value: 'full_name'},
      { text: 'Email', value: 'email'},
    ],
    groupOwners: [],
    dialogAddManager: false,
    // searchUser: null,
    managersSelected: [],
    users: [],
    currentGroup: [],
    rules: [v => v.length <= 500 || 'Max 500 characters'],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Group" : "Edit Group";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogUsers(val) {
      val || this.closeUsers();
    },
    // dialogAddManager(val) {
    //   val || this.closeAddManagers();
    // },
  },

  mounted() {
    this.getUserDetails();
    this.getGroups();
  },

  methods: {
    getUserDetails() {
      // get user details from the token to set the env according to permissions
      if (localStorage.token) {
        // get token from localstorage
        let token = localStorage.getItem("token");
        let decoded = JSON.parse(atob(token.split('.')[1]));
        this.selfScopes = decoded.scopes;
      }
      else {
        console.log('token not found');
        // generate and error pop-up saying invalid session. Please login!
      }
    },
    getGroups: async function() {
      const temp = await api.getGroups();
      if( Array.isArray(temp) ) {
        this.groups = temp;
      }
    },

    addGroup: async function() {
      const groupRequest = {
        description:  this.editedItem.description,
      };
      await api.addGroup(groupRequest);
      this.editedItem.id = 0;
      this.editedItem.description = "";
      this.getGroups();
    },

    updateGroups: async function() { 
      const requestData = {
        description:  this.editedItem.description,
        instructions: this.editedItem.instructions,
      };
      
      await api.updateGroups(this.editedItem.id, requestData);
      this.editedItem = Object.assign({}, this.defaultItem);
      this.getGroups(); // this can be avoided based on the request response success or error
    },

    editItem(item) {
      this.editedIndex = this.groups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      // call update api
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        this.updateGroups();
      } else {
        this.addGroup();
      }
      this.close();
    },

    editUsers: async function(item) {
      // this.currentGroup = item.id;
      this.currentGroup = item;
      await this.getGroupUsers(item.id);
    },

    getGroupUsers: async function(groupId) {
      this.groupUserIds = [];
      const temp = await api.getGroupUsers(groupId);
      if( Array.isArray(temp) ) {
        this.groupUserIds = temp;
      }
      this.getOwners();
    },
    getOwners: async function() {
      this.groupOwners = [];
      if (this.groupUserIds.length) {
        for (let i=0; i< this.groupUserIds.length; i++) {
          const user = await api.getUserIdData(this.groupUserIds[i]);
          let full_name = user[0].first_name + ' ' + user[0].last_name;
          const userNew = {'id':user[0].id, 'full_name': full_name, 'email': user[0].email};
          this.groupOwners = this.groupOwners.concat(userNew);
        }
      }
      this.dialogUsers = true;
    },

    closeUsers() {
      this.dialogUsers = false;
      this.groupOwners = [];
      this.groupUserIds = [];
      this.currentGroup = [];
      this.searchGroupUsers = '';
    },
    closeAddManagers() {
      this.dialogAddManager = false;
      this.managersSelected = [];
    },

    addRemoveManagers: async function() {
      const requestBody = {
        owners: this.managersSelected,
      };
      await api.updateManagers(this.currentGroup.id, requestBody);
      this.getGroupUsers(this.currentGroup.id);
      this.closeAddManagers();
    },
    getUsers: async function() {
      const temp = await api.getUsers();
      if( Array.isArray(temp) ) {
        this.users = temp.filter(element => element.status === 1);
      }
      this.updateManagersSelected();
    },
    updateManagersSelected: async function() {
      this.managersSelected = [];
      // Use map function of similar instead of for loop [TBD]
      for(let x in this.groupOwners) {
        this.managersSelected.push(this.groupOwners[x].id);
      }
    },
  },
};
</script>
