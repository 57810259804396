<template>
  <v-data-table
    :headers="headers"
    :items="users"
    :search="search"
    sort-by="status"
    sort-desc
    class="elevation-1">
    <!-- :items-per-page="5" -->
    
    <template #item.status="{ item }">
      <div v-if="item.status==1">
        <!-- <v-icon small color="green">mdi-record-circle</v-icon> -->
        <v-chip
          color="success"
          outlined
          small
        >Active
        </v-chip>
      </div>
      <div v-else-if="item.status==2">
        <v-chip
          color="info"
          outlined
          small
        >Pending Approval
        </v-chip>
      </div>
      <div v-else>
        <v-chip
          color="grey"
          outlined
          small
        >Disabled
        </v-chip>
      </div>
    </template>

    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Users</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        
        <v-dialog v-if="selfScopes.includes('users.write')" v-model="dialog" max-width="500px" transition="slide-y-transition">
          <!-- <v-tooltip bottom> -->
          <!-- <template v-if="$store.state.user.test" v-slot:activator="{ on, attrs }"> -->
            <!-- <template v-if="scopes.includes(selfScopes)" v-slot:activator="{ on, attrs }"> -->
          <template v-slot:activator="{ on, attrs }">
            <v-btn small color="primary" outlined dark class="mb-2" v-bind="attrs" v-on="on">
              <!-- <v-icon small class="mr-1">mdi-plus</v-icon> -->
              New User
            </v-btn>
          </template>
          <!-- <span>Tooltip</span> -->
        <!-- </v-tooltip> -->
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field dense
                      v-model="editedItem.first_name"
                      label="First Name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field dense
                      v-model="editedItem.last_name"
                      label="Last Name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field dense
                      v-model="editedItem.email"
                      label="Email"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="editedIndex===-1" cols="12">
                    <v-text-field dense
                      v-model="editedItem.pass"
                      label="Password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <template v-if="editedIndex!==-1">
                      <v-container fluid>
                        <h3 class="mb-5">Privileges</h3>
                        <!-- to live test the scopes array uncomment the following line -->
                        <!-- <p>{{ editedItem.scopes }}</p> -->
                        <!-- <div v-for="([label, value], i) in scopes_data" :key="i">
                          <v-checkbox
                            class="my-n4"
                            dense
                            v-model="editedItem.scopes"
                            label="label"
                            value="value"
                          ></v-checkbox>
                        </div> -->

                        <v-checkbox :disabled="scopesSensorWriteDisabled" class="my-n4" dense v-model="editedItem.scopes" label="Sensors [Read]" value="sensors.read"></v-checkbox>
                        <v-checkbox :disabled="scopesSensorCreateDisabled" @change="checkScopesSensorWrite('sensors.write')" class="my-n4" dense v-model="editedItem.scopes" label="Sensors [Write]" value="sensors.write"></v-checkbox>
                        <v-checkbox @change="checkScopesSensorCreate('sensors.create')" class="my-n4" dense v-model="editedItem.scopes" label="Sensors [Create]" value="sensors.create"></v-checkbox>
                        <v-checkbox class="my-n4" dense v-model="editedItem.scopes" label="Users [Read]" value="users.read"></v-checkbox>
                        <v-checkbox class="my-n4" dense v-model="editedItem.scopes" label="Users [Write]" value="users.write"></v-checkbox>
                        <v-checkbox :disabled="scopesSensorWriteDisabled" class="my-n4" dense v-model="editedItem.scopes" label="Groups [Read]" value="groups.read"></v-checkbox>
                        <v-checkbox class="my-n4" dense v-model="editedItem.scopes" label="Groups [Write]" value="groups.write"></v-checkbox>
                        <v-checkbox :disabled="scopesSensorWriteDisabled" class="my-n4" dense v-model="editedItem.scopes" label="Locations [Read]" value="locations.read"></v-checkbox>
                        <v-checkbox class="my-n4" dense v-model="editedItem.scopes" label="Locations [Write]" value="locations.write"></v-checkbox>
                        <v-checkbox :disabled="scopesSensorWriteDisabled" class="my-n4" dense v-model="editedItem.scopes" label="Sensor Types [Read]" value="sensortypes.read"></v-checkbox>
                        <v-checkbox class="my-n4" dense v-model="editedItem.scopes" label="Sensor Types [Write]" value="sensortypes.write"></v-checkbox>
                        <v-checkbox class="my-n4" dense v-model="editedItem.scopes" label="All Sensors Readings" value="data"></v-checkbox>
                        <v-checkbox class="my-n4" dense v-model="editedItem.scopes" label="All Sensors Alarms" value="alarms"></v-checkbox>
                      </v-container>
                    </template>
                  </v-col>

                  <v-col cols="12">
                    <v-switch v-if="editedIndex!==-1"
                      v-model="status_switch"
                      label="Active"
                    ></v-switch>
                  </v-col>
                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                text
                outlined
                @click="save"
              ><v-icon small class="mr-1">mdi-content-save</v-icon>
                Save
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                outlined
                @click="close"
              >Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogSensors" max-width="900px" transition="slide-y-transition">
          <v-card>
            <!-- <v-card-title class="headline"
              >User Sensors</v-card-title
            > -->
            <template>
              <v-data-table
                dense
                :headers="headers_data"
                :items="userSensors"
                :search="searchUserSensor"
                class="elevation-1"
              >
              <template v-slot:[`item.symbol`]="{ item }">
                <v-icon disabled>{{ item.symbol }}</v-icon>
              </template>
            
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>User Sensors</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>

                  <!-- Dialog for adding user sensors with a treeview for multiselect -->
                  <v-dialog scrollable v-model="dialogAddSensor" max-width="700px" transition="slide-y-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        :disabled="selfScopes.includes('users.write')==0"
                        color="primary"
                        outlined
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="getSensors">
                        <!-- <v-icon small class="mr-1">mdi-plus</v-icon> -->
                        Add/Remove
                      </v-btn>
                    </template>
                    <v-card>
                      <v-toolbar flat>
                      <v-toolbar-title>Add/Remove Sensors</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <!-- <v-card-title>
                        <span class="headline">Add Sensors</span>
                      </v-card-title> -->
                      <v-text-field
                        class="mx-5"
                        v-model="searchTree"
                        append-icon="mdi-magnify"
                        label="Search"
                        hide-details
                        clearable
                      ></v-text-field>
                      <!-- clear-icon="mdi-close-circle-outline" -->
                      </v-toolbar>
                      <v-card-text>
                        <!-- <v-container> -->
                          <v-row>
                            <v-col>
                              <v-treeview
                                class="ml-5 mt-4"
                                v-model="sensorSelected"
                                selectable
                                selected-color="primary"
                                :items="sensorsList"
                                item-text="description"
                                item-value="sensor"
                                item-key="sensor"
                                :search="searchTree"
                                open-on-click
                                transition
                                dense
                                item-disabled="locked"
                              >
                              <!-- :open.sync="open" -->
                                <template v-slot:prepend="{ item }">
                                  <!-- <v-icon v-if="item.symbol"> -->
                                  <v-icon>
                                    {{ item.symbol }}
                                  </v-icon>
                                </template>
                              </v-treeview>
                            </v-col>
                            <!-- <v-col class="pa-6" cols="6"> -->
                            <!-- <template> -->
                              <!-- <div v-for="node in sensorSelected" :key="node.id"> -->
                              <!-- <div> -->
                                <!-- {{ node.description }} -->
                                <!-- {{ sensorSelected }} -->
                              <!-- </div> -->
                            <!-- </template> -->
                            <!-- </v-col> -->
                            <!-- <v-spacer></v-spacer>
                            <v-col class="mt-5" cols="2">
                              <v-btn :disabled="!sensorSelected.length" color="blue darken-1" text outlined @click="addUserSensor">Add</v-btn>
                            </v-col>                         -->
                          </v-row>
                        <!-- </v-container> -->
                      </v-card-text>

                      <v-card-actions>
                        <v-btn
                          :disabled="sensorSelected.length==currentUserSensors.length"
                          color="primary" 
                          text outlined
                          @click="addRemoveUserSensors"
                          >Update
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          outlined
                          @click="closeAddSensors"
                        >Cancel
                        </v-btn>
                        <!-- <v-btn
                          color="blue darken-1"
                          text
                          outlined
                          @click="saveAddSensor"
                        ><v-icon small class="mr-1">mdi-content-save</v-icon>
                          Save
                        </v-btn> -->
                      </v-card-actions>
                    </v-card>                  
                  </v-dialog>
                  <v-spacer></v-spacer>
                  <v-text-field
                    class="mx-3"
                    v-model="searchUserSensor"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field>
                </v-toolbar>
              </template>             
              <!-- <template v-slot:[`item.action`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :disabled="selfScopes.includes('users.write')==0"
                      small
                      class="mx-3"
                      @click="delSensor(item)"
                      color="red"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-close-thick
                    </v-icon>
                  </template>
                  <span>Disassociate</span>
                </v-tooltip>
              </template> -->
              <template v-slot:[`item.view`]="{ item }">
                <!-- <v-switch
                  :input-value="userViewSwitch"
                  @change="changeUserSensorConfig(item)"
                ></v-switch> -->
                <div class="row justify-center">
                  <v-switch
                    :disabled="selfScopes.includes('users.write')==0"
                    dense
                    v-model="item.view"
                    @change="changeUserSensorConfig(item)"
                  ></v-switch>
                </div>
              </template>
               <template v-slot:[`item.alarm`]="{ item }">
                <!-- <v-switch
                  :input-value="userViewSwitch"
                  @change="changeUserSensorConfig(item)"
                ></v-switch> -->
                <div class="row justify-center">
                  <v-switch
                    :disabled="selfScopes.includes('users.write')==0"
                    dense
                    v-model="item.alarm"
                    @change="changeUserSensorConfig(item)"
                  ></v-switch>
                </div>
              </template>
              <template v-slot:[`item.no_data_alarm`]="{ item }">
                <div class="row justify-center">
                  <v-switch
                    dense
                    v-model="item.no_data_alarm"
                    @change="changeUserSensorConfig(item)"
                  ></v-switch>
                </div>
              </template>
              <template v-slot:[`item.location`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">{{ item.location }}</div>
                  </template>
                  <span>{{ item.descloc }}</span>
                </v-tooltip>
              </template>
              </v-data-table>
            </template>
            <!-- <v-card-actions>
              <v-btn color="blue darken-1" text @click="closeSensors">Close</v-btn>
              <v-btn color="blue darken-1" text @click="saveSensorConfig">Save</v-btn>
            </v-card-actions> -->
          </v-card>
        </v-dialog>

        <v-dialog scrollable v-model="dialogGroups" max-width="500px" transition="slide-y-transition">
          <v-card>
            <v-toolbar flat>
            <v-toolbar-title>User Groups</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <!-- <v-text-field
              class="mx-5"
              v-model="searchTreeGroups"
              append-icon="mdi-magnify"
              label="Search"
              hide-details
              clearable
            ></v-text-field> -->
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col>
                <v-list>
                  <v-list-item-group
                    v-model="groupsSelected"
                    multiple
                  >
                  <template v-for="item in thisOriginGroups">
                    <v-list-item
                      :key="item.id"
                      :value="item.id"
                    >

                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.description"></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            :disabled="selfScopes.includes('users.write')==0"
                          ></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                      
                    </template>
                  </v-list-item-group>
                </v-list>

                  <!-- <v-treeview
                    class="ml-5 mt-4"
                    v-model="groupsSelected"
                    selectable
                    :items="groupsList"
                    item-text="description"
                    item-value="description"
                    item-key="groups"
                    :search="searchTreeGroups"
                    open-on-click
                    transition
                    dense
                  >
                  </v-treeview> -->
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-container fluid>
                  {{groupsSelected}}
                </v-container>
              </v-row> -->
            </v-card-text>

            <v-card-actions>
              <v-btn
                :disabled="!groupsSelected.length || selfScopes.includes('users.write')==0"
                color="primary"
                text outlined
                @click="updateUserGroups"
                >Update
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                outlined
                @click="closeGroups"
              >Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-text-field
          class="mx-3"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-toolbar>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="selfScopes.includes('users.write')"
            small
            class="mr-3"
            @click="editItem(item)"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="(selfScopes.includes('users.write') || selfScopes.includes('users.read'))"
            small
            class="ml-3"
            @click="editSensors(item)"
            color="primary"
            dark
          >
            mdi-memory
          </v-icon>
          <v-icon
            v-if="(selfScopes.includes('groups.write') || selfScopes.includes('groups.read'))"
            small
            class="mx-3"
            @click="editGroups(item)"
            color="primary"
            dark
          >
            mdi-dots-grid
          </v-icon>
        </template>
        <span>Edit</span>
      </v-tooltip>
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-if="selfScopes.includes('sensors.read')"
            small
            class="mx-3"
            @click="viewItem(item)"
            color="purple"
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-eye
          </v-icon>
        </template>
        <span>Sensors</span>
      </v-tooltip> -->

      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-if="selfScopes.includes('users.write')"
            small
            class="ml-3"
            @click="viewItem(item)"
            color="orange"
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-lock
          </v-icon>
        </template>
        <span>Permissions</span>
      </v-tooltip> -->
    </template>
  </v-data-table>
</template>

<script>
import api from "@/services/apiService";

  export default {
    name: "Users",
    data: () => ({
      users: [],
      search: '',
      searchTree: null,
      // open:[1, 2],
      searchUserSensor: '',
      dialog: false,
      headers: [
        // { text: 'Id', value: 'id' },
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        // { text: 'Params', value: 'params' },
        // { text: 'Pass', value: 'pass' },
        { text: 'Email', value: 'email' },
        // { text: 'Sensors', value: 'sensors' },
        // { text: 'Scopes', value: 'scopes' },
        { text: 'Status', value: 'status', divider: true },  //checkbox-blank-circle
        { text: 'Operations', value: 'action',  align: 'center', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        id: 0,
        first_name: '',
        last_name: '',
        params: '',
        pass: '',
        email: '',
        sensors: '',
        scopes: [],
        status: '',
      },
      defaultItem: {
        id: 0,
        first_name: '',
        last_name: '',
        params: '',
        pass: '',
        email: '',
        sensors: '',
        scopes: [],
        status: '',
      },
      // dialogViewData: false,
      userIndex: 0,
      userData: [],
      headers_data: [
      // show list of sensors access to
      { value: "symbol" },
      { text: 'Sensor', value: 'description'},
      { text: 'Location', value: 'location', align: 'center'},
      { text: 'Alarms', value: 'view', sortable: false },
      { text: 'Notifications', value: 'alarm', align: 'center', sortable: false },
      { text: 'No-data Notifications', value: 'no_data_alarm', align: 'center', divider: true, sortable: false },
      // { text: 'Action', value: 'action',  align: 'center', sortable: false },
      ],
      // scopes_data: [
      // // show list of sensors access to
      // { label: 'Sensors [Read]', value: 'sensors.read'},
      // { label: 'Sensors [Write]', value: 'sensors.write'},
      // { label: 'Users [Read]', value: 'users.read'},
      // { label: 'Users [Write]', value: 'users.write'},
      // { label: 'Groups [Read]', value: 'groups.read'},
      // { label: 'Groups [Write]', value: 'groups.write'},
      // { label: 'Locations [Read]', value: 'locations.read'},
      // { label: 'Locations [Write]', value: 'locations.write'},
      // { label: 'Sensor Types [Read]', value: 'sensortypes.read'},
      // { label: 'Sensor Types [Write]', value: 'sensortypes.write'},
      // { label: 'All Sensors Readings', value: 'data'},
      // { label: 'All Sensors Alarms', value: 'alarms'},
      // ],
      status_switch: false,
      // chipProp: [
      //   { text : 'pending', colour : 'orange' },
      //   { text : 'active', colour : 'orange' },
      //   { text : 'disabled', colour : 'orange' },
      // ],
      // previ: [],
      selfScopes: [],
      dialogSensors: false,
      dialogAddSensor: false,
      userSensors: [],
      userSensorIndex: 0,
      sensors: [],
      sensorSelected: [],
      userViewSwitch: 0,
      userAlarmSwitch: 0,
      userSensorsIndex: 0,
      sensorsList: [],
      selected: [],
      disabledSensors: [],

      // USER Groups
      searchTreeGroups: '',
      dialogGroups: false,
      groupsList: [],
      groupsSelected: [],
      userGroups: [],
      // END USER Groups
      show1: false,
      password: 'Password',
      currentUserSensors: [],
      thisOriginGroups: [],
      scopesSensorWriteDisabled: false,
      scopesSensorCreateDisabled: false,
      scopesUserWriteDisabled: false,
      scopesGroupWriteDisabled: false,
      scopesLocationWriteDisabled: false,
      scopesSensorTypeWriteDisabled: false,
    }),
    
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? "New User" : "Edit User";
      },

      formGroupTitle() {
        return this.editedIndex === -1 ? "New Group" : "Edit Group";
      },
      // allSensorsSelect () {
      //   return this.sensorSelected.length === this.sensorsList.length
      // },
      // likesSomeFruit () {
      //   return this.sensorSelected.length > 0 && !this.allSensorsSelect
      // },
      // icon () {
      //   if (this.allSensorsSelect) return 'mdi-close-box'
      //   if (this.likesSomeFruit) return 'mdi-minus-box'
      //   return 'mdi-checkbox-blank-outline'
      // },
      // computeUserSensorsview(item) {
      //   return this.userViewSwith = Boolean)
      // }
    },

    watch: {
      dialog(val) {
        val || this.close();
      },
      // dialogViewData(val) {
      //   val || this.closeViewData();
      // },
      dialogSensors(val) {
        val || this.closeSensors();
      },
      dialogAddSensor(val) {
        val || this.closeAddSensors();
      },
      dialogGroups(val) {
        val || this.closeGroups();
      },

      // // whenever sensorSelected changes, this function will run
      // sensorSelected: function () {
      //   this.isLoading = true;
      //   this.getSensors();
      //   this.isLoading = false;
      // },
    },

    mounted(){
      this.getUserDetails();
      // call get users only when this user has permissions to read users
      this.getUsers();
      this.getGroups();
      this.getPendingUserNotifications(); // to sync the notifications in case changed by another user
    },

    methods: {
      getUserDetails() {
         // get user details from the token to set the env according to permissions
        if (localStorage.token) {
          // get token from localstorage
          let token = localStorage.getItem("token");
          let decoded = JSON.parse(atob(token.split('.')[1]));
          this.selfScopes = decoded.scopes;
        }
        else {
          console.log('token not found');
          // generate and error pop-up saying invalid session. Please login!
        }
      },
      getUsers: async function() {
        this.users = await api.getUsers();
      },

      addUser: async function() {
        const userRequest = {
          first_name: this.editedItem.first_name,
          last_name: this.editedItem.last_name,
          // parms:    this.editedItem.params,
          email:    this.editedItem.email,
          password: this.editedItem.pass,
          origin: 1,
        };
        await api.addUser(userRequest);
        this.editedItem = Object.assign({}, this.defaultItem);
        this.getUsers();
      },

      updateUser: async function() {
        const userRequest = {
          firstname: this.editedItem.first_name,
          lastname: this.editedItem.last_name,
          // parms:    this.editedItem.params,
          // password: this.editedItem.pass,
          email:    this.editedItem.email,
          
          // scopes:   this.editedItem.scopes,
          // status:   this.editedItem.status
        };
        await api.updateUser(this.editedItem.id, userRequest);
        this.editedItem = Object.assign({}, this.defaultItem);
        this.getUsers();
        this.getPendingUserNotifications();
      },
      updateUserParams: async function() {
        const userRequest = {
          parms: this.editedItem.params,
        };
        await api.updateUserParams(this.editedItem.id, userRequest);
      },
      updateUserScopes: async function() {
        const userRequest = {
          scopes: this.editedItem.scopes,
          // {"scopes":["sensors.read", "sensors.write"]}
        };
        await api.updateUserScopes(this.editedItem.id, userRequest);
      },

      updateUserSensors: async function(userSensorId, requestBody) {
        await api.updateUserSensors(userSensorId, requestBody);
      },

      // getUserIdData: async function() {
      //   this.userData = await api.getUserIdData(this.userIndex);
      // },
      getUserSensors: async function() {
        const temp = await api.getUserSensors(this.userIndex);
        if( Array.isArray(temp) ) {
          this.userSensors = temp;
        }
        // this.userViewSwitch = Boolean(this.userSensors.view)
      },

      getSensors: async function() {
        this.sensors = [];
        this.sensorsList = [];
        this.currentUserSensors = [];
        // Get all the sensors available
        this.sensors = await api.getSensorsCompact();

        for( var j = 0; j < this.sensors.length; j++) {
          // if ( this.sensors[j].state === 0 ) {
          //   this.sensors[j].locked = true;
          //   // this.disabledSensors.push(this.sensors[j].sensor);
          // }
          // else 
          if ( this.userSensors) {
            if (this.userSensors.some((obj) => obj.sensor === this.sensors[j].sensor)) {
              // this.sensors[j].locked = true;
              // this.sensors[j].splice(this.sensors[j].sensor, 1);
              this.sensorSelected.push(this.sensors[j].sensor); // update the model for seeing a checkbox in the already added sensors
            }
          }
          // else {
          //   this.sensors[j].locked = false;
          // }
        }
        this.currentUserSensors = this.sensorSelected;

        // Remove the existing user sensors from the list to display only the ones that are not added already
        // if(this.userSensors) {
        //   if( this.userSensors.length > 0 ) {
        //     for( var i = 0; i < this.userSensors.length; i++){                    
        //       for( var j = 0; j < this.sensors.length; j++){      
        //         if ( this.sensors[j].sensor == this.userSensors[i].sensor) {
        //             this.sensors.splice(j, 1);
        //             // this.sensors[j]['locked'] = 'true';
        //             j--;
        //         }
        //       }
        //     }
        //   }
        // }

        // console.log("All but already added Sensors:", this.sensors);

        // // Sort the sensors array of objects based on the sensor_group
        // this.sensors.sort((a, b) => {
        //   if (a.sensor_group < b.sensor_group) {
        //     return -1;
        //   }
        //   if (a.sensor_group > b.sensor_group) {
        //     return 1;
        //   }
        //   return 0;
        // });
        // // Alternate short syntax for Sort:
        // // this.sensors.sort((a, b) => (a.sensor_group < b.sensor_group) ? 1 : (a.sensor_group > b.sensor_group) ? -1 : 0);
        
        // console.log('allSensors: ', this.sensors);
        // console.log('sensopr: ', this.sensors.sensor_group);

        // Group sensors based on the sensor_group, needed for group title and inserting headers in between
        let sensorsByGroups = this.groupBy(this.sensors, 'group_');
        // let str1 = {};
        let m = 0;
        Object.keys(sensorsByGroups).forEach(index => { 
          // console.log(Object.keys(sensorsByGroups)[m]);
          // console.log("sensorsByGroups[index]", sensorsByGroups[index]);
          let str0 = Object.keys(sensorsByGroups)[m];
          let currentGroup = {  
            sensor: str0,
            description: str0,
            children:
              sensorsByGroups[index]
              // Object.values(sensorsByGroups)[m],
          };
          this.sensorsList.push(currentGroup);
          // console.log("~ this.sensorsList", this.sensorsList);
          // console.log(str1);
          m++;
        });
        // console.log('new object: ', str1);

        // console.log('object grouped: ', sensorsByGroups);
        // console.log('object entries: ', Object.entries(sensorsByGroups));
        // console.log('Object values: ', Object.values(sensorsByGroups));
        // console.log('object keys: ', Object.keys(sensorsByGroups));
        // this.sensorsList = Object.values(sensorsByGroups);
        
        // console.log('grouped: ', this.sensorsList);
        // // Insert the group header and dividers at the start of each group of sensors
        // let str1 = {};
        // let m = 0;
        // Object.keys(sensorsByGroups).forEach(index => { 
        //   // console.log(Object.keys(sensorsByGroups)[m]);
        //   console.log(sensorsByGroups[index]);
        //   str1 = {'header': 'Group: ' + Object.keys(sensorsByGroups)[m]};
        //   // console.log(str1);
        //   sensorsByGroups[index].unshift({"divider": true});
        //   sensorsByGroups[index].unshift(str1);
        //   // console.log(Object.keys(sensorsByGroups)[m]);
        //   // console.log(Object.values(sensorsByGroups));
        //   m++;
        // });
        // console.log(Object.values(sensorsByGroups));
        // // Array.prototype.push.apply(sensorsByGroups[1],sensorsByGroups[2]) // adds the arrays of objects from sensorsByGroups[2] to sensorsByGroups[1] 
        // this.sensorsList = Object.values(sensorsByGroups).flat();
        // // this.sensorsList[147].header = 'Group: 2';
        // console.log("sensorsList: ", this.sensorsList);
        // console.log("sensorsList: ", this.sensorsList[2][0].sensor_group);
        
        // console.log("sensorsList: ", Object.keys(this.sensorsList);
        // console.log(this.sensorsList.length);

        // // console.log(Object.keys(sensorsByGroups)[0]);
        // // console.log(Object.keys(sensorsByGroups)[1]);
        // // console.log(Object.keys(sensorsByGroups)[2]);

        // // console.log(Object.keys(sensorsByGroups)[0]);
        // // console.log(sensorsByGroups[1]);
        // // for (var k=0; k<Object.keys(sensorsByGroups).length; k++) {
        // //   str1['header'] = 'Group: ' + Object.keys(sensorsByGroups)[k];
        // //   sensorsByGroups[k+1].unshift({"divider": true});
        // //   sensorsByGroups[k+1].unshift(str1);
        // //   console.log('group: ', Object.keys(sensorsByGroups)[k]);
        // //   console.log(sensorsByGroups[k+1]);
        // // }
        // // console.log('group: ', Object.keys(sensorsByGroups)[1]);
        // // console.log(Object.keys(sensorsByGroups).length);
      },

      approveUser: async function() {
        await api.approveUser(this.editedItem.id);
        // let resp = await api.approveUser(this.userIndex);
        // confirm at success
      },
      disableUser: async function() {
        await api.disableUser(this.editedItem.id);
        // let resp = await api.disableUser(this.userIndex);
        // confirm at success
      },

      // delUserSensor: async function(userId) {
      //   const userRequest = {
      //     data: {
      //       user: userId,
      //     }
      //   };
      //   // console.log(this.userSensorIndex, userRequest);
      //   await api.delUserSensor(this.userSensorIndex, userRequest);
      //   this.userSensorIndex = 0;
      //   this.getUserSensors();
      //   // this.getSensors();
      // },

      editItem(item) {
        this.editedIndex = this.users.indexOf(item);
        this.editedItem = Object.assign({}, item);

        if(this.editedItem.scopes.includes('sensors.create')) {
          this.scopesSensorCreateDisabled = true;
          this.scopesSensorWriteDisabled = true;
        }
        else if(this.editedItem.scopes.includes('sensors.write')) {
          this.scopesSensorWriteDisabled = true;
        }
        
        if (this.editedItem.status == 1) {
          this.status_switch = true;
        }
        else {
          this.status_switch = false;
        }
        this.dialog = true;
      },
      
      // viewItem(item) {
      //   this.userIndex = Object.values(item)[0];
      //   // this.sensorIndex = this.sensors.indexOf(item) + 1;
      //   this.getUserIdData();
      //   this.dialogViewData = true;
      // },

      editSensors: async function(item) {
        this.userIndex = Object.values(item)[0];
        this.userSensors = [];
        await this.getUserSensors();

        // get the list of sensors available to add
        // this.getSensors();
        // this.formSelectList();
        this.dialogSensors = true;
      },

      addRemoveUserSensors: async function() {
        // add sensors if any
        let newUserSensorstoAdd = this.sensorSelected.filter(x => !this.currentUserSensors.includes(x));
        if (newUserSensorstoAdd.length) {
          const requestBody = {
            sensor: '',
            view: 1, // this.userViewSwitch & 1;
            alarm: 1, // this.userViewSwitch | 0;
            no_data_alarm: 0,
          };
          for (let i=0; i< newUserSensorstoAdd.length; i++) {
            requestBody.sensor = newUserSensorstoAdd[i];
            await api.addUserSensor(this.userIndex, requestBody);
          }
        }
        // Remove sensors if any
        let newUserSensorstoDel = this.currentUserSensors.filter(x => !this.sensorSelected.includes(x));
        if (newUserSensorstoDel.length) {
          const userRequest = {
            data: {
              user: this.userIndex,
            }
          };
          for (let i=0; i< newUserSensorstoDel.length; i++) {
            await api.delUserSensor(this.userSensors.find(element => element.sensor === newUserSensorstoDel[i]).sensor, userRequest);
          }
        }
        // refresh the list
        this.sensorSelected = [];
        this.getSensors();
        this.getUserSensors();
        // this.dialogAddSensor = true;
        this.closeAddSensors();
      },

      // addUserSensor: async function() {
      //   const requestBody = {
      //     sensor: '',
      //     view: 1, // this.userViewSwitch & 1;
      //     alarm: 1, // this.userViewSwitch | 0;
      //   };
      //   let newUserSensors = this.sensorSelected.filter(x => !this.currentUserSensors.includes(x));
      //   for (var i=0; i< newUserSensors.length; i++){
      //     requestBody.sensor = newUserSensors[i];
      //     await api.addUserSensor(this.userIndex, requestBody);
      //   }
      //   // // refresh the list
      //   this.sensorSelected = [];
      //   this.getUserSensors();
      //   this.getSensors();
      //   this.closeAddSensors();
      //   // this.dialogAddSensor = true;
      // },
      
      // delSensor(item) {
      //   if (confirm('Are you sure you want to disassociate this sensor?')) {
      //     // console.log(item);
      //     // this.userSensorIndex = Object.values(item)[0];
      //     this.userSensorIndex = item.id;
      //     // console.log(this.userSensorIndex, item.user);
      //     this.delUserSensor(item.user);
      //     // refresh the list
      //   }
      // },

      changeUserSensorConfig: async function(item) {
        // this.userSensorsIndex = Object.values(item)[0];
        const requestBody = {
          user: item.user,
          sensor: item.sensor,
          view: item.view ? 1 : 0, // this.userViewSwitch & 1;
          alarm: item.alarm ? 1 : 0, // this.userViewSwitch | 0;
          no_data_alarm: item.no_data_alarm ? 1 : 0,
        };
        // console.log(item.id, requestBody);
        await this.updateUserSensors(item.id, requestBody);
        // await api.updateUserSensors(item.id, requestBody);
        // refresh the list
        this.getUserSensors();
      },

      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
          this.status_switch = false;
          this.scopesSensorCreateDisabled = false;
          this.scopesSensorWriteDisabled = false;
        });
      },

      // closeViewData() {
      //   this.dialogViewData = false;
      //   this.userIndex = 0;
      // },
      
      closeSensors() {
        this.dialogSensors = false;
        this.userIndex = 0;
        this.sensorSelected = [];
        this.searchUserSensor = '';
      },
      closeAddSensors() {
        this.dialogAddSensor = false;
        this.sensorsList = [];
        this.searchTree = '';
      },
      save() {
        // validate all the fields
        // Register user upon all correct entries
        
        if (this.editedIndex > -1) {
          if (this.status_switch == true && this.editedItem.status != 1) {
            this.editedItem.status = 1;
            this.approveUser();
            // get confirmation before 
            // update only if changed
            // this.disableUser();
          }
          else if (this.status_switch == false && this.editedItem.status == 1) {
            this.editedItem.status = 0;
            this.disableUser();
            // console.log("disabling user");
          }
          // this.updateUserParams();
          this.updateUser();
          this.updateUserScopes();
          // this.getUsers();
          // this.getPendingUserNotifications();
          // Object.assign(this.users[this.editedIndex], this.editedItem); // 
        } else { // [TBD]: handle the case when the tables are empty
          // this.sensors.push(this.editedItem);
          this.addUser();
        }

        this.close();
      },
      getPendingUserNotifications: async function() {
        const n = await api.getPendingUserNotifications();
        // const n = { field: 'users', value: resp.find(element => element.n).n };
        const nt = { field: 'users', value: n };
        this.$emit('changeNotifications', nt);
      },
      saveSensorConfig() {
        // this.updateUserSensors();
        this.close();
      },

      groupBy(OurArray, property) {
        return OurArray.reduce(function (accumulator, object) {
          // get the value of our object(sensor_group in our case) to use for grouping the array as the array key
          const key = object[property];
          // if the current value is similar to the key(sensor_group) don't accumulate the transformed array and leave it empty  
          if (!accumulator[key]) {
            accumulator[key] = [];
          }
          // add the value to the array
          accumulator[key].push(object);
          // return the transformed array
          return accumulator;
        // set the initial value of reduce() to an empty object
        }, {});
      },
      remove (item) {
        const index = this.sensors.indexOf(item.description)
        if (index >= 0) this.sensors.splice(index, 1)
      },

      // Groups
      getGroups: async function() {
        this.groupsList = await api.getGroups();
      },

      editGroups: async function(item) {
        this.userIndex = Object.values(item)[0];
        // find the user origin from the users list
        const thisUserOrigin = this.users.find((user) => user.id == this.userIndex).origin;
        // make a list of groups associated with this origin
        this.thisOriginGroups = this.groupsList.filter((group) => group.origin === thisUserOrigin);

        // const thisOriginGroups = this.groupsList.find((group) => group.origin == thisUserOrigin);
        this.userGroups = [];
        await this.getUserGroups();

        // get the list of sensors available to add
        // this.getSensors();
        // this.formSelectList();
        this.dialogGroups = true;
      },
      getUserGroups: async function() {
        this.userGroups = await api.getUserGroups(this.userIndex);
        this.groupsSelected = [];
        
        // Use map function of similar instead of for loop [TBD]
        for(let x in this.userGroups) {
          this.groupsSelected.push(this.userGroups[x].id);
        }
        // this.userViewSwitch = Boolean(this.userSensors.view)
      },

      addGroup: async function() {
        const groupRequest = {
          // name: this.editedItem.group,
          description:  this.editedItem.description,
          // origin: 1,
        };
        await api.addGroup(groupRequest);
        this.editedItem.id = 0;
        // this.editedItem.group = "";
        this.editedItem.description = "";
        this.getGroups();
      },

      // addUserGroup: async function() {
      //   const requestBody = {
      //     group: this.groupsSelected,
      //     // view: 1, // this.userViewSwitch & 1;
      //     // alarm: 1, // this.userViewSwitch | 0;
      //   };
      //   await api.addUserSensor(this.userIndex, requestBody);
      //   this.groupsSelected = [];
      //   this.getUserGroups();
      //   // this.getGroups();
      //   this.closeAddGroups();
      // },

      updateGroups: async function() {
        const requestData = {
          // group:     this.editedItem.groups,
          description:  this.editedItem.description,
          // id: this.editedItem.id
        };
        await api.updateGroups(this.editedItem.id, requestData);
        this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedItem.id = 0;
        // this.editedItem.group = "1";
        // this.editedItem.description = "";
        this.getGroups(); // this can be avoided based on the request response success or error
      },

      // editItem(item) {
      //   this.editedIndex = this.groups.indexOf(item);
      //   this.editedItem = Object.assign({}, item);
      //   this.dialog = true;
      //   // call update api
      // },

      closeGroups() {
        this.dialogGroups = false;
        this.groupsSelected = [];
        // this.$nextTick(() => {
        //   this.editedItemGroups = Object.assign({}, this.defaultItem);
        //   this.editedIndexGroups = -1;
        // });
      },

      // closeGroups() {
      //   this.dialogGroups = false;
      //   this.userIndex = 0;
      //   this.groupsSelected = [];
      // },

      saveGroups() {
        if (this.editedIndex > -1) {
          this.updateGroups();
          // Object.assign(this.groups[this.editedIndex], this.editedItem);
        } else {
          // this.groups.push(this.editedItem);
          this.addGroup();
        }
        this.closeGroups();
      },

      updateUserGroups: async function() {
        const requestBody = {
          // group:     this.editedItem.groups,
          groups: this.groupsSelected,
          // id: this.editedItem.id
        };
        await api.updateUserGroups(this.userIndex, requestBody);
        this.getUserGroups();
        this.closeGroups();
      },
      checkScopesSensorWrite(input) {
        if(this.editedItem.scopes.includes(input)) {
          this.editedItem.scopes.push('sensors.read', 'groups.read', 'locations.read', 'sensortypes.read');
          this.scopesSensorWriteDisabled = true;
        }
        else {
          this.scopesSensorWriteDisabled = false;
        }
      },
      checkScopesSensorCreate(input) {
        if(this.editedItem.scopes.includes(input)) {
          this.editedItem.scopes.push('sensors.read','sensors.write','groups.read', 'locations.read', 'sensortypes.read');
          this.scopesSensorCreateDisabled = true;
          this.scopesSensorWriteDisabled = true;
        }
        else {
          this.scopesSensorCreateDisabled = false;
        }
      },
      checkScopesUsersWrite(input) {
        if(this.editedItem.scopes.includes(input)) {
          this.editedItem.scopes.push('users.read');
          this.scopesUsersWriteDisabled = true;
        }
        else {
          this.scopesUserWriteDisabled = false;
        }
      },
      checkScopesGroupsWrite(input) {
        if(this.editedItem.scopes.includes(input)) {
          this.editedItem.scopes.push('groups.read');
          this.scopesGroupWriteDisabled = true;
        }
        else {
          this.scopesGroupWriteDisabled = false;
        }
      },
      checkScopesLocationsWrite(input) {
        if(this.editedItem.scopes.includes(input)) {
          this.editedItem.scopes.push('locations.read');
          this.scopesLocationWriteDisabled = true;
        }
        else {
          this.scopesLocationWriteDisabled = false;
        }
      },
      checkScopesSensorTypesWrite(input) {
        if(this.editedItem.scopes.includes(input)) {
          this.editedItem.scopes.push('locations.read');
          this.scopesSensorTypeWriteDisabled = true;
        }
        else {
          this.scopesSensorTypeWriteDisabled = false;
        }
      },
      // passEvent()
      // {
      //   this.$emit('changeUserStatus','Awesome ')
      // }

      // handleSubmit() {
      //   alert(JSON.stringify(this.groupsSelected));
      // }

      // saveUserApprove() {
      //   // start loading state
      //   // send request and wait for response
      //   this.approveUser();
      //   // v-alert upon finish
      //   // approveRequestStatus = true;
      // },
      // toggle () {
      //   this.$nextTick(() => {
      //     if (this.allSensorsSelect) {
      //       this.sensorSelected = []
      //     } 
      //     else {
      //       this.sensorSelected = this.sensorsList.slice()
      //     }
      //   })
      // },
    },
  };
</script>