<template>
  <!-- <v-container fluid fill-height id="login-page" class="grey lighten-3"> -->
    <!-- <h2>Register</h2> -->
    <v-layout align-center justify-center>
      <v-flex :style="{ 'max-width': '300px' }">
        <v-card elevation="17">
          <v-snackbar v-model="show" :color="color" top right timeout="2500" app transition="slide-y-transition">
            {{response}}
            <!-- <v-btn small dark @click.native="show = false">
              close
            </v-btn> -->
          </v-snackbar>
          <!-- <v-img
            :src="require('@/assets/img/oscilas.svg')"
            contain
            height="70"
          /> -->
          <!-- class="my-7" -->
          <v-card-title>Reset your password</v-card-title>
          <v-card-text>
            <v-text-field 
              class="mx-2"
              v-model="register.email"
              label="Email"
              required
              disabled
            >
            </v-text-field>

            <v-text-field
              class="mx-2"
              v-model="register.pass1"
              label="Password"
              type="password"
              required
            >
            </v-text-field>

            <v-text-field
              class="mx-2"
              v-model="register.pass2"
              label="Confirm Password"
              type="password"
              required
            >
            </v-text-field>

          </v-card-text>
          <div class="text-center">
            <v-hover v-slot="{ hover }">
              <v-btn
                min-width="200"
                class="mb-5"
                success
                @click="registerIt"
                elevation="5"
                dark
                :color="hover ? '#00AB8E' : 'rgb(0, 108, 101, 0.75)'"
              >Reset</v-btn>
            </v-hover>
            <!-- <v-btn class="mb-5" outlined color="teal" success @click="registerIt"
              >Submit</v-btn> -->
            <v-divider class="ma-5"></v-divider>

            <v-btn text class="mb-3" success small @click="goToLogin"
              >It's not your account?</v-btn
            >
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  <!-- </v-container> -->
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      register: {
        email: this.$route.query.email,
        token: this.$route.query.token,
        pass1: "",
        pass2: "",
        // origin: 1,
      },
      // passwordrepeat: "",
      show: false,
      response: "",
      color: "",
    };
  },
  methods: {
    registerIt() {
      // check if all the fields are provided
      if (this.register.email && this.register.pass1 && this.register.pass2) {
        // if(this.register.pass1 == this.passwordrepeat) { // check if the passwords match
          // axios.defaults.headers.common["Authorization"] = undefined;
          // console.log(axios.defaults.headers.common["Authorization"]);
          axios
            .post("https://www.oscilas.com/api/reset", this.register)
            .then((response) => {
              // const token = response.data;
              // localStorage.setItem("token", token);
              this.response = response.data;
              this.color = "green";
              this.show = true;
              // pop-up saying pending approval
              this.$router.push("/");
            })
            .catch((error) => {
              this.response = error;
              this.color = "red";
              this.show = true;
              // console.log("Error Registering");
              // console.log(error);
            });
        // }
        // else {  // password mismatch
        //   this.response = "Password mismatch";
        //   this.color = "red";
        //   this.show = true;
        // }
      }
      else {
        // form incomplete
        this.response = "All fields are required!";
        this.color = "red";
        this.show = true;
      }
    },
    goToLogin() {
      this.$router.push("/");
    },
  },
};
</script>
