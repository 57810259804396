import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  // icons: {
  //   iconfont: 'mdi'
  // },
  theme: {
    themes: {
      light: {
        // primary: '#3f51b5',
        // primary: '#009688',
        primary: '#0d608f',
        secondary: '#424242',
        // secondary: '#ffffff',
        sidemenu: '#ffffff',
        menucontent: '#0d608f',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
      dark: {
        // primary: '#009688',
        primary: '#0d608f',
        secondary: '#1E1E1E',
        menucontent: '#1081B2',
        sidemenu: '#1E1E1E',
        // accent: '#8c9eff',
        // error: '#b71c1c',
        // primary: colors.teal,
      },
    },
  },
});
