var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.lastReads,"search":_vm.search,"sort-by":"description","multi-sort":"","items-per-page":15,"footer-props":{'items-per-page-options': [10 ,15, 20, -1]}},scopedSlots:_vm._u([{key:"item.symbol",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":(item.value < item.min_val) ? 'blue' : ((item.value > item.max_val) ? 'red' : '#9E9E9E')}},[_vm._v(_vm._s(item.symbol))])]}},{key:"item.event_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.event_time))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.checkTime(item.event_time))?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-clock-alert-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Outdated")])])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item),"dark":"","small":"","outlined":""}},[_c('b',[_vm._v(_vm._s(item.value))]),_vm._v(" "+_vm._s(_vm.formatUnit(item.type))+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Latest Sensor Readings")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"mx-3",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-dialog',{attrs:{"transition":"slide-y-transition"},model:{value:(_vm.dialogPlot),callback:function ($$v) {_vm.dialogPlot=$$v},expression:"dialogPlot"}},[(_vm.dialogPlot)?_c('Plot',{attrs:{"last-reading":_vm.lastReading}}):_vm._e(),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialogPlot=false}}},[_vm._v(" Close ")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ml-3",attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.plotItem(item)}}},[_vm._v(" mdi-poll ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }