<template>
  <v-data-table
    :headers="headers"
    :items="alarms"
    :search="search"
    sort-by="event_time"
    sort-desc
    multi-sort
    class="elevation-1"
    :footer-props="{'items-per-page-options': [10 ,15, 20, -1]}"
  >
    <template v-slot:[`item.symbol`]="{ item }">
      <v-icon disabled>{{ item.symbol }}</v-icon>
    </template>
    <template v-slot:[`item.event_time`]="{ item }">
      {{ formatDate(item.event_time) }}
    </template>

    <template v-slot:[`item.alarm_type`]="{ item }">
      {{ formatAlarm(item.alarm_type) }}
    </template>

    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Alarms</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-dialog persistent v-model="dialogReport" max-width="900px" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="primary"
              outlined
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="getDismissedAlarms"
            >
              Dismissed Alarms
            </v-btn>
          </template>

          <v-card>
            <template>
              <v-data-table
                dense
                :headers="dismissed_headers"
                :items="dismissedAlarms"
                class="elevation-1"
                sort-by="event_time"
                sort-desc
              >
                <template v-slot:[`item.symbol`]="{ item }">
                  <v-icon disabled>{{ item.symbol }}</v-icon>
                </template>

                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Details</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                    <!-- :nudge-left="80" -->
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="mt-7"
                          solo
                          outlined
                          dense
                          v-model="dateRangeText"
                          label="Dates"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                        <!-- model: {{ dates }} -->
                      </template>
                      <v-date-picker
                        color="primary"
                        width="auto"
                        v-model="dates"
                        range
                        no-title
                        scrollable
                      ></v-date-picker>
                    </v-menu>
   
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        @click="loadDismissedAlarms"
                        >Load</v-btn>
                    </v-card-actions>

                  </v-toolbar>
                </template>
                <template v-slot:[`item.event_time`]="{ item }">
                  {{ formatDate(item.event_time) }}
                </template>
              </v-data-table>
            </template>

            <v-snackbar v-model="show" :color="color" bottom right timeout="5000">
              {{snack_message}}
              <template v-slot:action="{ attrs }">
                <v-icon
                  v-bind="attrs"
                  @click="show = false"
                >
                  mdi-close
                </v-icon>
              </template>
              <!-- <v-btn small dark @click.native="show = false">
                close
              </v-btn> -->
            </v-snackbar>

            <!-- <v-card-title>
              <span class="headline">Generate Report</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined text @click="closeReport">
                Cancel
              </v-btn>
              <v-btn color="primary" outlined text @click="generateReport">
                Generate
              </v-btn>
            </v-card-actions> -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined text @click="closeReport">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogAlarms" max-width="700px" transition="slide-y-transition">
          <v-card>
            <template>
              <v-data-table
                dense
                :headers="detail_headers"
                :items="sensorAlarms"
                class="elevation-1"
                sort-by="event_time"
                sort-desc
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>
                      <v-icon disabled>{{ symbol }}</v-icon>
                      {{ sensorDescription }}
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.event_time`]="{ item }">
                  {{ formatDate(item.event_time) }}
                </template>
                <template v-slot:[`item.value`]="{ item }">
                  <template v-if="item.value">
                    {{ item.value + ' ' + sensorType }}
                  </template>
                  <template v-else>
                    no data
                  </template>
                </template>
              </v-data-table>
            </template>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined text @click="close">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-text-field
          class="mx-3"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-toolbar>
    </template>
    
    <template v-slot:[`item.action`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            class="mx-3"
            @click="dismissAlarm(item)"
            color="red"
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-close-thick
          </v-icon>
        </template>
        <span>Dismiss</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            class="mx-3"
            @click="viewAlarms(item)"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-view-list
          </v-icon>
        </template>
        <span>Details</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import api from "@/services/apiService";
import dayjs from 'dayjs';
// var pdfMake = require('pdfmake/build/pdfmake.js');
// var pdfFonts = require('pdfmake/build/vfs_fonts.js'); // [TBD]: get only the required fonts to lower size
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "Alarms",
  data: () => ({
    search: '',
    // dialogDelete: false,
    headers: [
      { value: "symbol" },
      // { text: '', value: 'alarm_sign', sortable: false },
      { text: "Sensor", align: "start", value: "description" },
      { text: "Time", value: "event_time" },
      { text: "Count", value: "count" },
      { text: "Type", value: "alarm_type", divider: true },
      // { text: "Notification", value: "notification" },
      { text: 'Actions', value: 'action', align: 'center', sortable: false },
    ],
    detail_headers: [
      // { text: "ID", align: "start", value: "id" },
      { text: "Time", value: "event_time" },
      { text: "Value", value: "value" },
    ],
    dismissed_headers: [
      { value: "symbol" },
      { text: "Sensor", value: "description" },
      { text: "Time", value: "event_time" },
      { text: "User", value: "fullname" },
      { text: "Email", value: "email" },
    ],
    alarms: [],
    // dateStart: '',
    // dateEnd: '',
    sensorIndex: '3',
    sensorAlarms: [],
    dialogAlarms: false,
    dialogReport: false,
    dateStart: new Date().toISOString().substr(0, 10),
    dateEnd: new Date().toISOString().substr(0, 10),
    menu1: false,
    dismissedAlarms: [],
    show: false,
    color: '',
    snack_message: '',
    symbol: '',
    sensorDescription: '',
    sensorType: '',
  }),

  watch: {
    dialogAlarms(val) {
      val || this.close();
    },
    dialogReport(val) {
      val || this.closeReport();
    },
  },

  computed: {
    dateRangeText () {
      return this.dates.join(' - ')
    },
    dates: {
      get: function () {
        return !this.dateEnd ? [this.dateStart] : (this.dateStart < this.dateEnd ? [this.dateStart, this.dateEnd] : [this.dateEnd, this.dateStart]);
      },
      set: function (val) {
        this.dateStart = val[0] || null
        this.dateEnd = val[1] || null
      },
    },
  },

  mounted() {
    this.getAllAlarms();
    this.getAlarmNotifications(); // to sync the notifications in case changed by another user
    // this.dateEnd = dayjs(this.dateStart).add(1, 'day').format('YYYY-MM-DD')
    // this.addLocation(this.requestData);
  },

  methods: {
  // initialize () {
  //     },

    getAllAlarms: async function() {
      // let dateStart = new Date().toISOString().substr(0, 10);
      // let dateEnd = new Date().toISOString().substr(0, 10);
      // this.alarms = await api.getAllAlarms(dateStart, dateEnd);

      const temp = await api.getAllAlarms();
      if( Array.isArray(temp) ) {
        this.alarms = temp;
      }
      else {
        //clear the table (fix the last data dismiss update)
        this.alarms = [];
      }

      // this.alarms = await api.getAllAlarms();
      // this.alarms[0].event_time = dayjs(this.alarms[0].event_time).format('YYYY-MM-DD HH:mm:ss');
      // console.log(this.alarms[0].event_time);
    },

    getDismissedAlarms: async function() {
      this.dateStart = new Date().toISOString().substr(0, 10);
      this.dateEnd = dayjs(this.dateStart).add(1, 'day').format('YYYY-MM-DD');
      this.loadDismissedAlarms();
    },
      
    loadDismissedAlarms: async function() {
      this.dismissedAlarms = [];
      var currentDate = new Date().toISOString().substr(0, 10);
      
      if ( !this.dateEnd ) {
        this.dateEnd = dayjs(this.dateStart).add(1, 'day').format('YYYY-MM-DD');
      }
      if ( (this.dateStart > currentDate) ) {
        // alert('The dates can not be in future');
        this.snack_message = 'Dates in future!';
        this.color = 'red';
        this.show = true;
      }
      else {
        if (this.dateEnd < this.dateStart) {
          let dateTemp = this.dateEnd;
          this.dateEnd = this.dateStart;
          this.dateStart = dateTemp;
        }
        const temp = await api.getDismissedAlarms(this.dateStart, this.dateEnd);
        if( Array.isArray(temp) ) {
          this.dismissedAlarms = temp;
        }
      }
    },

    viewAlarms: async function(item) {
      this.symbol = item.symbol;
      this.sensorDescription = item.description;
      this.sensorType = this.formatUnit(item.type);
      this.sensorAlarms = await api.getSensorAlarms(item.sensor, item.alarm_type);
      this.dialogAlarms = true;
    },

    // getSensorAlarms: async function() {
    //   this.alarms = await api.getSensorAlarms(this.sensorIndex, this.dateStart, this.dateEnd);
    // },

    formatDate(dateToFormat) {
      return dayjs(dateToFormat).format('YYYY-MM-DD HH:mm:ss');
    },

    formatAlarm(alarmToFormat) {
      var result = '';
      switch (alarmToFormat) {
        case 0 :
          result = 'Reset Alarm';
          break;
        case 1 :
          result = 'Threshold Crossed';
          break;
        case 2 :
          result = 'Threshold Alarm';
          break;
        case 3 :
          result = 'No Data';
          break;
        case 4 :
          result = 'No Data Alarm';
          break;
        default:
          result = 'unknown';
      }
      return result;
    },

    async dismissAlarm(item) {
      // const userRequest = {
      //   type: item.alarm_type,
      //   sensor: item.sensor,
      // };
      await api.dismissAlarm(item.sensor, item.alarm_type);
      this.getAllAlarms();
      this.getAlarmNotifications();
    },
    getAlarmNotifications: async function() {
      const n = await api.getAlarmNotifications();
      const nt = { field: 'alarms', value: n };
      this.$emit('changeNotifications', nt);
    },

    close() {
      this.dialogAlarms = false;
    },

    generateReport () {
      // var docDefinition = {
      //   content: "Coming soon!"
      // };
      // pdfMake.createPdf(docDefinition).download("Report.pdf");
      // this.closeReport();

    },

    closeReport() {
      this.dialogReport = false;
      this.dismissedAlarms = [];
    },

    formatUnit(typeToFormat) {
      var unit = '';
      switch (typeToFormat) {
        case 'battery' :
          unit = 'V';
          break;
        case 'temperature' :
          unit = '°C';
          break;
        case 'humidity' :
          unit = '%';
          break;
        case 'co2' :
          unit = 'ppm';
          break;
        case 'light' :
          unit = 'lux';
          break;
        case 'motion' :
          unit = 'm';
          break;
        case 'pressure' :
          unit = 'mbar';
          break;
        // default:
        //   unit = 'unknown';
      }
      return unit;
    },
    // delEntry(item) {
    //   this.delUserSensor(item.id);
    // },
  },
};
</script>
