var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers_data,"items":_vm.userSensors,"search":_vm.searchUserSensor,"footer-props":{'items-per-page-options': [10 ,15, 20, -1]}},scopedSlots:_vm._u([{key:"item.symbol",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"disabled":""}},[_vm._v(_vm._s(item.symbol))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("My Sensors")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-dialog',{attrs:{"scrollable":"","max-width":"700px","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"small":"","color":"primary","outlined":"","dark":""},on:{"click":_vm.getSensors}},'v-btn',attrs,false),on),[_vm._v(" Add/Remove ")])]}}]),model:{value:(_vm.dialogAddSensor),callback:function ($$v) {_vm.dialogAddSensor=$$v},expression:"dialogAddSensor"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Add/Remove Sensors")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"mx-5",attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","clearable":""},model:{value:(_vm.searchTree),callback:function ($$v) {_vm.searchTree=$$v},expression:"searchTree"}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-treeview',{staticClass:"ml-5 mt-4",attrs:{"selectable":"","selected-color":"primary","items":_vm.sensorsList,"item-text":"description","item-value":"sensor","item-key":"sensor","search":_vm.searchTree,"open-on-click":"","transition":"","dense":"","item-disabled":"locked"},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(item.symbol)+" ")])]}}]),model:{value:(_vm.sensorSelected),callback:function ($$v) {_vm.sensorSelected=$$v},expression:"sensorSelected"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":_vm.sensorSelected.length==_vm.currentUserSensors.length,"color":"primary","outlined":""},on:{"click":_vm.addRemoveUserSensors}},[_vm._v("Update ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.closeAddSensors}},[_vm._v("Cancel ")])],1)],1)],1),_c('v-spacer'),_c('v-text-field',{staticClass:"mx-3",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchUserSensor),callback:function ($$v) {_vm.searchUserSensor=$$v},expression:"searchUserSensor"}})],1)]},proxy:true},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row justify-center"},[_c('v-switch',{attrs:{"dense":""},on:{"change":function($event){return _vm.changeUserSensorConfig(item)}},model:{value:(item.view),callback:function ($$v) {_vm.$set(item, "view", $$v)},expression:"item.view"}})],1)]}},{key:"item.alarm",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row justify-center"},[_c('v-switch',{attrs:{"dense":""},on:{"change":function($event){return _vm.changeUserSensorConfig(item)}},model:{value:(item.alarm),callback:function ($$v) {_vm.$set(item, "alarm", $$v)},expression:"item.alarm"}})],1)]}},{key:"item.no_data_alarm",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row justify-center"},[_c('v-switch',{attrs:{"dense":""},on:{"change":function($event){return _vm.changeUserSensorConfig(item)}},model:{value:(item.no_data_alarm),callback:function ($$v) {_vm.$set(item, "no_data_alarm", $$v)},expression:"item.no_data_alarm"}})],1)]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(_vm._s(item.location))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.descloc))])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }