<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      dark
      width="165px"
    >
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/img/oscilas.png" alt="Logo" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title">Scisensing</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      <!-- </v-list> -->

      <v-divider />

      <!-- <v-list dense> -->
        <v-list-item
          v-for="([icon, text, link], i) in items"
          :key="i"
          link
          @click="$vuetify.goTo(link)"
        >
          <v-list-item-icon class="justify-center">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">{{
              text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    
    <v-app-bar
      app
      :color="color"
      :flat="flat"
      dark
      class="px-5"
      :class="{ expand: flat }"
    >
      <!-- <v-toolbar-title>
        <v-img src="@/assets/img/oscilas.png" max-width="35px" />
      </v-toolbar-title>
      <v-toolbar-title class="display-1 ml-2" style="color:gray">Scisensing</v-toolbar-title> -->
      <v-spacer />
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="isXs"
      />
      <div v-else>
        <v-btn text @click="$vuetify.goTo('#home')">
          <span class="mr-2">Home</span>
        </v-btn>
        <v-btn text @click="$vuetify.goTo('#about')">
          <span class="mr-2">About</span>
        </v-btn>
        <v-btn text @click="$vuetify.goTo('#technology')">
          <span class="mr-2">Features</span>
        </v-btn>
        <!-- <v-btn text @click="$vuetify.goTo('#team')">
          <span class="mr-2">Team</span>
        </v-btn> -->
        <!-- <v-btn text @click="goToTeam">
          <span class="mr-2">Team</span>
        </v-btn> -->
        <!-- <v-btn text @click="$vuetify.goTo('#pricing')">
          <span class="mr-2">Pricing</span>
        </v-btn> -->
        <v-btn class="mr-10" rounded outlined text @click="$vuetify.goTo('#contact')">
          <span class="mr-2">Contact us</span>
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
// import Team from './components/landing/Team.vue'

export default {
  data: () => ({
    drawer: null,
    isXs: false,
    items: [
      ["mdi-home-outline", "Home", "#home"],
      ["mdi-information-outline", "About", "#about"],
      // ["mdi-atom-variant", "Technology", "#technology"],
      // ["mdi-react", "Technology", "#technology"],
      ["mdi-telescope", "Features", "#technology"],
      // ["mdi-account-multiple", "Team", "#team"],
      // ["mdi-currency-usd", "Pricing", "#pricing"],
      ["mdi-email-outline", "Contact", "#contact"],
      
    ],
  }),
  props: {
    color: String,
    flat: Boolean,
  },
  methods: {
   onResize() {
      this.isXs = window.innerWidth < 800;
    },
    // goToTeam() {
    //   this.$router.push("/team");
    // },
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>