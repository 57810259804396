<template>
  <v-app>
    <!-- <v-main class="grey lighten-3"> -->
    <Snackbar />
    <!-- <Alert /> -->
    <router-view></router-view>
    <!-- </v-main> -->

    <!-- <template>
      <v-footer padless style="background:#043949" dark>
        <v-col
          class="text-center"
          cols="12"
        >
          © beUave {{ new Date().getFullYear() }}
        </v-col>
      </v-footer>
    </template> -->
  </v-app>
</template>

<script>
// import EventBus, { ACTIONS } from "./eventBus/index";
import Snackbar from "@/components/Snackbar";
// import Alert from "@/components/Alert";

export default {
  name: "App",

  components: {
    Snackbar,
    // Alert,
  },

  // updated () {
  //   if (!localStorage.token && this.$route.path !== '/') {
  //     this.$router.push('/?redirect=' + this.$route.path)
  //   }
  // }
};
</script>
