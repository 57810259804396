<template>
  <div id="app">
  <!-- <v-app id="inspire"> -->
    <v-app-bar dense app color="primary" dark elevation="1" height="50">
      <v-app-bar-nav-icon
        @click.stop="sidebarMenu = !sidebarMenu"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>{{ this.$route.name }}</v-toolbar-title>

      <v-spacer></v-spacer>
      
      <!-- <v-btn icon @click="toggleTheme" color="dark" class="mr-2">
        <v-icon>{{ buttonText }}</v-icon>
      </v-btn> -->

      <!-- <v-btn @click="toggleTheme" color="primary" class="mr-2">{{ buttonText }}</v-btn> -->
      
      <v-btn
        @click="drawer = !drawer"
        class="mr-2"
        icon
      >
      <v-icon>mdi-cog</v-icon>
      </v-btn>

      <v-menu offset-y left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="getUserIdData"
          >
            <!-- <v-avatar
              color="brown"
              size="48"
            >
              <span class="white--text text-h5">{{ userDetails.user.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() }}</span>
              <span class="white--text text-h5">{{ computedInitials }}</span>
            </v-avatar> -->
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-list>
          <div class="mx-auto text-center">
          <h3>{{ userDetails.user }}</h3>
          <p class="text-caption mt-1">
            {{ userInfo.email }}
          </p>
          </div>
          <v-divider class="my-3"></v-divider>
          <!-- <v-list-item-group v-model="model"> -->
          <v-list-item-group>
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              link
              :to="item.href"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <!-- Title - Profile name -->
                <!-- Sub-title Profile email -->
                <!-- <v-list-item-subtitle v-text="item.email"></v-list-item-subtitle> -->
                <!-- Divider -->
                <v-list-item-subtitle v-text="item.text"></v-list-item-subtitle>            
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <!-- <v-spacer></v-spacer> -->

      <!-- <v-responsive max-width="260"> </v-responsive> -->
    </v-app-bar>

    <v-navigation-drawer
      v-model="sidebarMenu"
      app
      floating
      :permanent="sidebarMenu"
      :mini-variant.sync="mini"
      color="sidemenu"
    >
      <!-- <v-list dense color="primary" dark>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h3>Dashboard</h3>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
      <div style="height: 50px;">
        <v-list-item dense class="px-2" @click="toggleMini = !toggleMini">
          <v-list-item-avatar>
            <!-- <v-icon>mdi-account-outline</v-icon> -->
            <!-- <v-img
              max-height="70"
              max-width="70"
              src="@/assets/img/mysvgfile.svg"
            ></v-img> -->
            <!-- <v-img :src="`svgCompute`"></v-img> -->
            
            <v-img :src="'data:image/svg+xml;base64,' + originInfo.svglogo "></v-img>
            <!-- <v-img v-if="originInfo.svglogo" :src="'data:image/svg+xml;base64,' + originInfo.svglogo "></v-img> -->
            <!-- <v-img v-else :src="require('@/assets/img/oscilas.png')"></v-img> -->
            <!-- <v-img :src="`data:image/svg+xml;base64,${originInfo[0].svglogo}`"></v-img> -->
    
          </v-list-item-avatar>
          <!-- class="text-truncate" -->
          <!-- style="font-size:1vw" -->
          <v-list-item-content style="font-size:15px;line-height:100%">
            <!-- {{ userDetails.user }} -->
            {{ originInfo.desc }}
          </v-list-item-content>
          <!-- <v-btn icon small>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn> -->
        </v-list-item>
      </div>
      <v-divider></v-divider>

      <!-- <v-list-item>
        <v-list-item-content>
          {{ userDetails.user }}
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider> -->

      <v-list>
        <v-list-item
          v-for="item in itemsDrawer"
          :key="item.title"
          link
          :to="item.href"
        >
          <v-list-item-icon>
            <v-badge
              :content="notifications[item.badge]"
              :value="notifications[item.badge]"
              :color="item.color"
              overlap
            >
              <v-icon color="menucontent">{{ item.icon }}</v-icon>
            </v-badge>
            <!-- <v-icon color="menucontent">{{ item.icon }}</v-icon> -->
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="menucontent--text">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    
    <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
        right
      >
       <v-toolbar flat class="transparent">
        <v-list class="pa-0">
            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
        </v-list>
       </v-toolbar>

        <!-- <v-list class="pt-0" dense>
          <v-list-tile
            v-for="item in itemsSettings"
            :key="item.title"
          > -->
        <v-divider></v-divider>
            <!-- <v-list-tile-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-tile-action> -->

            <!-- <v-list-tile-content> -->
              <!-- <v-list-tile-title>{{ item.title }}</v-list-tile-title> -->
        
        <v-select
          class="ma-10"
          v-model="themeInput"
          :items="currentTheme"
          label="Theme"
        ></v-select>

        <v-select
          class="ma-10"
          v-model="homeInput"
          :items="currentHome"
          label="Home Page"
        ></v-select>
            <!-- </v-list-tile-content>
          </v-list-tile>
        </v-list> -->
        <!-- <v-card-actions> -->
        <v-btn class="mx-10" color="primary" outlined @click="saveit">
          <!-- <v-icon left dark>mdi-save</v-icon> -->
          Save
        </v-btn>
          <!-- </v-card-actions> -->
      </v-navigation-drawer>

    <!-- Main content -->
    <!-- <v-content> -->
    <v-main>
      <v-container class="px-4 py-4" fluid>
        <v-row class="fill-height">
          <v-col>
            <!-- <transition name="fade"> -->
              <router-view @changeNotifications="ChangeU($event)"></router-view>
            <!-- </transition> -->
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- </v-content> -->
    <!-- <Users @changeUserStatus="ChangeU($event)" /> -->
    <!-- <loader :is-visible="isLoading"></loader> -->
  </div>
</template>

<script>
import api from "@/services/apiService";
// import Loader from './Loader.vue'
// import Users from "./Users";

  export default {
    components: {
      // Loader,
      // Users,
    },
    data: () => ({
      sidebarMenu: true,
      toggleMini: false,
      // userName: '',
      userDetails: [],
      drawer: null,
      // currentTheme: '',
      themeInput: "Light",
      homeInput: "Instructions",
      currentTheme: ["Light", "Dark"],
      currentHome: ["Readings", "Alarms", "Dashboard"],
      // itemsSettings: [
      //   { title: 'Theme'},
      //   // { title: 'About', icon: 'question_answer' }
      // ],
      itemsDrawer: [
        // changing the value of title here will break the \permission logic. define constants to sync changes all over, to avoid bugs [TBD]
        { title: "Readings", href: "/readings", icon: "mdi-gauge", badge: "readings", color: "green" },
        { title: "Alarms", href: "/alarms", icon: "mdi-bell-ring-outline", badge: "alarms", color: "warning" },
        { title: "Sensors", href: "/sensors", icon: "mdi-memory", badge: "sensors", color: "info" },
        { title: "My Sensors", href: "/usersensors", icon: "mdi-thermometer", badge: "usersensors", color: "green" },
        // { title: "Add Sensors", href: "/addsensor", icon: "mdi-plus" },
        { title: "Users", href: "/users", icon: "mdi-account-multiple-outline", badge: "users", color: "info" },
        { title: "Groups", href: "/groups", icon: "mdi-dots-grid", badge: "groups", color: "green" },
        { title: "Locations", href: "/locations", icon: "mdi-map-marker-multiple-outline", badge: "locations", color: "green" },
        // { title: "Visualize", href: "/graph", icon: "mdi-poll", badge: "graph", color: "green" },
        { title: "Instructions", href: "/instructions", icon: "mdi-information-outline", badge: "instructions", color: "green" },
        { title: "Dashboard", href: "/dashboard", icon: "mdi-view-dashboard", badge: "readings", color: "green" },
      ],
      items: [
        { icon: 'mdi-account-details', text: 'My Profile', href: "/Profile" },
        // { icon: 'mdi-tune', text: 'Settings', href: "/Preferences" },
        { icon: 'mdi-logout', text: 'Logout', href: "/logout" },
      ],
      messages: null,
      notifications: {
        readings: 0,
        alarms: 0,
        sensors: 0,
        usersensors: 0,
        users: 0,
        groups: 0,
        locations: 0,
        graph: 0,
        instructions: 0,
        },
      my_origin: 0,
      originInfo: '',
      userInfo: [],
      // model: '',
      // props: {
      //   preferences: {
      //     type: Array,
      //     // required: true
      //   }
      // },
      // user scopes array in the props 
    }),
    // watch: {
    //   // whenever model changes, this function will run
    //   model: function () {
    //     if (this.model == 2) {
    //       this.$router.push('/Logout');
    //     }
    //     else if (this.model == 0) {
    //       this.$router.push('/Users');
    //     }
    //     else return;
    //   },
    // },
    watch: {
      // whenever sensorTypeSelected changes, this function will run
      themeInput: function () {
        if(this.themeInput == 'Dark') {
          this.$vuetify.theme.dark = true;
        }
        else {
          this.$vuetify.theme.dark = false;
        }
      },
      // drawer(val) {
      //   val || this.updateUserParams();
      // },
    },
    computed: {
      // buttonText() {
      //   // return !this.$vuetify.theme.dark ? "mdi-weather-night" : "mdi-weather-sunny";
      //   return !this.$vuetify.theme.dark ? "mdi-moon-waxing-crescent" : "mdi-white-balance-sunny";
      // },
      // computedInitials() {
      //   return this.userInfo.first_name + this.userInfo.last_name;
      // },
      mini() {
        return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
      },
    },
    mounted() {
      this.getUserDetails();
      if (this.userDetails.scopes.includes('users.write')) {
        this.getPendingUserNotifications();
      }
       if (this.userDetails.scopes.includes('sensors.write')) {
         this.getSensorsNotifications();
      }
      this.getOriginInfo();
      this.getUserIdData();
      this.getAlarmNotifications();
      
      // this.getOriginData();
      
      // get the updates data
      // console.log(this.userDetails.scopes);
      // console.log("testing: ");
      // console.log(this.$store.state.user.test);
    },
    methods: {
      // toggleTheme() {
      //   this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      // },
      // toggleTheme() {
      //   if(this.themeInput == 'Light') {
      //     this.$vuetify.theme.dark = false;
      //   }
      //   else {
      //     this.$vuetify.theme.dark = true;
      //   }
      // },
      getUserDetails() {
         // get user details from the token to set the env according to permissions
        if (localStorage.token) {
          // get token from localStorage
          let token = localStorage.getItem("token");
          this.userDetails = JSON.parse(atob(token.split('.')[1]));
          this.my_origin = this.userDetails.origin;
          this.setUserMainLayout();
        }
        else {
          console.log('token not found');
          // generate and error pop-up saying invalid session. Please login!
        }
      },
      setUserMainLayout() {
        // check user scopes and set layout elements accordingly
        // if (!this.userDetails.scopes.includes('users.write'))
        // {
        //   // Replace the sensors with My Sensors
        //   let markLocation = this.itemsDrawer.findIndex(obj => obj.title === 'Sensors');
        //   this.itemsDrawer.splice(markLocation, 1);
          if (!this.userDetails.scopes.includes('users.write') && !this.userDetails.scopes.includes('users.read'))
          {
            // Find the object where title is 'Users' and return it's array index
            let markLocation = this.itemsDrawer.findIndex(obj => obj.title === 'Users');
            // remove the object from the array
            this.itemsDrawer.splice(markLocation, 1);
          }
        // }
        // else {
        //   let markLocation = this.itemsDrawer.findIndex(obj => obj.title === 'My Sensors');
        //   this.itemsDrawer.splice(markLocation, 1);
        // }
        if (!this.userDetails.scopes.includes('sensors.write') && !this.userDetails.scopes.includes('groups.manager'))
        {
          let markLocation = this.itemsDrawer.findIndex(obj => obj.title === 'Sensors');
          this.itemsDrawer.splice(markLocation, 1);
        }

        if (!this.userDetails.scopes.includes('locations.write') && !this.userDetails.scopes.includes('locations.read'))
        {
          let markLocation = this.itemsDrawer.findIndex(obj => obj.title === 'Locations');
          this.itemsDrawer.splice(markLocation, 1);
        }

        if (!this.userDetails.scopes.includes('groups.write') && !this.userDetails.scopes.includes('groups.read') && !this.userDetails.scopes.includes('groups.manager') )
        {
          let markLocation = this.itemsDrawer.findIndex(obj => obj.title === 'Groups');
          this.itemsDrawer.splice(markLocation, 1);
        }

        if('theme' in this.userDetails.params) {
          this.themeInput = this.userDetails.params.theme;
        }
        if('home' in this.userDetails.params) {
          this.homeInput = this.userDetails.params.home;
        }
        this.$router.replace('/' + this.homeInput);
        
        if (this.userDetails.scopes.includes('locations.read')==null && this.userDetails.scopes.includes('locations.write')==null) {
          // Find the object where title is 'Locations' and return it's array index
          let markLocation = this.itemsDrawer.findIndex(obj => obj.title === 'Locations');
          // remove the object from the array
          this.itemsDrawer.splice(markLocation, 1);
        }
      },

      saveit: async function() {
        const userRequest = { 
          parms: {
            theme: this.themeInput,
            home: this.homeInput,
            // dashboard: this.dashboardInput,
          }
        };

        await api.updateUserParams(this.userDetails.id, userRequest);
        this.drawer = false;
      },

      getPendingUserNotifications: async function() {
        this.notifications.users = await api.getPendingUserNotifications();
        // this.notifications.users = resp.find(element => element.n).n;
        // this.itemsDrawer.badge = resp.find(element => element.n).n;
      },
      getAlarmNotifications: async function() {
        this.notifications.alarms = await api.getAlarmNotifications();
      },
      getSensorsNotifications: async function() {
        this.notifications.sensors = await api.getSensorsNotifications();
      },

      ChangeU(status)
      {
        this.notifications[status.field] = status.value;
      },

      getOriginInfo: async function() {
        this.originInfo = await api.getOriginInfo(this.my_origin);
      },

      getUserIdData: async function() {
        const temp = await api.getUserIdData(this.userDetails.id);
        if(temp) {
          this.userInfo = temp[0];
        }
      },

      // getOriginData: async function() {
      //   const originData = await api.getOriginData(this.userDetails.origin);
      // },

    },
  };
</script>
