<template>
  <section style="background:#f8f9fa" class="pb-15" id="contact">
    <v-container class="mt-5" fluid>
      <v-row align="center" class="my-15" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" sm="6">
              <h1 class="font-weight-light display-1">Contact us</h1>
              <h3 class="font-weight-light mt-3">
                <!-- If you want to book a demo or get more information about the pricing, 
                please drop us a message and we will get back to you shortly.<br>
                We are also open to discuss potential partnerships. -->
                We are commited to improve the user's experience. If you find a bug, need more information, or want to leave a comment, please fill in and submit this form and we'll get back to you!
              </h3>
              <!-- <h3 class="font-weight-light">
                Email: contact@beuave.com
              </h3> -->
            </v-col>
            <v-col cols="12" sm="6">
              <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-text-field
                  v-model="name"
                  :rules="nameRules"
                  label="Name"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>

                <v-textarea
                  v-model="textArea"
                  :rules="textAreaRules"
                  label="Message"
                  required
                />

                <v-btn
                  :disabled="!valid"
                  color="primary"
                  :dark="valid"
                  rounded
                  block
                  class="mt-3"
                  @click="submit"
                >
                  Send
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar
      v-model="snackbar.enabled"
      timeout="5000"
      right
      top
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.enabled = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<script>
import api from "@/services/apiService";

export default {
  data: () => ({
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 6) || "The name should be at least 6 characters long",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail should be valid",
    ],
    textArea: "",
    textAreaRules: [
      (v) => !!v || "Message is required",
      (v) => (v && v.length >= 10) || "Minimum 10 characters",
    ],
    lazy: false,
    snackbar: {
      enabled: false,
      text: '',
      color: ''
    }
  }),
  methods: {
    async submit() {
      const userRequest = {
        name: this.name,
        email: this.email,
        message: this.textArea,
      };
      try {
        await api.contactus(userRequest);
        this.snackbar.text = "Thank you for contacting us! We will review your message and get back to you if necessary";
        this.snackbar.color = "success";
        this.snackbar.enabled = true;
      } catch (error) {
        console.log(error);
        this.snackbar.text = "Error sending message!";
        this.snackbar.color = "danger";
        this.snackbar.enabled = true;
      }
      // this.name = '';
      // this.email = '';
      // this.textArea = '';
    }
    // async submit() {
    //   const userRequest = {
    //     name: this.name,
    //     email: this.email,
    //     message: this.textArea,
    //   };
    //   await api.contactus(userRequest);
    //   this.name = '';
    //   this.email = '';
    //   this.textArea = '';
    // }
  }
};
</script>
