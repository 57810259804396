<template>
  <v-data-table
    :headers="headers"
    :items="lastReads"
    :search="search"
    sort-by="description"
    multi-sort
    class="elevation-1"
    :items-per-page="15"
    :footer-props="{'items-per-page-options': [10 ,15, 20, -1]}"
  >
  <!-- sort-desc -->
<!-- 
    <v-chip
      color="getChipColor(item)"
      outlined
    ></v-chip> -->

    <template v-slot:[`item.symbol`]="{ item }">
      <v-icon :color="(item.value < item.min_val) ? 'blue' : ((item.value > item.max_val) ? 'red' : '#9E9E9E')">{{ item.symbol }}</v-icon>
    </template>
    <template v-slot:[`item.event_time`]="{ item }">
        {{ formatDate(item.event_time) }}
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="checkTime(item.event_time)"
              small
              color="warning"
              v-bind="attrs"
              v-on="on"
            >
              mdi-clock-alert-outline
            </v-icon>
          </template>
          <span>Outdated</span>
        </v-tooltip>
        <!-- <v-icon v-if="checkTime(item.event_time)" small color="warning">mdi-clock-alert-outline</v-icon> -->
        
        <!-- <v-icon small color="warning">{{ infoTag }}</v-icon>
        {{ formatDate(item.event_time) }} -->
        <!-- <v-icon small color="warning">{{ infoTag }}</v-icon> {{ item.event_time }} -->

    </template>

    <template v-slot:[`item.value`]="{ item }">
      <v-chip
        :color="getColor(item)"
        dark
        small
        outlined
      >
        <!-- {{ item.value + ' ' + formatUnit(item.type) }} -->
        <b>{{ item.value }}</b> {{ formatUnit(item.type) }}
      </v-chip>
    </template>

    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Latest Sensor Readings</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="mx-3"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>

        <v-dialog v-model="dialogPlot" transition="slide-y-transition">
          <Plot v-if="dialogPlot"
            :last-reading="lastReading"
          />
          <v-btn color="primary" @click="dialogPlot=false">
            Close
          </v-btn>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="ml-3"
        @click="plotItem(item)"
        color="primary"
        dark
      >
        mdi-poll
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import api from "@/services/apiService";
import dayjs from 'dayjs';
import Plot from './Plot';

// import TimeSeriesChart from './TimeSeriesChart.vue';

// Vue.use(VueExcelXlsx);
// var utc = require('dayjs/plugin/utc');
// var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.tz.setDefault(dayjs.tz.guess());

// console.log('this is the time: ', dayjs.tz().format('YYYY-MM-DD HH:mm:ss'));
// console.log(dayjs.tz.guess());
// dayjs.tz.setDefault("America/New_York");


export default {
  name: "Readings",
  components: {
    Plot
    // TimeSeriesChart
  },
  data: () => ({
    search: '',
    // dialogDelete: false,
    headers: [
      { value: "symbol" },
      { text: "Sensor Name", value: "description" },
      // { text: "Type", value: "type" },
      { text: "Value", value: "value", align: 'center', sortable: false },
      { text: "Label", value: "label", align: 'center' },
      { text: "Last Updated", value: "event_time" },
      // { text: "Unit", value: "type" },
      // { text: "Status", value: "state" }, // use css to show this instead of column
      // { text: "Notification", value: "notification" },
      { text: 'Data', value: 'actions', align: 'center', sortable: false },
    ],
    // sensorType: [
    //   { text: "battery", value: "V" },
    //   { text: "temperture", value: "C" },
    //   { text: "humidity", value: "event_time" },
    //   { text: "CO2", value: "ppm" },
    //   { text: "light", value: "lux" },
    //   { text: "motion", value: "motion" },
    // ],
    lastReads: [],
    dialogPlot: false,
    symbol: '',
  }),

  // watch: {
  //   // toggle_exclusive(val) {
  //   //   this.plotGraph(val);
  //   // },
  // },

  mounted() {
    this.getLastReadings();
  },

  methods: {
    plotItem(item) {
      this.lastReading = item;
      this.dialogPlot = true;
    },
    getLastReadings: async function() {
      this.lastReads = await api.getLastReadings();
    },
    getColor (item) {
      if (item.value > item.max_val) return 'red'
      else if (item.value < item.min_val) return 'blue'
      else return 'green'
    },
    checkTime(timeToCheck) {
      if (dayjs().subtract(1, 'hour') > dayjs(timeToCheck)) {
        return true;
      }
      else {
        return false;
      }
      // // if (dayjs().subtract(1, 'hour') > dayjs(dateToFormat)) {
      // //   this.infoTag = 'mdi-alert';
      // // }
      // // else {
      // //   this.infoTag = '';      // console.log(dateToFormat);
      // // }
      // // return dayjs.tz(dateToFormat).format('YYYY-MM-DD HH:mm:ss');
      // return dayjs(dateToFormat).format('YYYY-MM-DD HH:mm:ss');
      // // return dayjs.tz(dateToFormat).tz("Europe/Lisbon").format('YYYY-MM-DD HH:mm:ss');
    },
    formatDate(dateToFormat) {
      // // if (dayjs().subtract(1, 'hour') > dayjs(dateToFormat)) {
      // //   this.infoTag = 'mdi-alert';
      // // }
      // // else {
      // //   this.infoTag = '';      // console.log(dateToFormat);
      // // }
      // // return dayjs.tz(dateToFormat).format('YYYY-MM-DD HH:mm:ss');
      return dayjs(dateToFormat).format('YYYY-MM-DD HH:mm:ss');
      // // return dayjs.tz(dateToFormat).tz("Europe/Lisbon").format('YYYY-MM-DD HH:mm:ss');
    },
    formatUnit(item) {
      // const lastReadItem = this.lastReads.find(element => element.sensor === item.i);
      if (item) {
        // const typeToFormat = lastReadItem.type;
        var unit = '';
        switch (item) {
          case 'battery' :
            unit = 'V';
            break;
          case 'temperature' :
            unit = '°C';
            break;
          case 'temperature_probe' :
            unit = '°C';
            break;
          case 'humidity' :
            unit = '%';
            break;
          case 'co2' :
            unit = 'ppm';
            break;
          case 'light' :
            unit = 'lux';
            break;
          case 'motion' :
            unit = 'm';
            break;
          case 'pressure' :
            unit = 'mbar';
            break;
          case 'distance' :
            unit = 'mm';
            break;
          case 'oxygen' :
            unit = '%';
            break;
          case 'meter' :
            unit = '';
            break;
          default:
            unit = 'unknown';
        }
        return unit;
      }
      else return '';
    },
  },
};
</script>
