import Vue from 'vue'
import VueRouter from 'vue-router'
import Alarms from './components/Alarms.vue'
import Readings from './components/Readings.vue'
import Sensors from './components/Sensors.vue'
import Locations from './components/Locations.vue'
import Users from './components/Users.vue'
import Graph from './components/Graph.vue'
import Register from './components/Register.vue'
import Logout from './components/Logout.vue'
import MainLayout from './components/MainLayout.vue'
// import Error from './components/Error.vue'
import Profile from './components/Profile.vue'
import UserSensors from './components/UserSensors.vue'
import LandingLogin from './components/LandingLogin.vue'
import Instructions from './components/Instructions.vue'
import Groups from './components/Groups.vue'
import Recoverpass from './components/Recoverpass.vue'
import Resetpass from './components/Resetpass.vue'
import Dashboard from './components/Dashboard.vue'

// import { indexOf } from 'core-js/core/array'

Vue.use(VueRouter)
// To deal with the Router Error: "NavigationDuplicated" ---
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }
// ---------------------------------------------------------

// Vue.component("Error", Error);

const routes = [
  // {
  //   path: '/error',
  //   component: () => import('@/components/RequiresAuthenticationResource')
  // },
  // {
  //   path: '/non-existent',
  //   component: () => import('@/components/NonExistentResource')
  // },
  {
    path: '/',
    name: 'LandingLogin',
    component: LandingLogin
  },
  {
    path: '/login',
    name: 'Login',
    component: LandingLogin
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/recover',
    name: 'Recoverpass',
    component: Recoverpass
  },
  {
    path: '/reset',
    name: 'Resetpass',
    component: Resetpass
  },
  {
    path: '/home',
    component: MainLayout,
    // props: true,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: '/instructions',
        name: 'Instructions',
        component: Instructions
      },
      {
        path: '/logout',
        name: 'Logout',
        component: Logout
      },
      {
        path: '/profile',
        name: 'Profile',
        component: Profile
      },
      {
        path: '/alarms',
        name: 'Alarms',
        component: Alarms
      },
    
      {
        path: '/readings',
        name: 'Readings',
        component: Readings
      },
      {
        path: '/users',
        name: 'Users',
        component: Users,
        // props: true,
      },
      {
        path: '/locations',
        name: 'Locations',
        component: Locations
      },
      {
        path: '/sensors',
        name: 'Sensors',
        component: Sensors
      },
      {
        path: '/usersensors',
        name: 'My Sensors',
        component: UserSensors
      },
      {
        path: '/groups',
        name: 'Groups',
        component: Groups
      },
      {
        path: '/graph',
        name: 'Graph',
        component: Graph,
        props: {
          deviceData: {
            id: '',
            description: '',
            location: ''
          }
        }
      },
    ]
  },
  // {
  //   //route to catch errors
  //   path: '*',
  //   component: Error
  // }
]

const router = new VueRouter({
  mode: 'history',
  // base:  process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/login', '/register', '/recover', '/reset'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('token');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router