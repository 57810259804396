<template>
  <v-data-table
    :headers="headers"
    :items="locations"
    :search="search"
    sort-by="id"
    multi-sort
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Locations</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        
        <v-dialog v-model="dialog" max-width="500px" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              :disabled="selfScopes.includes('locations.write')==0"
              color="primary"
              outlined
              dark class="mb-2"
              v-bind="attrs" 
              v-on="on"
            >
              New Location
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <!-- <v-col cols="12"> -->
                    <!-- <v-autocomplete
                      v-model="editedItem.location"
                      :items="locations"
                      item-text="location"
                      item-value="id"
                      label="Location"
                    ></v-autocomplete> -->
                    <v-text-field
                      v-model="editedItem.location"
                      label="Location"
                      required
                    ></v-text-field>
                  <!-- </v-col> -->
                </v-row>
                <v-row>
                  <!-- <v-col cols="12" sm="6" md="4"> -->
                    <v-text-field
                      v-model="editedItem.description"
                      label="Description"
                      required
                    ></v-text-field>
                  <!-- </v-col> -->
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" outlined text @click="save">
                <v-icon small class="mr-1">mdi-content-save</v-icon>
                Save
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined text @click="close">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-text-field
          class="mx-3"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <!-- :disabled="selfScopes.includes('locations.write')==0" -->
      <v-icon
        v-if="selfScopes.includes('locations.write')||selfScopes.includes('groups.manager')"
        small
        class="mr-3"
        @click="editItem(item)"
        color="primary"
        dark
      >
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import api from "@/services/apiService";

export default {
  name: "Locations",
  data: () => ({
    search: '',
    dialog: false,
    // dialogDelete: false,
    headers: [
      // {
      //   text: "id",
      //   align: "start",
      //   // sortable: false,
      //   value: "id",
      // },
      { text: "Name", value: "location" },
      { text: "Description", value: "description" },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    locations: [],
    // requestData: { name: 'world' },
    // responseSuccess: false,
    editedIndex: -1,
    editedItem: {
      id: 0,
      location: '',
      description: '',
    },
    defaultItem: {
      id: 0,
      location: '',
      description: '',
    },
    selfScopes: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Location" : "Edit Location";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  mounted() {
    this.getUserDetails();
    this.getLocations();
    // this.addLocation(this.requestData);
  },

  methods: {
  // initialize () {
  //     },

    getUserDetails() {
      // get user details from the token to set the env according to permissions
      if (localStorage.token) {
        // get token from localstorage
        let token = localStorage.getItem("token");
        let decoded = JSON.parse(atob(token.split('.')[1]));
        this.selfScopes = decoded.scopes;
        // console.log(this.selfScopes);
      }
      else {
        console.log('token not found');
        // generate and error pop-up saying invalid session. Please login!
      }
    },
    getLocations: async function() {
      const temp = await api.getLocations();
      if( Array.isArray(temp) ) {
        this.locations = temp;
      }
    },

    addLocation: async function() {
      const locationRequest = {
        name: this.editedItem.location,
        description:  this.editedItem.description,
      };
      await api.addLocation(locationRequest);
      this.editedItem.id = 0;
      this.editedItem.location = "";
      this.editedItem.description = "";
      this.getLocations();
    },

    updateLocation: async function() {
      const requestData = {
        location:     this.editedItem.location,
        description:  this.editedItem.description
      };
      await api.updateLocation(this.editedItem.id, requestData);
      this.editedItem = Object.assign({}, this.defaultItem);
      // this.editedItem.id = 0;
      // this.editedItem.location = "1";
      // this.editedItem.description = "";
      this.getLocations(); // this can be avoided based on the request response success or error
    },

    editItem(item) {
      this.editedIndex = this.locations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      // call update api
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        this.updateLocation();
        // Object.assign(this.locations[this.editedIndex], this.editedItem);
      } else {
        // this.locations.push(this.editedItem);
        this.addLocation();
      }
      this.close();
    },
  },
};
</script>
