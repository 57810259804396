<template>
<div>
  <navigation :color="color" :flat="flat" />
  <v-main class="pt-0">
    <home />
    <about />
    <technology />
    <!-- <team /> -->
    <!-- <whyus /> -->
    <!-- <pricing /> -->
    <contact />
  </v-main>
  
  <v-scale-transition>
      <v-btn
        fab
        v-show="fab"
        v-scroll="onScroll"
        dark
        fixed
        bottom
        right
        color="secondary"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>

    <!-- <template>
      <v-footer padless style="background:#043949" dark>
        <v-col
          class="text-center"
          cols="12"
        >
          © beUave {{ new Date().getFullYear() }}
        </v-col>
      </v-footer>
    </template> -->
</div>
</template>

<script>
import navigation from "@/components/landing/Navigation";
import home from '@/components/landing/HomeLogin.vue';
import about from '@/components/landing/About.vue';
import contact from '@/components/landing/Contact.vue';
import technology from '@/components/landing/Technology.vue';
// import team from '@/components/landing/Team.vue';
// import whyus from '@/components/landing/WhyUs.vue';
// import pricing from '@/components/landing/Pricing.vue';

export default {
  name: "Landing",

  components: {
    navigation,
    home,
    about,
    contact,
    technology,
    // team,
    // whyus,
    // pricing,
  },

  data: () => ({
    fab: null,
    color: "",
    flat: null,
  }),
  
   created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
