<script>
  import { Line } from 'vue-chartjs';
  // import { Line, mixins } from 'vue-chartjs';
  import chartjsPluginAnnotation from "chartjs-plugin-annotation";
  import zoom from 'chartjs-plugin-zoom';
  // const { reactiveProp } = mixins;

  export default {
    extends: Line,
    // mixins: [reactiveProp],
    props: {
      chartData: {
        // type: Array | Object,
        type: Array,
        required: false
      },
      chartLabels: {
        type: Array,
        required: true
      },
      chartUnit: {
        type: String,
        default: ''
      },
      chartType: {
        type: String,
        default: ''
      },
      chartThresholds : {
        type: Object,
      },
    },
    data () {
      return {
        // options: {
        // }
      }
    },
    mounted () {
      this.addPlugin(chartjsPluginAnnotation);
      this.addPlugin(zoom);
      this.renderLineChart();
    },
    watch: {
      chartData: function() {
        // this.$data._chart.destroy();
        this.renderLineChart();
        // this.$data._chart.resetZoom();
      },
    },
    methods: {
      renderLineChart() {
        const chartUnit = this.chartUnit;
        const chartType = this.chartType;
        this.renderChart({
          labels: this.chartLabels,
          datasets: [
            {
              label: 'value',
              borderColor: '#249EBF',
              pointBackgroundColor: 'transparent',
              borderWidth: 1,
              pointBorderColor: '#249EBF',
              backgroundColor: 'transparent',
              data: this.chartData
            }
          ]
        },
        {
          tooltips: {
            // mode: 'index',
            callbacks: {
              label: function(tooltipItem) {
                var label = chartType[0].toUpperCase() + chartType.substring(1);

                if (label) {
                  label += ': ';
                }

                // label += Math.floor(tooltipItem.yLabel);
                label += tooltipItem.yLabel;
                return label + ' ' + chartUnit;
              }
            },
            intersect: false,
            // mode: 'x'
            axis: 'x'
          },

          annotation: {
            drawTime: 'afterDatasetsDraw',
            annotations: [
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: this.chartThresholds.max,
                borderColor: "red",
                borderWidth: 1,
                borderDash: [3, 2],
                label: {
                  backgroundColor: 'grey',
                  fontSize: 11,
                  fontStyle: "normal",
                  borderColor: 'black',
                  xPadding: 4,
                  yPadding: 4,
                  // borderRadius: 10,
                  // borderWidth: 2,
                  cornerRadius: 4,
                  enabled: true,
                  position: "right",
                  content: 'Max Threshold: ' + this.chartThresholds.max + ' ' + chartUnit,
                  yAdjust: 10,
                  // xAdjust: 15,
                  // rotation: 'auto'
                },
              },
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: this.chartThresholds.min,
                borderColor: "blue",
                borderWidth: 1,
                borderDash: [3, 2],
                label: {
                  backgroundColor: 'grey',
                  fontSize: 11,
                  fontStyle: "normal",
                  borderColor: 'black',
                  xPadding: 4,
                  yPadding: 4,
                  // borderRadius: 10,
                  // borderWidth: 2,
                  cornerRadius: 4,
                  enabled: true,
                  position: "left",
                  content: 'Min Threshold: ' + this.chartThresholds.min + ' ' + chartUnit,
                  yAdjust: -10,
                  // rotation: 'auto'
                },
              },
            ],
          },
          scales: {
            xAxes: [ {
              type: 'time',
              time: {
                unit: 'hour',
                displayFormats: {
                  hour: 'M/DD - hA'
                },
                tooltipFormat: 'MMM. DD - h:mm:ssA'
              },
              // distribution: 'linear', // 'linear' or 'series'
              gridLines: {
                drawOnChartArea: true
              },
              scaleLabel: {
                display: true,
                labelString: 'Date/Time'
              }
            }],
            yAxes: [{
              ticks: {
                beginAtZero: false,
                // eslint-disable-next-line no-unused-vars
                callback: function(value, index, values) {
                  return value + ' ' + chartUnit;
                }
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: this.chartType[0].toUpperCase() + this.chartType.substring(1)
              },
              // bounds:
            }]
          },
          elements: {
            point: {
              radius: 1.5,
              hitRadius: 5,
              hoverRadius: 3,
              hoverBorderWidth: 3
            }
          },

          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: 'xy',
              },

              zoom: {
                enabled: true,
                mode: 'x',
              }
            }
          },

          legend: {
            display: false,
            position: 'top',
            align: 'center',
          },
          
          responsive: true,
          maintainAspectRatio: false
        }
        );
      },
    },
  }
</script>